.btn-block {
   display: block;
   width: 100%;
}

.btn-block + .btn-block {
   margin-top: 5px;
}

input[type="submit"] .btn-block,
input[type="reset"] .btn-block,
input[type="button"] .btn-block {
    width: 100%;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
}
.btn:hover,
.btn:focus,
.btn.focus {
   text-decoration: none;
}
.btn:active,
.btn.active {
   outline: 0;
   background-image: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
}
a.btn.disabled,
fieldset[disabled] a.btn {
   pointer-events: none;
}
.btn-link {
   font-weight: normal;
   border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
   background-color: transparent;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
   border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
   background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
   text-decoration: none;
}
.btn-block {
   display: block;
   width: 100%;
}
.btn-block + .btn-block {
   margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
   width: 100%;
}
