@media only screen and (max-width: 767px) {
    #content {
        margin-top: 90px;
    }

    .desktop-menu {
        visibility: hidden;
        clear: both;
        display: none !important;
    }

    .preview-link, .preview-link-dark {
        word-wrap: break-word !important;
    }

    .privacylink {
        position: relative;
        top: 8px;
        margin-right: unset !important;
        display: inline-block;
        white-space: normal;
        color: #6d6f6f;
        cursor: pointer;
    }

    .row .col-md-12 .nav-tabs .nav-link, .row .col-md-12 .nav-tabs-dark .nav-link {
        padding: 10px 11px;
    }

    .navbar-right li, .navbar-right-dark li {
        padding: unset !important;
    }

    .navbar-right li a, .navbar-right-dark li a {
        font-weight: bold;
        transition: all 0.2;
        padding: unset !important;
        display: block;
    }

    .navbar-right li ul.dropdown-menu-main li a, .navbar-right-dark li ul.dropdown-menu-main li a {
        display: block;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        text-transform: uppercase;
        color: #ffffff !important;
    }

    .mobile-menu ul {
        background: #43c5e6 !important;
    }

    .mobile-menu ul > li > a {
        color: #ffffff !important;
        font-weight: bold;
    }

    .navbar-right li > ul.dropdown-menu-main, .navbar-right-dark li > ul.dropdown-menu-main {
        width: 200px !important;
        background: #24b0d3 !important;
        border-radius: 0px !important;
    }

    .navbar-nav .open .dropdown-menu, .navbar-nav-dark .open .dropdown-menu {
        margin-right: unset !important;
    }

    .card-wrapper .card-outline-info .card-block a {
        word-wrap: break-word !important;
    }

    .pull-right {
        float: right !important;
    }

    .dropLanguage {
        position: absolute;
        top: 10px !important;
        right: 30px !important;
        background: #111;
    }
    .dropLanguage-footer {
        top: 10px !important;
        right: 30px !important;
        background: #fff;
        color: black;
    }

    .footer .nav li a, footer .nav li span {
        font-family: 'vugolight', sans-serif;
        color: #ffffff;
        padding: 5px;
        font-weight: 400;
        font-size: 10px;
        margin-right: unset;
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        line-height: 15px !important;
        background-color: #f5f5f5;
        padding-bottom: unset !important;
        margin-bottom: -37px !important;
    }

    footer .nav {
        padding-top: 10px;
        margin-right: 120px !important;
        float: none !important;
        display: block !important;
    }

    footer .nav li a {
        padding: 0px !important;
    }

    .footer .justify-content-center .desktop-menu {
        display: none !important;
    }

    .static-home .copyright {
        font-size: 11px;
        color: #efefef;
        padding: 0;
        margin-top: unset !important;
        line-height: 13px;
        margin-bottom: unset !important;
    }

    .static-home {
        margin-top: -178px;
    }

    .copyright i {
        margin-top: 20px !important;
    }

    .navbar-right li a {
        font-family: 'vugolight', sans-serif;
        font-weight: 400;
        font-size: 12px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        color: #000000 !important;
        display: block;
    }
    .navbar-right-dark li a {
        font-family: 'vugolight', sans-serif;
        font-weight: 400;
        font-size: 12px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        color: #ffffff !important;
        display: block;
    }

    .btn {
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .accountdiv {
        width: 100% !important;
    }

    .nav-tabs > li > a {
        color: #0a2737;
        text-transform: uppercase;
        font-weight: 600;
        font-family: 'vugo', sans-serif;
        font-size: 10px !important;
    }
    .nav-tabs-dark > li > a {
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        font-family: 'vugo', sans-serif;
        font-size: 10px !important;
    }

    .tab-content .tab-pane .col-md-12 .btn {
        font-size: 11px !important;
    }

    #contentlocations .form-control .form-control-dark {
        float: unset !important;
        display: unset !important;
        margin-right: 5px;
        width: 100% !important;
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 5px 5px !important;
    }

    .mobile-size button .dropdown-toggle {
        height: 35px !important;
        width: 35px !important;
        margin: 0 !important;
        margin-left: 0 !important;
    }

    .mobile-navbar {
        width: 100% !important;
    }

    .mobile-navbar .container {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-right: 0;
        padding-left: 0;
    }

    .mobile-menuholder {
        width: 70px !important;
        display: inline-block !important;
        float: right;
    }

    .modal .modal-dialog {
        top: 230px !important;
    }

    .mobile-iframe {
        width: 310px !important;
        height: 250px !important;
    }

    #votepopup .commentarea {
        flex: 0 0 100% !important;
    }

    .profile-page .politicians-row, .profile-page-dark .politicians-row-dark {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .audience-container button,
    .audience-container .politicians-row .aflexbox li {
        width: 100% !important;
    }

    #newsection .politicians .owl-stage .item .card, #newsection .politicians .owl-stage .item .card-dark {
        height: 350px !important;
    }

    #newsection .politicians .owl-height {
        height: 370px !important;
    }

    #third .title-accent {
        display: block;
        font-size: 70px !important;
        margin-top: -30px;
    }

    #first .container .navbar-right li,#first .container .navbar-right-dark li {
        padding: 0 10px !important;
    }

    #content .offset-md-4 h2 {
        font-size: 1.5rem !important;
        text-align: center !important;
    }

    .input-btn-header {
        width: 65% !important;
        float: left;
    }

    .search-btn-header {
        width: 30% !important;
        float: right;
    }

    .footer .dropLanguage, .footer .dropLanguage-footer {
        width: 90px !important;
    }

    .politician {
        margin-bottom: 1px !important;
        word-wrap: break-word;
    }

    /*fieldset .mentions--multiLine{*/
    /*margin-left: 12px !important;*/
    /*}*/
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    .mobile-size .show .dropdown-menu .nav-item a {
        display: block;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        text-transform: uppercase;
        color: #ffffff !important;
    }

    .navbar-right li a {
        font-family: 'vugolight', sans-serif;
        font-weight: 400;
        font-size: 12px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        color: #000000 !important;
        display: block;
    }
    .navbar-right-dark li a {
        font-family: 'vugolight', sans-serif;
        font-weight: 400;
        font-size: 12px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        color: #ffffff !important;
        display: block;
    }

    .tab-content .tab-pane .col-md-12 .btn {
        font-size: 8px !important;
    }
}

@media only screen and (min-width: 767px) {
    .mobile-menu {
        visibility: hidden;
        clear: both;
        display: none !important;
    }

    .desktop-width {
        height: 190px !important;
    }

    .admin-menu {
        height: 270px !important;
    }
}

@media only screen and (device-width: 375px)and (device-height: 812px) {
    .tab-content .tab-pane .col-md-12 .btn {
        font-size: 9px !important;
    }
}

@media (min-width: 1200px) {
    .modal-content {
        overflow-y: auto;
        height: 400px;
    }

    .wrapper,
    .navbar,
    footer,
    #root {
        width: 1070px;
        margin: auto;
    }

    .col-md-9 {
        width: 73%;
    }

    .right-section:nth-of-type(1) {
        padding-top: 5px;
        margin-top: 0px;
    }

    .content-page {
        margin-top: 30px;
    }

    .leftcontent {
        margin-left: -15px !important;
    }

    .politiqbox h2 {
        line-height: 54px !important;
    }

    .politiqbox-dark h2 {
        line-height: 54px !important;
    }

    body.enlarged {
        min-height: auto;
    }

    body {
        margin: 0px;
        padding: 0px;
    }

    .content-page .content {
        margin-top: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    .rightmenu {
        width: 240px !important;
    }
}

@media (max-width: 991px) {
    .rightmenu {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    body {
        overflow-x: hidden;
    }
}

@media (max-width: 768px) {
    .pull-right {
        float: unset;
    }

    .navbar-right, .navbar-right-dark {
        flex-direction: column;
        width: 100%;
    }

    .navbar-right li, .navbar-right-dark li {
        /*flex: 1 100%;*/
        padding: 10px;
    }

    .navbar-right li a, .navbar-right-dark li a {
        font-weight: bold;
        transition: all 0.2;
        padding: 10px;
        display: block;
    }

    .navbar-right li a:focus {
        background: #ffffff;
    }
    .navbar-right-dark li a:focus {
        background: #000000;
    }

    .loginroot .footer {
        position: relative !important;
        bottom: auto;
        z-index: auto;
    }

    #signupsection,
    #loginsection {
        position: relative;
    }

    .button-menu-mobile {
        display: block;
        margin: 0px auto;
        width: 70px;
        height: 70px;
        text-align: center;
        padding: 0px;
        background: #43c5e6;
        border-radius: 0px;
    }

    .logo {
        margin-top: 0px;
    }

    body {
        overflow-x: hidden;
    }

    .topbar-left {
        width: 70px !important;
    }

    .topbar-left i {
        display: block !important;
        line-height: 70px !important;
        height: 70px;
        width: 70px;
        margin: 0px auto;
        position: relative;
    }

    .content-page .content {
        margin-top: 70px;
    }

    .topbar .topbar-left {
        height: 70px;
    }

    .navbar-default {
        background-color: #ffffff;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    }

    .navbar-nav.navbar-right, .navbar-right-dark {
        float: right;
    }

    .content-page {
        margin-left: 0 !important;
    }

    .enlarged .left.side-menu {
        margin-left: -72px;
    }

    .footer {
        left: 0 !important;
    }

    .mobile-sidebar {
        left: 0;
    }

    .mobile-content {
        left: 250px;
        right: -250px;
    }

    .wrapper-page {
        width: 90%;
    }

    .dataTables_wrapper .col-xs-6 {
        width: 100%;
        text-align: left;
    }

    div#datatable-buttons_info {
        float: none;
    }

    .ms-container {
        width: 100%;
    }

    .m-t-sm-50 {
        margin-top: 50px !important;
    }
}

@media (max-width: 767px) {
    body {
        margin: 0;
        padding: 0;
    }

    footer {
        padding-bottom: 60px !important;
        position: relative !important;
    }

    footer .nav li::before {
        display: none;
    }

    footer .nav li a {
        padding: 5px !important;
    }

    footer .nav li span {
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        text-align: center;
    }

    .leftcontent {
        margin-bottom: 10px;
    }

    footer .nav {
        padding-top: 10px;
        float: none !important;
        display: block !important;
    }

    footer .navbar-right {
        float: none !important;
    }

    .whitebox {
        min-height: 200px !important;
        margin-bottom: 20px;
    }

    footer .nav li {
        float: none !important;
        display: block;
        width: 100%;
    }

    .hsContent {
        padding: 20px;
    }

    .navbar-nav .open .dropdown-menu {
        background-color: #ffffff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        left: auto;
        position: absolute;
        right: 0;
    }

    .navbar-nav .open .dropdown-menu li {
        display: block;
    }

    .dropdown-lg {
        width: 200px !important;
    }

    .user-box, .user-box-dark {
        float: left;
    }

    .dataTables_length {
        float: none;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .highlight {
        margin-top: 5px;
        display: block;
    }

    .logo-mobile {
        margin-top: -20px;
        display: block;
    }

    .topelements {
        padding: 0px !important;
        margin-top: 15px;
    }

    .logo {
        display: none;
    }

    .creditbalance {
        padding: 15px 0 0 0px !important;
        float: right !important;
    }

    .creditbalance a {
        padding-right: 20px !important;
        padding-top: 0px !important;
    }

    .buttonborder {
        border: 0 !important;
        padding: 0 !important;
    }

    .searchcredits {
        background: #0a2737;
        color: #ffffff;
        border-radius: 100%;
        line-height: 40px;
        height: 40px;
        width: 40px;
        text-align: center;
        display: inline-block;
    }

    .button-menu-mobile {
        display: block;
        margin: 0px auto;
        width: 70px;
        height: 70px;
        text-align: center;
    }

    .search-bar {
        display: none !important;
    }
}

@media (max-width: 420px) {
    .hide-phone {
        display: none !important;
    }
}

/* Container-alt */
@media (min-width: 768px) {
    .container-alt {
        width: 750px;
    }

    .nav-tabs.nav-justified > li > a, .nav-tabs-dark.nav-justified > li > a {
        border-bottom: 2px solid #edf1f1;
    }
}

@media (min-width: 992px) {
    .container-alt {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container-alt {
        width: 1170px;
    }
}

@media (max-width: 419px) {
    .hidden-xxs {
        display: none;
    }

    .publish {
        position: relative;
    }

    .privacylink {
        position: absolute;
        display: table-cell;
        /*width: 40px;*/
        left: -20px;
        top: 12px;
    }

    /* .publish{

      height:90px;
    }*/
    .fullpost {
        display: block;
        position: absolute;
        bottom: 0px;
        right: -10px;
        top: 65px;
    }

    footer .navbar-right {
        padding-top: 0px !important;
    }

    .topbar-left {
        width: 70px !important;
    }

    .logo .icon-c-logo {
        display: inline-block !important;
        line-height: 58px !important;
    }

    .logo span {
        /*display: none !important;*/
    }

    .wrapper-page .logo span {
        display: inline-block !important;
    }

    .content-page {
        margin-left: 70px;
    }

    .forced .side-menu.left {
        box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
    }

    .enlarged .side-menu.left {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
    }

    .page-title {
        font-size: 15px;
        max-width: 250px;
        white-space: nowrap;
    }

    .navbar-default {
        padding: 0;
    }

    .navbar-default .navbar-left {
        padding-left: 0 !important;
    }

    .navbar-default .navbar-left li {
        padding: 0 5px;
    }

    .topbar-left {
        /* display: none;*/
    }

    .editable-responsive {
        overflow-x: auto;
    }

    .page-title-box .breadcrumb {
        display: none;
    }

    .navbar-nav .open .dropdown-menu {
        margin-right: -20px;
    }

    .user-box .dropdown-menu, .user-box-dark .dropdown-menu {
        margin-right: 0 !important;
    }

    .dropdown-lg {
        width: 200px !important;
    }

    .user-list .user-list-item .icon,
    .user-list .user-list-item .avatar {
        display: none;
    }

    .user-list .user-list-item .user-desc {
        margin-left: 0;
    }
}
.quick-btn .btn {
    height: 45px;
    border-radius: 4px;
    padding: 12px 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    min-width: 200px;
    position: relative;
}
