.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ne {
  background-image: url(flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(flags/1x1/ne.svg);
}
.flag-icon-us {
  background-image: url(flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(flags/1x1/us.svg);
}