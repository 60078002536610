/* Generated by Font Squirrel (https://www.fontsquirrel.com) on February 23, 2017 */



@font-face {
    font-family: 'vugolight';
    src: url('copyfonts.com_gotham_light-webfont.woff2') format('woff2'),
         url('copyfonts.com_gotham_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'vugobold';
    src: url('ufonts.com_gotham-bold-webfont.woff2') format('woff2'),
         url('ufonts.com_gotham-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'vugo';
    src: url('ufonts.com_gotham-medium-webfont.woff2') format('woff2'),
         url('ufonts.com_gotham-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}