/*
 * bootstrap-tagsinput v0.8.0
 *
 */

.bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    padding: 4px 6px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
}
.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
    display: inline-block;
}
.input-tag input{
    width: 100%;
}
.bootstrap-tagsinput.form-control .form-control-dark input::-moz-placeholder {
    color: #777;
    opacity: 1;
}
.bootstrap-tagsinput.form-control .form-control-dark input:-ms-input-placeholder {
    color: #777;
}
.bootstrap-tagsinput.form-control .form-control-dark input::-webkit-input-placeholder {
    color: #777;
}
.bootstrap-tagsinput input:focus {
    border: none;
    box-shadow: none;
}
.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
/*
 * bootstrap-tagsinput v0.8.0
 *
 */

.bootstrap-tagsinput-dark {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    padding: 4px 6px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
}
.bootstrap-tagsinput-dark input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
    display: inline-block;
}
.bootstrap-tagsinput-dark.form-control .form-control-dark input::-moz-placeholder {
    color: #777;
    opacity: 1;
}
.bootstrap-tagsinput-dark.form-control .form-control-dark input:-ms-input-placeholder {
    color: #777;
}
.bootstrap-tagsinput-dark.form-control .form-control-dark input::-webkit-input-placeholder {
    color: #777;
}
.bootstrap-tagsinput-dark input:focus {
    border: none;
    box-shadow: none;
}
.bootstrap-tagsinput-dark .tag {
    margin-right: 2px;
    color: white;
}
.bootstrap-tagsinput-dark .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
}
.bootstrap-tagsinput-dark .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
}
.bootstrap-tagsinput-dark .tag [data-role="remove"]:hover {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput-dark .tag [data-role="remove"]:hover:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

