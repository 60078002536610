.static-home .input {
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 0em;
	max-width: 450px;
	width: calc(100% - 0.5em);
	vertical-align: top;
}

.input__field {
	position: relative;
	display: block;
	float: right;
	padding: 0.9em;
	width: 60%;
	border: none;
	border-radius: 0;
	background: #f0f0f0;
	color: #aaa;
	font-weight: bold;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
	outline: none;
}

.input__label {
	display: inline-block;
	float: right;
	padding: 0 2em;

	width: 40%;
	color: #fff;
	font-weight:normal;
	font-size: 13px;
	font-family:"vugo";
	text-transform:uppercase;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.input__label-content {
	position: relative;
	display: block;
	padding: 1.6em 0 1.4em 0;
	width: 100%;
}

.graphic {
	position: absolute;
	top: 0;
	left: 0;
	fill: none;
}

.icon {
	color: #ddd;
	font-size: 150%;
}


/* Hoshi */
.input--hoshi {
	overflow: hidden;
}

.input__field--hoshi {
	margin-top: 1em;
	padding: 0.85em 0.15em;
	width: 100%;
	background: transparent;
	color: #0b2737;
}

.input__label--hoshi {
	position: absolute;
	bottom: -2px;
	left: 0;
	padding: 0 0.25em;
	width: 100%;
	height: calc(100% - 0.6em);
	text-align: left;
	pointer-events: none;
}

.input__label-content--hoshi {
	position: absolute;
}

.input__label--hoshi::before,
.input__label--hoshi::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;

	height: 45px;
	border-bottom: 1px solid #ffffff;
}

.input__label--hoshi::after {
	margin-top: 2px;
	border-bottom: 4px solid red;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.input__label--hoshi-color-1::after {
	border-color: #f26940;
}


.has-error .input__label--hoshi-color-2, .has-error .input__label--hoshi-color-2::after{
	
		border-color: #db290b !important;
	
}


.has-error .input__label-content--hoshi{
	
color:#db290b;	
}
.input__label--hoshi-color-2::after {
	border-color: #a8f4ff;
}

.input__label--hoshi-color-3::after {
	border-color: #a8f4ff;
}

.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	font-size:70%;
}

.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
	-webkit-animation: anim-1 0.3s forwards;
	animation: anim-1 0.3s forwards;
}

@-webkit-keyframes anim-1 {
	50% {
		opacity: 0;
		-webkit-transform: translate3d(1em, 0, 0);
		transform: translate3d(1em, 0, 0);
	}
	51% {
		opacity: 0;
		-webkit-transform: translate3d(-1em, -40%, 0);
		transform: translate3d(-1em, -40%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, -40%, 0);
		transform: translate3d(0, -50%, 0);
	}
}

@keyframes anim-1 {
	50% {
		opacity: 0;
		-webkit-transform: translate3d(1em, 0, 0);
		transform: translate3d(1em, 0, 0);
	}
	51% {
		opacity: 0;
		-webkit-transform: translate3d(-1em, -40%, 0);
		transform: translate3d(-1em, -40%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, -40%, 0);
		transform: translate3d(0, -40%, 0);
	}
}

@-webkit-keyframes anim-2 {
	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 1);
		transform: scale3d(0.3, 0.3, 1);
	}
	51% {
		opacity: 0;
		-webkit-transform: translate3d(0, 3.7em, 0) scale3d(0.3, 0.3, 1);
		transform: translate3d(0, 3.7em, 0) scale3d(0.3, 0.3, 1);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 3.7em, 0);
		transform: translate3d(0, 3.7em, 0);
	}
}

@keyframes anim-2 {
	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 1);
		transform: scale3d(0.3, 0.3, 1);
	}
	51% {
		opacity: 0;
		-webkit-transform: translate3d(0, 3.7em, 0) scale3d(0.3, 0.3, 1);
		transform: translate3d(0, 3.7em, 0) scale3d(0.3, 0.3, 1);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 3.7em, 0);
		transform: translate3d(0, 3.7em, 0);
	}
}

.static-home label {
 
  margin-bottom: auto;
  max-width: auto;
}

.static-home .form-group{
margin-bottom:0;	
	
}