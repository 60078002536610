.fileuploader,
.fileuploader *,
.fileuploader :before,
.fileuploader :after {
    box-sizing: border-box;
}

/*
    Parent
*/
.fileuploader {
    display: block;
    width: 100%;
    padding: 15px;
    margin: 15px 0 !important;
    background: #f5f6fA;
    border-radius: 4px;
    line-height: normal;
    text-align: left;
    overflow: hidden;
}

/*
    Input
*/
.fileuploader-input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid transparent;
    cursor: pointer;
    overflow: hidden;
}

/* input caption */
.fileuploader-input-caption {
    position: relative;
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-item-align: start;
    align-self: flex-start;
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border: 1px solid #dde4f6;
    border-radius: 4px;
    color: #575757;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

/* input button */
.fileuploader-input-button {
    display: inline-block;
    background: #687ddb;
    padding: 11px 20px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    vertical-align: top;
    cursor: pointer;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}
.fileuploader-input-button:hover {
    background: #7b90ef;
}
.fileuploader-input-button:active {
    background: #687ddb;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}

/* input focused state */
.fileuploader-focused .fileuploader-input-caption {
    border-color: #b8c2ef;
}
.fileuploader-focused .fileuploader-input-button {
    background: #7b90ef;
    border-color: #7b90ef;
}

/* input disabled state */
.fileuploader-disabled .fileuploader-input {
    opacity: 0.7;
    cursor: default;
    pointer-events: none !important;
}

/* input dragging state */
.fileuploader-theme-default .fileuploader-dragging {
    background: #fff;
    border: 1px dashed #dde4f6;
}
.fileuploader-theme-default .fileuploader-dragging .fileuploader-input-caption {
    border-color: transparent;
}
.fileuploader-theme-default .fileuploader-dragging .fileuploader-input-button {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
}

/* input uploading state */
.fileuploader.fileuploader-is-uploading .fileuploader-input-caption:after {
    width: 14px;
    height: 14px;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    opacity: 0.8;
}

/* global loader */
.fileuploader.fileuploader-is-uploading .fileuploader-input-caption:after,
.fileuploader-item .fileuploader-item-image.fileuploader-loading:after,
.fileuploader-item.upload-pending .fileuploader-action-remove:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 3px solid #dde4f6;
    border-right: 3px solid #dde4f6;
    border-bottom: 3px solid #dde4f6;
    border-left: 3px solid #979fb8;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: fileuploaderSpin 1s infinite linear;
    animation: fileuploaderSpin 1s infinite linear;
}
/*
    Thumbnails
    default theme
*/

/* list */
.fileuploader-items-list {
    display: block;
    margin: 0 -15px;
    padding: 0;
    list-style: none;
}

/* item */
.fileuploader-item {
    position: relative;
    margin: 0;
    padding: 20px 15px;
    border-bottom: 1px solid #e8e8e8;
    -webkit-animation: fileuploaderSlideIn 0.4s ease;
    animation: fileuploaderSlideIn 0.4s ease;
}
.fileuploader-item:last-child {
    border-bottom: 0;
    margin-bottom: -15px;
}

/* item uploading state */
.fileuploader-item.upload-failed {
    background: rgba(219, 104, 104, 0.08);
}
.fileuploader-item.upload-pending .fileuploader-action-remove:after {
    width: 26px;
    height: 26px;
    right: -5px;
    top: 50%;
    margin-top: -13px;
    opacity: 0.2;
}

/* item columns */
.fileuploader-item .columns {
    position: relative;
    z-index: 2;
}

/* item column-thumbnail */
.fileuploader-item .column-thumbnail {
    position: absolute;
    width: 36px;
    height: 36px;
    line-height: 36px;
    left: 0;
    top: 0;
}
.fileuploader-item .column-thumbnail .fileuploader-item-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.fileuploader-item .fileuploader-item-image img,
.fileuploader-item .fileuploader-item-image canvas {
    border-radius: 4px;
    -webkit-animation: fileuploaderFadeIn .2s ease;
    animation: fileuploaderFadeIn .2s ease;
}

/* thumbnail loading state */
.fileuploader-item .column-thumbnail .fileuploader-item-image.fileuploader-loading:after {
    width: 24px;
    height: 24px;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    border-radius: 50%;
    opacity: 0.12;
}

/* item icon */
.fileuploader-item .fileuploader-item-icon {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-size: 11px;
    background: #ddd;
    border-radius: 4px;
    -webkit-animation: fileuploaderFadeIn .2s ease;
    animation: fileuploaderFadeIn .2s ease;
}
.fileuploader-item .fileuploader-item-icon.is-bright-color {
    color: #888;
}
.fileuploader-item .fileuploader-item-icon i {
    display: block;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: bold;
}

/* item icon like file */
/*
.fileuploader-item .fileuploader-item-icon {
    width: 30px;
    margin: 0 auto;
    border-radius: 3px;
}
.fileuploader-item .fileuploader-item-icon i {
    padding-top: 3px;
}
.fileuploader-item .fileuploader-item-icon:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom-left-radius: 2px;
    border-width: 4px;
    border-style: solid;
    border-color: #f5f6fa #f5f6fa rgba(255, 255, 255, .35) rgba(255, 255, 255, .35);
}
.fileuploader-item .fileuploader-item-icon {
    -webkit-animation: fileuploaderFadeIn 0.4s ease;
    animation: fileuploaderFadeIn 0.4s ease;
}
*/

/* item column-title */
.fileuploader-item .column-title {
    display: block;
    padding-left: 51px;
    padding-right: 81px;
    color: #74809D;
}
.fileuploader-item a {
    color: #74809D;
    text-decoration: none;
}
.fileuploader-item .column-title div {
    width: 100%;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.fileuploader-item .column-title span {
    font-size: 12px;
    color: #979fb8;
}

/* item actions */
.fileuploader-item .column-actions {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -9px;
}
.fileuploader-action {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: top;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.fileuploader-item .column-actions .fileuploader-action + .fileuploader-action {
    margin-left: 10px;
}
.fileuploader-item .column-actions .fileuploader-action-success {
    position: absolute;
    right: 0;
    top: 0;
}
.fileuploader-item .column-actions .fileuploader-action-success:hover,
.fileuploader-item .column-actions .fileuploader-action-success:active {
    opacity: 0;
}

/* icon.remove */
.fileuploader-action-remove {
    background: #fae1e1;
    border: 1px solid #ffcfcf;
}
.fileuploader-action-remove:hover {
    background: #ffefef;
}
.fileuploader-action-remove:active {
    background: #ffcfcf;
}
.fileuploader-action-remove i:before,
.fileuploader-action-remove i:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 7px;
    height: 8px;
    width: 2px;
    background-color: #f59595;
}
.fileuploader-action-remove i:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.fileuploader-action-remove i:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* icon-start */
.fileuploader-action-start {
    background: #687ddb;
    border-radius: 50%;
}
.fileuploader-action-start i {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -2px;
    margin-top: -5px;
    border-radius: 2px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #fff;
}
.fileuploader-action-start:hover {
    background: #7b90ef;
}
.fileuploader-action-start:active {
    background: #687ddb;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}

/* icon.success */
.fileuploader-action-success {
    background: #687ddb;
    border: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.fileuploader-action-success i:before,
.fileuploader-action-success i:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}
.fileuploader-action-success i:before {
    width: 4px;
    height: 2px;
    left: 6px;
    top: 11px;
    background: #fff;
}
.fileuploader-action-success i:after {
    top: 4px;
    left: 9px;
    width: 2px;
    height: 9px;
    background: #fff;
}

/* icon-download */
.fileuploader-action-download {
    background: #687ddb;
    border-radius: 50%;
}
.fileuploader-action-download i {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -1px;
    margin-top: -4px;
    width: 2px;
    height: 5px;
    border-radius: 0;
    background: #fff;
}
.fileuploader-action-download i:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -4px;
    left: -3px;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 5px solid #fff;
    border-radius: 2px;
}
.fileuploader-action-download:hover {
    background: #7b90ef;
}
.fileuploader-action-download:active {
    background: #687ddb;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}

/* icon-retry */
.fileuploader-action-retry {
    background: #687ddb;
    border-radius: 50%;
}
.fileuploader-action-retry i:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.fileuploader-action-retry i:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 3px;
    left: 3px;
    top: 9px;
    background: #687ddb;
}
.fileuploader-action-retry:hover,
.fileuploader-action-retry:hover i:after {
    background: #7b90ef;
}
.fileuploader-action-retry:active,
.fileuploader-action-retry:active i:after {
    background: #687ddb;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}

/* item progress bar */
.fileuploader-item .fileuploader-progressbar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    overflow: hidden;
}
.fileuploader-item .fileuploader-progressbar .bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    border-radius: 4px;
    background: #687ddb;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
.fileuploader-item .progress-bar2 .fileuploader-progressbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.fileuploader-item .progress-bar2 .fileuploader-progressbar .bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    border-radius: 0;
    background: rgba(104, 125, 219, 0.08);
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
.fileuploader-item .progress-bar2 span {
    position: absolute;
    top: 50%;
    margin-top: -29px;
    right: 15px;
    color: rgba(151, 159, 184, 0.16);
    font-size: 48px;
}

/* clipboard paste loading */
.fileuploader-input-caption .fileuploader-pending-loader {
    margin-right: 5px;
}
.fileuploader-pending-loader {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    overflow: hidden;
    vertical-align: middle;
}
.fileuploader-pending-loader:after,
.fileuploader-pending-loader .left-half {
    background: #fff;
}
.fileuploader-pending-loader .right-half,
.fileuploader-pending-loader .spinner {
    background: #575757;
}
.fileuploader-pending-loader:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: 4;
}
.fileuploader-pending-loader .left-half,
.fileuploader-pending-loader .right-half,
.fileuploader-pending-loader .spinner {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
}
.fileuploader-pending-loader .left-half {
    left: 0;
    opacity: 1;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;
    -webkit-animation: fileuploaderOpacity 3s steps(1, end) infinite;
    animation: fileuploaderOpacity 3s steps(1, end) infinite;
    z-index: 3;
}
.fileuploader-pending-loader .right-half {
    right: 0;
    opacity: 0;
    border-radius: 0 100% 100% 0/ 0 50% 50% 0;
    -webkit-animation: fileuploaderOpacity 3s steps(1, end) reverse infinite;
    animation: fileuploaderOpacity 3s steps(1, end) reverse infinite;
    z-index: 1;
}
.fileuploader-pending-loader .spinner {
    left: 0;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;
    -webkit-animation: fileuploaderSpin 3s linear infinite;
    animation: fileuploaderSpin 3s linear infinite;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    z-index: 2;
}

/* animations */
@-webkit-keyframes fileuploaderOpacity {
    0% {
        opacity: 1;
    }
    50%,
    100% {
        opacity: 0;
    }
}
@keyframes fileuploaderOpacity {
    0% {
        opacity: 1;
    }
    50%,
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes fileuploaderFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fileuploaderFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fileuploaderScaleIn {
    from {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes fileuploaderScaleIn {
    from {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes fileuploaderSlideIn {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
}
@keyframes fileuploaderSlideIn {
    from {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
}
@-webkit-keyframes fileuploaderSlideOut {
    from {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
    to {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        opacity: 0;
    }
}
@keyframes fileuploaderSlideOut {
    from {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
    to {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        opacity: 0;
    }
}
@-webkit-keyframes fileuploaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes fileuploaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}