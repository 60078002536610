nav {
    width: 100%;
}
nav ul {
    list-style: none;
}

a , a:hover {
    text-decoration: none;
}
.navbar-light {
    position: fixed;
    margin-bottom: 0px !important;
    padding-top: 0px;
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    top: 0px;
    background: #f1f6f6 !important;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    min-height: 70px;
    width: 100%;
    z-index: 3;
}
.navbar-light-dark {
    position: fixed;
    margin-bottom: 0px !important;
    padding-top: 0px;
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    top: 0px;
    background: #000000 !important;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    min-height: 70px;
    width: 100%;
    z-index: 3;
}
.anonymous-dark{
    color:#8e9ea7
}
.navbar {
    padding: 0px;
   /* transition: all 1s;*/
}
.navbar-brand {
    display: inline-block;
    background: url('../images/logo.svg') 0 0 no-repeat;
    background-size: contain;
    text-indent: -99999px;
    min-width: 200px;
    margin-top: 10px;
    margin-left: 10px !important;
    margin-bottom: 5px;
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}
.navbar-brand-dark {
    display: inline-block;
    background: url('../images/logowhite.svg') 0 0 no-repeat;
    background-size: contain;
    text-indent: -99999px;
    min-width: 200px;
    margin-top: 10px;
    margin-left: 10px !important;
    margin-bottom: 5px;
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-right {
    padding-top: 0px;
    padding-bottom: 0px;
}
.navbar-right li, .navbar-right-dark li {
    display: inline-block;
}
.navbar-right li a {
    font-family: 'vugolight', sans-serif;
    font-weight: 400;
    font-size: 12px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #000000 !important;
    display: block;
}
.navbar-right-dark li a {
    font-family: 'vugolight', sans-serif;
    font-weight: 400;
    font-size: 12px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #ffffff !important;
    display: block;
}
.user-box a:hover {
    color: #43c5e6;
    background: #ffffff;
    text-decoration: none;
}
.user-box-dark a:hover {
    color: #43c5e6 !important;
    text-decoration: none;
}
.navbar-right ul {
    margin-left: 4px;
    padding: 0;
    margin-top: -2px;
}
.button,
.navbar-right li a.button, .navbar-right-dark li a.button {
    background: #43c5e6;
    color: #ffffff !important;
    border-radius: 6px;
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
}
.button:hover,
.navbar-right li a.button:hover, .navbar-right-dark li a.button:hover {
    background: #24b0d3;
}

.navbar-right li ul.dropdown-menu-main li a, .navbar-right-dark li ul.dropdown-menu-main li a {
    display: block;
    padding-top: 15px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: #ffffff !important;
}

.navbar-nav {
    margin: 0px !important;
}

.navbar-right, .navbar-right-dark {
    padding-right: 0px !important;
}
.user-box .user-img, .user-box-dark .user-img {
    position: relative;
    height: 36px;
    width: 36px;
    margin: 0 auto;
}
.logo {
    /*padding-top:20px !important;	*/
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}
.navbar-right li.login-box a, .navbar-right-dark li.login-box a {
    line-height: 51px;
    font-weight: bold;
}
.navbar-right li.login-box a:hover,
.navbar-right li.login-box a:focus,
.navbar-right-dark li.login-box a:hover,
.navbar-right-dark li.login-box a:focus{
    background: #43c5e6;
    color: #ffffff !important;
}
.navbar-right li.login-box:nth-last-child(1),.navbar-right-dark li.login-box:nth-last-child(1) {
    margin-right: 20px;
}
.navbar-default {
    margin-bottom: 0px !important;
    padding-top: 0px;
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    background: #f1f6f6;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 12px !important;
}

.menuholder {
    float: right;
}

.navbar-nav .dropdown-toggle::after {
    display: none;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: #43c5e6 !important;
    color: #ffffff;
}

.greyrow {
    background: #f7fcfc;
}
legend,
h4 {
    color: #000000;
    font-family: 'vugo', sans-serif;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.list-group-item h4 {
    color: #0a2737;
    margin: 0;
    padding: 0;
}
.privacy-list {
    padding: 15px;
    background: #e5faff;
    margin-bottom: 10px;
    margin-left: 0px !important;
}
.privacy-list-dark {
    padding: 15px;
    background: #1d1f20;
    margin-bottom: 10px;
    margin-left: 0px !important;
}
.explain {
    text-transform: none;
    letter-spacing: 0px;
    font-weight: normal;
    font-family: 'vugolight', sans-serif;
}
.navbar-nav .nav-item {
    margin: 0 !important;
    width:100%;
}
.btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .open > .dropdown-toggle.btn-primary {
    background-color: #43c5e6;
    outline: none !important;
    box-shadow: none !important;
    border-color: #061821 !important;
}
.btn-primary-dark:focus, .btn-primary-dark:active, .btn-primary-dark.active, .btn-primary-dark.focus, .btn-primary-dark:active, .btn-primary-dark:focus, .btn-primary-dark:hover, .open > .dropdown-toggle.btn-primary-dark {
    background-color: #43c5e6;
    outline: none !important;
    box-shadow: none !important;
    border-color: #061821 !important;
}
.nav-tabs .nav-item.active, .nav-tabs-dark .nav-item.active {
    position: relative;
    color: #43c5e6;
    border-bottom: solid 4px #43c5e6;
}
.nav-tabs .nav-item.active:before, .nav-tabs-dark .nav-item.active:before {
    font-family: "feather";
    content: "\e097";
    position: absolute;
    top: -20px;
    font-size: 20px;
    margin-left: -10px;
    left: 50%;
    color: #ec1c45;
}
.navbar-light .navbar-nav .nav-link, .navbar-light-dark .navbar-nav .nav-link {
    padding-left: 20px !important;
}
.tab-pane {
    width: 100%;
}
.settings h2 {
    margin-top: 0px !important;
    margin-bottom: 30px;
}
.greybg {
    background: #f1f6f6;
}
.list-group-item {
    background: transparent !important;
    border: none !important;
}
/*INPUT*/
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.inputfile-2 + label {
    color: #4acf95;
    border: 2px solid #4acf95;
}
.inputfile + label {
    max-width: 80%;
    font-size: 15px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 5px;
    border-radius: 6px;
    transition: all .2s;
}
.inputfile + label:hover {
    border: solid 2px #43c5e6;
    background: #43c5e6;
    color: #ffffff;
}
.avatarbox h4 {
    color: #000000 !important;
}
.locationlist li {
    margin-bottom: 10px;
    padding: 0;
}
.alert {
    padding: 3px;
    text-align: center;
}
.alert:before {
    content: "\f071";
    font-family: "FontAwesome";
}
#contentlocations .form-control, #contentlocations .form-control-dark {
    float: left;
    display: inline-block;
    margin-right: 5px;
    width: 89%;
}
.politicians-row .settings img {
    margin-right: 0px;
    position: relative;
    width: 80px;
    height: 80px;
    float: none;
    transition: all .2s;
}
.politicians-row .settings img:hover {
    border: solid 3px #43c5e6;
}
.accountdiv {
    width: 50%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.creditbalance {
    padding: 15px 15px 15px 0px;
    display: inline-block;
}
.creditbalance ul {
    margin: 0px;
    padding-left: 6px;
    margin-top: 0px;
}
.creditbalance ul li a span {
    margin-right: 3px;
    margin-top: -2px !important;
}
.creditbalance ul li a {
    font-family: 'vugolight', sans-serif;
    text-transform: none;
    font-weight: 500;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #000000;
    display: block;
    font-size: 13px;
}
.creditbalance ul li a:hover {
    color: #43c5e6 !important;
}
.app-search .input-group {
    width: 300px;
    padding-top: 15px;
}
button.dropdown-toggle {
    margin-top: 0px;
    border-radius: 0px !important;
    background: #43c5e6;
    color: #ffffff;
    border: 0;
    border: none;
    display: inline-block;
    height: 70px;
    width: 70px;
    border-radius: 2px;
    font-size: 22px;
    margin: 0;
    padding: 0;
    text-align: center;
}
button.dropdown-toggle:focus {
    background-color: #24b0d3 !important;
}
button.dropdown-toggle:hover {
    background-color: #24b0d3 !important;
}
li.search-mobil a {
    display: none;
}
.navbar-right li a.search-badge, .navbar-right-dark li a.search-badge {
    position: relative;
    display: block;
    margin-top: 16px;
}
.navbar-right li.user-box a, .navbar-right-dark li.user-box-dark a {
    padding-top: 17px !important;
    padding-bottom: 15px !important;
}
.navbar-right li.login-box a, .navbar-right-dark li.login-box a {
    line-height: 70px;
    font-weight: bold;
}
.navbar-right li.login-box a:hover,
.navbar-right li.login-box a:focus,
.navbar-right-dark li.login-box a:hover,
.navbar-right-dark li.login-box a:focus{
    background: #43c5e6;
    color: #ffffff !important;
}
.navbar-right li.login-box:nth-last-child(1),.navbar-right-dark li.login-box:nth-last-child(1) {
    margin-right: 20px;
}
.navbar-right li.user-box.user-box ul li a, .navbar-right-dark li.user-box-dark.user-box-dark ul li a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.navbar-right.in,
.navbar-right.collapsing,
.navbar-right-dark.in,
.navbar-right-dark.collapsing{
    float: none;
    width: 100%;
}
.navbar-right.in .button,
.navbar-right.collapsing .button,
.navbar-right-dark.in .button,
.navbar-right-dark.collapsing .button{
    text-align: left;
    margin-top: 2px;
}
.navbar-default {
    margin-bottom: 0px !important;
    padding-top: 0px;
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    background: #f1f6f6;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

.dripicons-menu:before {
    content: "\e020";
}

[class^="dripicons-"]:before, [class*=" dripicons-"]:before {
    font-family: "dripicons-v2" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.navbar-right li ul.dropdown-menu-main, .navbar-right-dark li ul.dropdown-menu-main {
    width: 300px;
    background: #24b0d3 !important;
    border-radius: 0px !important;
}

.navbar-right li ul.dropdown-menu-main li, .navbar-right-dark li ul.dropdown-menu-main li {
    display: block;
}

.nav ul {
    list-style: none;
}

.dropdown-menu {
    padding: 4px 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(152, 166, 173, 0.15);
    z-index: 999999!important;
}
/* Badge */
.badge {
    text-transform: uppercase;
    padding: 3px 5px;
    margin-top: 1px;
    background-color: #4acf95;
    font-family: 'vugo', sans-serif;
    border-radius: 100%;
}
.badge-xs {
    font-size: 9px;
}
.badge-large {
    min-width: 65px;
    min-height: 65px;
}
.badge-large img {
    margin-top: 6px;
}
.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
}
.badge-white {
    background-color: rgba(255, 255, 255, 0.7);
    color: #000000 !important;
}
.badge-primary {
    background-color: #0a2737;
}
.badge-success {
    background-color: #4acf95;
}
.badge-info {
    background-color: #43c5e6;
}
.badge-warning {
    background-color: #ff9800;
}
.badge-danger {
    background-color: #f7531f;
}
.badge-purple {
    background-color: #8892d6;
}
.badge-top {
    background-color: #a9d7ec;
}
.badge-pink {
    background-color: #f26624;
}
.badge-inverse {
    background-color: #3b3e47;
}


@media (min-width: 768px) {
    .navbar-right, .navbar-right-dark {
        float: right!important;
    }
}

@media (max-width: 768px){
    .navbar-nav.navbar-right, .navbar-nav.navbar-right-dark {
        float: right;
    }
}
