.footer {
    position: fixed !important;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background: #0b0b0b;
    color: #f1f6f6;
    z-index: 4;
}
footer .container {
    text-align: center;
}
footer .nav {
    text-align: center;
    margin: auto;
    width: auto;
    float: none;
    display: inline-block;
}
footer .nav li span {
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
}
footer .nav li a,
footer .nav li span {
    font-family: 'vugolight', sans-serif;
    color: #ffffff;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    margin: 0 10px;
}
footer .nav li a:hover,
footer .nav li span:hover {
    color: #43c5e6;
    background: none;
}
footer .nav li a:focus,
footer .nav li span:focus {
    color: #43c5e6;
    background: none;
}
footer .nav li {
    position: relative;
    display: inline-block;
}
footer .nav li::before {
    content: "|";
    position: absolute;
    right: 0px;
    color: #f1f6f6;
    top: 10px;
}
footer .nav li:nth-last-child(1):before {
    display: none;
}
footer span.text-center {
    display: block;
    padding-bottom: 20px;
}
footer .navbar-right span, footer .navbar-right-dark span {
    color: #98a6ad !important;
}

@media (max-width: 767px) {
    footer .nav li::before {
        content: none;
    }
    footer .nav li {
        float: none !important;
        display: block;
        width: unset !important;
    }
   /* .footer {
        left: 0 !important;
    }
    footer .nav {
        padding-top: 10px;
        float: none !important;
        display: block !important;
    }
    footer .nav li {
        float: none !important;
        display: block;
        width: 100%;
    }
    footer .nav li a {
        padding: 5px !important;
    }*/
}
