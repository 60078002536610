.boxshadow {
    -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.52);
}

input[type=checkbox]:not(old),
input[type=radio]:not(old) {
    width: 0px;
    margin: 0;
    padding: 0;
    font-size: 0em;
    opacity: 0;
    display: none;
}

input[type=checkbox]:not(old)+label,
input[type=radio]:not(old)+label {
    display: inline-block;
    /*margin-left  : -2em;*/
    line-height: 1.5em;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 5px;
    margin-top: 5px !important;
    text-transform: none !important;
    font-family: "vugolight", sans-serif !important;
    /* text-indent:10px;*/
}

input[type=radio]:not(old)+label {
    /* display      : inline-block;*/
    /* margin-left  : -28px;*/
    padding-left: 33px;
    background: url('../images/input-radio-unchecked.png') no-repeat 0 1px;
    line-height: 27px;
}

input[type=radio]:not(old):checked+label {
    background: url('../images/input-radio-checked.png') no-repeat 0 1px;
    text-transform: none !important;
    font-family: "vugolight", sans-serif !important;
}

input[type=checkbox]:not(old):checked+label {
    background: url('../images/checks_checked.png') no-repeat 0 1px;
    text-transform: none !important;
    font-family: "vugolight", sans-serif !important;
}

input[type=checkbox]:not(old)+label {
    /*display      : inline-block;
    margin-left  : -28px;*/
    padding-left: 33px;
    background: url('../images/checks_unchecked.png') no-repeat 0 1px;
    line-height: 27px;
}

.input[type=checkbox]:not(old):checked+label {
    /*background: url('../images/checks_checked_perm.png') no-repeat 0 1px;*/
}

.input[type=checkbox]:not(old)+label {
    /*display      : inline-block;
    background: url('../images/checks_unchecked_perm.png') no-repeat 0 1px;
    margin-left  : -28px;*/
    padding-left: 33px;
    line-height: 27px;
}

/*.form-control:focus {
  border-color: #26bac4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(38, 186, 196, 0.6);
  outline: 0 none;
}

*/
/* Badge */
.badge {
    text-transform: uppercase;
    padding: 3px 5px;
    margin-top: 1px;
    background-color: #4acf95;
    font-family: 'vugo', sans-serif;
    border-radius: 100%;
}

.badge-xs {
    font-size: 9px;
}

.badge-large {
    min-width: 65px;
    min-height: 65px;
}

.badge-large img {
    margin-top: 6px;
}

.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.badge-white {
    background-color: rgba(255, 255, 255, 0.7);
    color: #000000 !important;
}

.badge-primary {
    background-color: #0a2737;
}

.badge-success {
    background-color: #4acf95;
}

.badge-info {
    background-color: #43c5e6;
}

.badge-warning {
    background-color: #ff9800;
}

.badge-danger {
    background-color: #f7531f;
}

.badge-purple {
    background-color: #8892d6;
}

.badge-top {
    background-color: #a9d7ec;
}

.badge-pink {
    background-color: #f26624;
}

.badge-inverse {
    background-color: #3b3e47;
}

/*menu items*/
.nav ul {
    list-style: none;
}

.navbar-right {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px !important;
    margin-right: 0px !important;
}

.navbar-right li, .navbar-right-dark li {
    display: inline-block;
}

.navbar-right li a {
    font-family: 'vugolight', sans-serif;
    font-weight: 400;
    font-size: 13px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #000000 !important;
    display: block;
}
.navbar-right-dark li a {
    font-family: 'vugolight', sans-serif;
    font-weight: 400;
    font-size: 13px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #ffffff !important;
    display: block;
}

.nav li.mainlink a {
    color: #0a2737 !important;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    padding-right: 20px !important;
    transition: all .2s;
}

.nav li.mainlink-dark a {
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    padding-right: 20px !important;
    transition: all .2s;
}

.user-name-dark{
    font-size: 13px !important;
    color: white !important;
}
.user-name-dark:hover{
    font-size: 13px !important;
    color: #43c5e6 !important;
}

.pt-20{
    padding-top: 20px;
}
.pt-7{
    padding-top: 7px;
}
.fs-13{
    font-size: 13px;
}

.navbar-right li a:hover {
    color: #43c5e6;
    background: #ffffff;
}
.navbar-right-dark li a:hover {
    color: #43c5e6 !important;
}

.navbar-right ul {
    margin-left: 4px;
    padding: 0;
    margin-top: -2px;
}

.button,
.navbar-right li a.button, .navbar-right-dark li a.button {
    background: #43c5e6;
    color: #ffffff !important;
    border-radius: 6px;
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
}
.scrolled .navbar-right li a.button, .scrolled .navbar-right-dark li a.button {
    background: #43c5e6;
    color: #000000 !important;
    border-radius: 6px;
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
}

.button:hover,
.navbar-right li a.button:hover, .navbar-right-dark li a.button:hover {
    background: #24b0d3;
}

.buttonborder {
    border: solid 1px #43c5e6;
    color: #000000 !important;
    font-size: 14px;
    border-radius: 6px;
    text-align: center;
    margin-left: 2px;
    font-face: 'Open Sans', sans-serif;
    font-weight: bold !important;
    display: inline-block;
    padding: 9px 15px !important;
}

.user-box .user-img, .user-box-dark .user-img {
    position: relative;
    height: 36px;
    width: 36px;
    margin: 0 auto;
}

.logo {
    /*padding-top:20px !important;	*/
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}

.topelements {
    /*padding:20px 15px;*/
}

.creditbalance {
    padding: 15px 15px 15px 0px;
    display: inline-block;
}

.creditbalance ul {
    margin: 0px;
    padding-left: 6px;
    margin-top: 0px;
}

.creditbalance ul li a span {
    margin-right: 3px;
    margin-top: -2px !important;
}

.creditbalance ul li a {
    font-family: 'vugolight', sans-serif;
    text-transform: none;
    font-weight: 500;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #000000;
    display: block;
    font-size: 13px;
}

.creditbalance ul li a:hover {
    color: #43c5e6 !important;
}

.app-search .input-group {
    width: 300px;
    padding-top: 15px;
}

button.dropdown-toggle {
    margin-top: 0px;
    border-radius: 0px !important;
    background: #43c5e6;
    color: #ffffff;
    border: 0;
    border: none;
    display: inline-block;
    height: 70px;
    width: 70px;
    border-radius: 2px;
    font-size: 22px;
    margin: 0;
    padding: 0;
    text-align: center;
}

button.dropdown-toggle:focus {
    background-color: #24b0d3 !important;
}

button.dropdown-toggle:hover {
    background-color: #24b0d3 !important;
}

li.search-mobil a {
    display: none;
}

.navbar-right li a.search-badge, .navbar-right-dark li a.search-badge {
    position: relative;
    display: block;
    margin-top: 16px;
}

.navbar-right li.user-box a, .navbar-right-dark li.user-box-dark a {
    padding-top: 17px !important;
    padding-bottom: 15px !important;
}

.navbar-right li.login-box a, .navbar-right-dark li.login-box a {
    line-height: 70px;
    font-weight: bold;
}

.navbar-right li.login-box a:hover,
.navbar-right li.login-box a:focus,
.navbar-right-dark li.login-box a:hover,
.navbar-right-dark li.login-box a:focus {
    background: #43c5e6;
    color: #ffffff !important;
}

.navbar-right li.login-box:nth-last-child(1), .navbar-right-dark li.login-box:nth-last-child(1) {
    margin-right: 20px;
}

.navbar-right li.user-box.user-box ul li a, .navbar-right-dark li.user-box-dark.user-box-dark ul li a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.navbar-right.in,
.navbar-right.collapsing,
.navbar-right-dark.in,
.navbar-right-dark.collapsing{
    float: none;
    width: 100%;
}

.navbar-right.in .button,
.navbar-right.collapsing .button,
.navbar-right-dark.in .button,
.navbar-right-dark.collapsing .button{
    text-align: left;
    margin-top: 2px;
}

.navbar-default {
    margin-bottom: 0px !important;
    padding-top: 0px;
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    background: #f1f6f6;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

/*end of menu items*/
/*HOMEPAGE*/
.hsContent {
    max-width: 750px;
    margin: 0px auto;
    position: relative;
    text-align: center;
}

.centerbox,
.adcontainer {
    top: 0px;
    position: relative;
    display: flex;
    align-items: center;
}

#searchcontainer {
    padding: 10px;
}

.innerbox {
    text-align: center;
}

#home_workflow {
    margin: 20px 0 40px 0;
}

.postboxesbg {
    background: #edf1f1;
}

.postboxesbg .hsContent {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 95% !important;
}

.home_pcbox a {
    display: inline-block;
    font-family: 'vugo', sans-serif;
    background: #ffffff;
    border: solid 1px #43c5e6;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    margin: 4px;
}

.highlight {
    padding: 5px;
    background: #43c5e6;
    color: #ffffff;
}

.formbox h2 {
    padding-bottom: 40px;
}

.whitebox {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    background: #ffffff;
    padding: 15px;
    min-height: 450px;
}

.home_bottombox h4 {
    padding-top: 40px;
    margin-bottom: 40px;
}

.home_bottombox {
    padding: 0px;
    background: #43c5e6;
    margin-top: -15px;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

#footersub {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
    background: #1f2223;
}

.lighttitle {
    font-family: 'vugolight', sans-serif;
}

/*END OF HOMEPAGE*/
.footer {
    position: fixed !important;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background: #0b0b0b;
    color: #f1f6f6;
}

footer .container {
    text-align: center;
}

footer .nav {
    text-align: center;
    margin: auto;
    width: auto;
    float: none;
    display: inline-block;
}

footer .nav li span {
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
}

footer .nav li a,
footer .nav li span {
    font-family: 'vugolight', sans-serif;
    color: #ffffff;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    margin-right: 7px;
}

footer .nav li a:hover,
footer .nav li span:hover {
    color: #43c5e6;
    background: none;
}

footer .nav li a:focus,
footer .nav li span:focus {
    color: #43c5e6;
    background: none;
}

footer .nav li {
    position: relative;
    display: inline-block;
}

footer .nav li::before {
    content: "|";
    position: absolute;
    right: 0px;
    color: #f1f6f6;
    top: 10px;
}

footer .nav li:nth-last-child(1):before {
    display: none;
}

footer span.text-center {
    display: block;
    padding-bottom: 20px;
}

footer .navbar-right span {
    color: #98a6ad !important;
}

/*MENU*/
.navbar-right li ul.dropdown-menu-main, .navbar-right-dark li ul.dropdown-menu-main {
    width: 300px;
    background: #24b0d3 !important;
    border-radius: 0px !important;
}

.navbar-right li ul.dropdown-menu-main li, .navbar-right-dark li ul.dropdown-menu-main li {
    display: block;
}

.navbar-right li ul.dropdown-menu-main li a {
    display: block;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
    text-transform: uppercase;
    color: #ffffff !important;
}

.navbar-right-dark li ul.dropdown-menu-main li a {
    display: block;
    padding-left: 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: uppercase;
    color: #ffffff !important;
}

.navbar-nav {
    margin: 0px !important;
}

footer .navbar-nav {
    margin: 0px !important;
}

/*MENU END*/
.navbar-right li a.badge-search, .navbar-right-dark li a.badge-search {
    width: 36px;
    height: 36px;
    display: block !important;
    line-height: 36px !important;
    border-radius: 100%;
    font-size: 18px;
    text-align: center;
    padding-top: 15px !important;
    padding-bottom: 17px !important;
}

a.search-badge i {
    line-height: 35px;
    text-align: center;
}

.search-badge {
    background: #43c5e6;
    top: 2px !important;
    left: -5px;
    text-align: center;
    line-height: 35px;
    padding: 10px !important;
    width: 35px;
    height: 35px;
}

.search-badge:hover,
.search-badge:focus,
.search-badge:active {
    background: #70d3ec !important;
}

/*RIGHT MENU*/
.rightmenu {
    width: 100%;
    height: 100%;
    bottom: 80px;
    background: #f1f6f6;
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding-left: 8px;
    padding-right: 7px;
    /*padding: 15px;*/
}
.rightmenu-dark {
    width: 100%;
    height: 100%;
    bottom: 80px;
    color:white;
    background: #1d1f20;
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding-left: 8px;
    padding-right: 7px;
    /*padding: 15px;*/
}

.right-section {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: solid 1px #575959;
}

.right-section h4,
.subfooter h4,.subfooter h3 {
    color: #000000;
    text-transform: uppercase;
    font-family: 'vugo', sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.right-section ul {
    margin: 0px;
    padding: 0px;
}

a.politician {
    color: #000000;
    font-family: 'vugo', sans-serif;
    font-size: 16px;
    position: relative;
    display: inline-block;
    clear: both;
    margin-bottom: 15px;
}

a.politician .poliname {
    color: #43c5e6;
    font-family: 'vugo', sans-serif;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
}

a.politician .poliname:hover {
    color: #ec1d45;
}

a.politician .poli-info {
    display: inline-block;
    float: right;
    width: 170px;
}

a.politician .occupation {
    font-size: 14px;
    color: #000000;
    font-family: 'vugolight', sans-serif;
}

a.politician .country {
    font-size: 14px;
    color: #969696;
    font-family: 'vugolight', sans-serif;
    display: block;
}

a.politician img {
    margin-right: 15px;
    position: relative;
    width: 50px;
    height: unset;
    float: left;
    transition: all .2s;
}

a.politician img:hover {
    border: solid 3px #43c5e6;
}

a.politician span.gender {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 35px;
    top: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    display: block;
    padding: 3px 3px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

span.male {
    /*background: url("../images/ico_male.svg") 50% 50% no-repeat;*/
    /*background-size: 60%;*/
}

span.female {
    /*background: url("../images/ico_female.svg") 50% 50% no-repeat;*/
    /*background-size: 60%;*/
}

span.verified{
    background: url("../images/verified.svg") 50% 50% no-repeat;
    background-size: 60%;
}

.right-section a.viewall {
    text-transform: uppercase;
    font-family: 'vugo', sans-serif;
    letter-spacing: 2px;
    font-size: 14px;
    text-align: center;
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
}

.events li {
    border-bottom: solid 1px #969696;
}

.events li a {
    text-transform: uppercase;
    font-family: 'vugo', sans-serif;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 15px;
    padding-bottom: 15px;
    display: block;
    padding-top: 15px;
}

.events li:last-child {
    border: 0px;
}

.events li:last-child a {
    padding-bottom: 0px;
}

.events li:first-child a {
    padding-top: 0px;
}

a.square {
    display: block;
    position: relative;
    min-width: 200px;
    background: #43c5e6;
    color: #ffffff;
    padding: 15px;
    transition: all .2s;
}

a.square:hover {
    background: #24b0d3 !important;
}

a.square .date {
    display: inline-block;
    font-size: 24px;
    font-family: 'vugolight', sans-serif;
    color: #ffffff;
}

a.square .explanation {
    display: block;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
}

.superscript {
    vertical-align: super;
    font-size: 12px;
}

/*RIGHT MENU END */
/*LEFT CONTENT START */
.content-page {
    margin-top: 50px;
}

.leftcontent {
    /* margin-top: 20px;*/
    margin-left: 0px;
    margin-right: 0px;
    padding: 0 15px 15px 10px;
    margin-bottom: 20px;
}

.politiqbox {
    background: #f7fcfc;
    padding: 10px;
}
.politiqbox-dark {
    background: #1d1f20 !important;
    padding: 10px;
}

.politiqbox header {
    margin-bottom: 5px;
}
.politiqbox-dark header {
    margin-bottom: 5px;
}

.politiqbox h2 {
    font-size: 18px;
    line-height: 18px;
}
.politiqbox-dark h2 {
    color: white;
    font-size: 18px;
    line-height: 18px;
}

.politiqbox h2 img {
    margin-top: -10px;
}
.politiqbox-dark h2 img {
    margin-top: -10px;
}

.politiqbox .articleimage,
.politiqbox .row politicians-row img {
    float: left;
    margin-right: 15px;
}
.politiqbox-dark .articleimage,
.politiqbox-dark .row politicians-row img {
    float: left;
    margin-right: 15px;
}

.politiqbox .smallmargintop {
    margin-top: 10px;
    margin-right: 5px;
}
.politiqbox-dark .smallmargintop {
    margin-top: 10px;
    margin-right: 5px;
}

/*LEFT CONTENT END */
/*ARTICLE LIST*/
.btn-toolbar {
    transition: all .2s;
    border: 0 !important;
}

.btn-toolbar:hover .pol {
    color: #43c5e6;
}

/*ARTICLE LIST END*/

/*ARTICLE LIST END*/
.articles {
    position: relative;
    margin-top: 20px;
}
.card-block-new{
    display: flex;
}
.head{
    margin: -32px ;
}

article,
.comment,
.friendslist {
    position: relative;
    background: #ffffff;
    padding: 10px;
    margin-bottom: 15px;
}

@media (max-width: 767px) {

    article,
    .comment,
    .friendslist {
        padding: 5px;
    }

}

@media (max-width: 767px) {
    .text-left {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center!important;
    }
    .slide-content-about{
        position: absolute !important;
        top: 30% !important;
        left: 0% !important;
        z-index: 10000 !important;
    }
    .title-accent{
        line-height: 7rem;
    }
    .home-message{
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
    .home-describe, .home-contain{
        margin-right: 10px !important;
        margin-left: 10px !important;
        display: contents;
    }
    .footer-left-content{
        margin-left: 15px;
    }
    .bgh{
        margin-top: -90px !important;
    }
}

article .additional,
.comment .additional,
.friendslist .additional {
    border-bottom: solid 1px #e3ebef;
}

article .btn-toolbar,
.comment .btn-toolbar,
.friendslist .btn-toolbar {
    margin-bottom: 10px;
}

article header,
.comment header,
.friendslist header {
    position: relative;
    margin-top: 5px;
}

article header .authorimage,
.comment header .authorimage,
.friendslist header .authorimage {
    margin-right: 5px;
    float: left;
}

@media (max-width: 767px) {

    article header .authorimage,
    .comment header .authorimage,
    .friendslist header .authorimage {
        margin-right: 0;
    }
}

article header .friendimage,
.comment header .friendimage,
.friendslist header .friendimage {
    margin-right: 15px;
    float: left;
}

article header .articletitle,
.comment header .articletitle,
.friendslist header .articletitle {
    margin-bottom: 5px;
}

.comment-box{
    display: flex;
    flex-direction: row;
}

.post-comment-box-title,.post-comment-box-title-dark{
    padding: 10px;
    padding-right: 20px;
    /*flex: 1 0 auto;*/
    width: -webkit-fill-available;
    margin: 0;
    background: #f1f6f6;
    border-radius: 6px;
}

.comment-option{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.comment-bottom-border{
    border-bottom: solid 1px #e3ebef;;
}

.comment-option button{
    border: none;
    background: transparent;
    color: #0b5d9b;
    font-size: 13px;
    font-weight: bold;
}

.comment-option button:disabled{
    pointer-events: none;
    opacity: 0.5;
}

.post-comment-box-title .author{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    color: #000000;
}
.post-comment-box-title-dark .author{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    color: #ffffff;
}
.post-comment-box-title a{
    color: #000000;
}
.post-comment-box-title-dark a{
    color: #ffffff;
}
.post-comment-box-title a:hover{
    color: #000000;
}
.post-comment-box-title-dark a:hover{
    color: #ffffff;
}
article header .author,
.comment header .author,
.friendslist header .author {
    font-size: 14px;
    font-family: 'vugolight', sans-serif;
}

article header .author a,
.comment header .author a,
.friendslist header .author a {
    color: #43c5e6;
    text-transform: none;
    font-family: 'vugo', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
}

article header .author a:hover,
.comment header .author a:hover,
.friendslist header .author a:hover {
    color: #70d3ec;
}

article header .author img,
.comment header .author img,
.friendslist header .author img {
    margin-top: -10px;
    display: inline-block;
    margin-right: 15px;
}

article header h2,
.comment header h2,
.friendslist header h2 {
    white-space: normal;
    display: inline !important;
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0 !important;
    line-height: 18px !important;
}

article header h2 a,
.comment header h2 a,
.friendslist header h2 a {
    font-size: 20px;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
}

article header a:hover,
.comment header a:hover,
.friendslist header a:hover {
    color: #43c5e6;
}

article .articleimage,
.comment .articleimage,
.friendslist .articleimage {
    margin-top: 10px;
    margin-bottom: 15px;
}

article .tags,
.comment .tags,
.friendslist .tags {
    margin-top: 15px;
    float: left;
}

article .tags a,
.comment .tags a,
.friendslist .tags a {
    font-family: 'vugo', sans-serif;
    font-size: 14px;
    padding: 3px;
    color: black;
}

article .tags a:hover,
.comment .tags a:hover,
.friendslist .tags a:hover {
    background: #caeff8;
    border-radius: 4px;
}

article .buttonlist,
.comment .buttonlist,
.friendslist .buttonlist {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

article .buttonlist .pol:hover,
.comment .buttonlist .pol:hover,
.friendslist .buttonlist .pol:hover {
    color: #43c5e6;
}

article .buttonlist .pol:active,
.comment .buttonlist .pol:active,
.friendslist .buttonlist .pol:active {
    color: #43c5e6;
}

article .buttonlist .pol:focus,
.comment .buttonlist .pol:focus,
.friendslist .buttonlist .pol:focus {
    color: #43c5e6;
}

article .buttonlist .icon-dislike,
.comment .buttonlist .icon-dislike,
.friendslist .buttonlist .icon-dislike {
    display: block;
    margin-top: 5px;
}

article .buttonlist .icon-comment,
.comment .buttonlist .icon-comment,
.friendslist .buttonlist .icon-comment {
    font-size: 30px;
}

article .date,
.comment .date,
.friendslist .date {
    color: #969696;
    font-size: 12px;
}

article .comments,
.comment .comments,
.friendslist .comments {
    margin-left: 0px;
}

article .comments .comment,
.comment .comments .comment,
.friendslist .comments .comment {
    margin-top: 10px;
    position: relative;
    border-bottom: none;
}

article .comments .author,
.comment .comments .author,
.friendslist .comments .author {
    line-height: 30px;
}

article .comments .articletitle,
.comment .comments .articletitle,
.friendslist .comments .articletitle {
    margin-bottom: 0px;
}

article .comments .text,
.comment .comments .text,
.friendslist .comments .text {
    margin-left: 55px;
    margin-top: 0px;
}

article .comments .date,
.comment .comments .date,
.friendslist .comments .date {
    margin-left: 55px;
    margin-top: 10px;
    margin-bottom: 10px;
}

article .viewall,
.comment .viewall,
.friendslist .viewall {
    text-transform: uppercase;
    font-family: 'vugo', sans-serif;
    letter-spacing: 2px;
    color: #ec1c45;
    font-size: 14px;
    text-align: right;
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
}

article .viewall:hover,
.comment .viewall:hover,
.friendslist .viewall:hover {
    color: #f04b6c;
}

#searchdiv.active {
    display: block !important;
    position: absolute;
    width: 100%;
    display: flex;
    left: 0px;
    bottom: 0px;
    background: #0a2737;
    transition: all .4s;
}

#searchdiv.active .input-group {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}

#searchdiv.active .input-group input.form-control {
    border: none !important;
    outline: none !important;
    border-radius: 0px;
}

#searchdiv {
    transition: all .4s;
}

.navbar {
    /*transition: all 1s;*/
}

.navbar.active, .navbar.active-dark {
    transition: all 1s;
    height: 120px;
}

#tabbar {
    z-index: 9999;
    width: 100%;
    background: #43c5e6;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 50px;
    box-shadow: -2px 2px 5px 2px rgba(0, 0, 0, 0.5);
}

#tabbar .buttonlist {
    margin-left: 20px;
    margin-top: 6px;
}

#tabbar .buttonlist .btn-icon:hover i {
    color: #0a2737;
}

#tabbar .buttonlist .btn-icon.active, #tabbar .buttonlist .btn-icon.active-dark {
    color: #0a2737;
}

#tabbar .buttonlist #showarticles.active i, #tabbar .buttonlist #showarticles.active-dark i {
    color: #0a2737;
}

#tabbar .buttonlist #showpoliticians.active i {
    color: #0a2737;
}
#tabbar .buttonlist #showpoliticians.active-dark i {
    color: #ffffff;
}

#tabbar .buttonlist i {
    font-size: 28px;
    color: #ffffff;
}

.topbar .navbar {
    z-index: 2;
}

.rightmenu.active, .rightmenu.active-dark {
    box-shadow: none;
    position: relative;
    width: 100%;
    display: block !important;
    /*z-index:9997;	*/
    left: 10px;
    bottom: auto;
    top: auto;
    background: transparent;
    padding: 30px;
}

.hidden {
    display: none;
}

.privacylink,
.fullpost {
    position: relative;
    top: 8px;
    margin-right: 15px;
    display: inline-block;
    white-space: normal;
    color: #6d6f6f;
    cursor: pointer;
}

.privacy-setting-dark{
    color: #46bcf2 !important;
}

.fullpost {
    color: #43c5e6;
}

.modal-header, .modal-header-dark {
    padding: 15px !important;
    border: 0;
    background: #43c5e6;
    margin-bottom: 15px !important;
    display: flex;
    justify-content: space-between;
}

.modal-header .close {
    color: #afb6b9;
    opacity: 1 !important;
    margin-top: -10px;
}
.modal-header-dark .close {
    color: #afb6b9;
    opacity: 1 !important;
    margin-top: -10px;
}

.modal-header h4, .modal-header-dark h4 {
    color: #ffffff !important;
}

.modal h4 {
    color: #43c5e6;
    letter-spacing: 2px;
    font-size: 14px;
    text-transform: uppercase;
}

.fade {
    opacity: 1 !important;
}

.modal-backdrop.fade {
    opacity: 0.5 !important;
}

.addtagsdiv {
    display: block;
    border-radius: 4px;
    line-height: normal;
    text-align: left;
    overflow: hidden;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}

.contextmenu {
    position: absolute;
    top: 15px;
    right: 5px;
}

.dropdown-menu {
    border-radius: 0px;
    z-index: 999999 !important;
}

.textareadiv {
    background: #ffffff;
    transition: all .9s;
}

.textareadiv.active, .textareadiv.active-dark {
    margin-top: 0px;
    position: absolute;
    width: 100%;
    height: 450px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
}

.textareadiv.active textarea, .textareadiv.active-dark textarea {
    resize: none;
    margin: 15px;
    width: 100%;
    height: 400px;
    padding: 10px;
}

.politiqbox.active, .politiqbox.active-dark {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
.politiqbox-dark.active, .politiqbox-dark.active-dark {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.leftcontent.active, .leftcontent.active-dark {
    width: 100%;
    margin: 0;
    padding: 0;
}

#moodtoolbar.active {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-top: -10px;
    top: 0;
    left: 0;
    padding: 4px;
    background: #f7fcfc;
    position: fixed;
}
#moodtoolbar.active-dark {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-top: -10px;
    top: 0;
    left: 0;
    padding: 4px;
    background: #1d1f20;
    position: fixed;
}

textarea.form-control.with-border{
    border: solid 1px #969696 !important;
    min-height: 65px;
}
textarea.form-control-dark.with-border {
    border: solid 1px #969696 !important;
    min-height: 65px;
    color: white;
}


.politicalmood {
    padding: 5px;
    background: #ffffff;
    transition: all .2s;
}

.politicalmood:focus {
    border: solid 1px #43c5e6;
}

/*textarea*/
.noscroll {
    overflow: hidden;
}

.hiddendiv {
    display: none;
    white-space: pre-wrap;
    width: 500px;
    min-height: 50px;
    font-family: Arial, sans-serif;
    font-size: 13px;
    padding: 5px;
    word-wrap: break-word;
}

.subfooter .comment {
    padding: 0;
    border-bottom: solid 1px #cad1d1;
    background: transparent;
    margin-bottom: 0 !important;
}

.subfooter .comment .text {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 10px;
    min-height: 60px;
}

.subfooter .col-md-4 {
    padding: 0px;
}

.subfooter .col-md-4:nth-of-type(1) {
    padding-right: 10px;
}

.subfooter .col-md-4:nth-of-type(3) {
    padding-left: 10px;
}

.subfooter .politiqbox {
    min-height: 320px;
}
.subfooter .politiqbox-dark {
    min-height: 320px;
}

.subfooter ul {
    margin: 0;
    padding: 0;
}

.inthenews .text a {
    font-family: 'vugo', sans-serif;
}

.checkdiv {
    margin-top: 10px;
}

h4.handwritten {
    text-transform: none !important;
    font-size: 26px;
    font-family: 'Caveat', cursive;
}

.pol-card {
    margni-top: 40px;
}

.bio {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: solid 1px #cad1d1;
    border-bottom: solid 1px #cad1d1;
    line-height: 18px;
    font-family: "vugolight",sans-serif;
    margin-top: 10px !important;
    font-weight: normal !important;
}

.privacylink i {
display: block;
float: left;
margin-right: 5px;
font-size: 22px;
margin-top: -2px;
}

/*LOGIN/SIGNUP STYLES*/
#loginsection .bcg,
#signupsection .bcg {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 auto;
    /*transform: translate(0%, 50%) matrix(1, 0, 0, 1, 0, 0);*/
}

#loginsection header,
#signupsection header {
    position: relative;
    text-align: center;
}

#loginsection header .logo,
#signupsection header .logo {
    width: 220px !important;
    position: relative;
    margin: 5px auto 10px auto;
    display: block;
}

.fullscreen {
    display: flex;
    flex: 1 auto;
    min-height: 100%;
    width: 100%;
}

#loginsection {
    background: #43c5e6 url('../images/loginill.png') center bottom no-repeat;
    background-size: cover;
    background-position: center top !important;
    display: flex;
    flex: 1 auto;
    min-height: 100%;
    width: 100%;
    background-attachment: fixed;
    -webkit-animation: myfirst 1s;
    /* Chrome, Safari, Opera */
    animation: myfirst 1s;
}

#signupsection {
    background: #43c5e6 url('../images/signupill.png') center bottom no-repeat;
    display: flex;
    flex: 1 auto;
    min-height: 100%;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    -webkit-animation: myfirst 1s;
    /* Chrome, Safari, Opera */
    animation: myfirst 1s;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes myfirst {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

#loginsection .hsContent,
#signupsection .hsContent {
    max-width: 400px;
    margin: 130px auto;
    border-radius: 10px;
    padding: 0px;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.52);
}

#loginsection .hsContent .content,
#signupsection .hsContent .content {
    padding: 10px;
    margin-top: 0px !important;
}

#loginsection .hsContent .content .btn:focus,
#signupsection .hsContent .content .btn:focus,
#loginsection .hsContent .content .btn:hover,
#signupsection .hsContent .content .btn:hover {
    background: #ec1c45 !important;
}

#loginsection .hsContent .content a,
#signupsection .hsContent .content a {
    font-size: 14px;
    color: #ec1c45;
    text-decoration: underline;
    text-align: center;
}

#loginsection .hsContent .content .signuplink,
#signupsection .hsContent .content .signuplink {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

#loginsection .hsContent .content .signuplink:hover,
#signupsection .hsContent .content .signuplink:hover {
    color: #ec1c45;
}

#loginsection .hsContent .content .form-group,
#signupsection .hsContent .content .form-group {
    margin-bottom: 5px;
}

#loginsection .hsContent .content .checkboxgroup label,
#signupsection .hsContent .content .checkboxgroup label {
    color: #000000;
}

#advsignupsection .hsContentsignup {
    max-width: 650px;
    margin: 0px auto;
    border-radius: 4px;
    padding: 20px 40px 20px 40px;
    background: #46bcf2;
}

.hsContainer {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    /* overflow: hidden;*/
    table-layout: fixed;
    width: 100%;
    text-align: center;
}

#loginsection h1 {
    font-family: "vugobold", sans-serif;
    font-size: 30px;
    letter-spacing: 2px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    margin: 0px;
    padding-top: 15px;
}

#loginsection label {
    display: inline-block;
    color: #ffffff;
    font-weight: normal;
    text-align: left !important;
}

#loginsection input[type="checkbox"] {
    display: none;
    float: left;
}

#loginsection input.form-control,
#loginsection select,
#loginsection .bfh-selectbox {
    max-width: 450px;
    margin-bottom: 11px;
    background: #ffffff;
    color: #000000;
    font-size: 16px;
    font-family: "vugolight";
}

.loginroot {
    width: 100% !important;
}

.loginroot nav {
    width: 100% !important;
}

.loginroot .footer {
    z-index: 100;
}

.aflexbox  {
    width: 100%;
    margin: auto;
    position: relative;
    border-bottom: 1px solid #ddd;
    display: inline-block;
    text-align: center;
}
.aflexbox-fullPost  {
    width: 100%;
    margin: auto;
    position: relative;
    display: inline-block;
    text-align: center;
}

.flexbox ul {
    border-bottom: 0px solid #ddd;
}

.nav>li>a {
    position: relative;
    display: flex;
    /*padding: 10px 10px !important;*/
}

.menuholder {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    flex: 1 auto;
}

.navbar-nav .dropdown-toggle::after {
    display: none;
}

.greyrow {
    background: #f7fcfc;
}

legend,
h4 {
    color: #000000;
    text-transform: uppercase;
    font-family: 'vugo', sans-serif;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.list-group-item h4 {
    color: #0a2737;
    margin: 0;
    padding: 0;
}

.privacy-list {
    padding: 15px;
    background: #e5faff;
    margin-bottom: 10px;
    margin-left: 0px !important;
}
.privacy-list-dark {
    padding: 15px;
    background: #1d1f20;
    margin-bottom: 10px;
    margin-left: 0px !important;
}

.explain {
    text-transform: none;
    letter-spacing: 0px;
    font-weight: normal;
    font-family: 'vugolight', sans-serif;
}

.nav-tabs .nav-item.active {
    position: relative;
    color: #43c5e6;
    border-bottom: solid 4px #43c5e6;
}
.nav-tabs-dark .nav-item.active-dark {
    position: relative;
    color: #ffffff;
    border-bottom: solid 4px #43c5e6;
}

.nav-tabs .nav-item.active:before, .nav-tabs-dark .nav-item.active-dark:before {
    font-family: "feather";
    content: "\e097";
    position: absolute;
    top: -20px;
    font-size: 20px;
    margin-left: -10px;
    left: 50%;
    color: #ec1c45;
}

.tab-pane {
    width: 100%;
}

.settings h2 {
    margin-top: 0px !important;
    margin-bottom: 30px;
}

.greybg {
    background: #f1f6f6;
}

.list-group-item {
    background: transparent !important;
    border: none !important;
}

/*INPUT*/
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile-2+label {
    color: #4acf95;
    border: 2px solid #4acf95;
}

.inputfile+label {
    max-width: 80%;
    font-size: 15px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 5px;
    border-radius: 6px;
    transition: all .2s;
}

.inputfile+label:hover {
    border: solid 2px #43c5e6;
    background: #43c5e6;
    color: #ffffff;
}

.avatarbox h4 {
    color: #000000 !important;
}

.locationlist li {
    margin-bottom: 10px;
    padding: 0;
}

.alert {
    padding: 3px;
    text-align: center;
}

.alert:before {
    content: "\f071";
    font-family: "FontAwesome";
}

#contentlocations .form-control {
    float: left;
    display: inline-block;
    margin-right: 5px;
    width: 89%;
}

#contentlocations .divradio,
.divradio+#PlacesAutocomplete__root {
    float: left;
}

#PlacesAutocomplete__autocomplete-container img {
    height: 20px;
}

.divradio+#PlacesAutocomplete__root .form-control {
    width: 99%;
}

.divradio+#PlacesAutocomplete__root {
    width: 85%;
}

.politicians-row .settings img {
    margin-right: 0px;
    position: relative;
    width: 80px;
    height: 80px;
    float: none;
    transition: all .2s;
}

.politicians-row .settings img:hover {
    border: solid 3px #43c5e6;
}

.accountdiv {
    width: 50%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/* NEW STYLES */
.top-user-span {
    white-space: pre-line;
    max-width: 120px;
    display: block;
    float: right;
    margin-left: 10px;
    line-height: 16px;
}

.img-circle {
    border-radius: 10% !important;

}

.dropdown-toggle.contextmenu:after {
    display: none;
}

.full-page .articletitle {
    padding-bottom: 15px;
    border-bottom: solid 1px #edf1f1;
}

.full-page .page {
    background: #ffffff;
}
.full-page .page-dark {
    background: #1d1f20;
}

.greybg .comment {
    background: transparent;
}

.card-footer, .card-footer-dark {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
    padding-right: 0px;
}

.btn-private,
.btn-private:hover,
.btn-private:focus {
    text-indent: -99999px;
    width: 50px;
    height: 40px;
    background: url("../images/icon_private2.svg") center center no-repeat;
}

.btn-private-dark,
.btn-private-dark:hover,
.btn-private-dark:focus {
    margin-top: 24px !important;
    text-indent: -99999px;
    width: 50px;
    height: 40px;
    background: url("../images/private.svg") center center no-repeat;
}

.btn-group,
.btn-group:hover,
.btn-group:focus {
    text-indent: -99999px;
    width: 50px;
    height: 40px;
    background: url("../images/icon_group2.svg") center center no-repeat;
}
.btn-group-dark,
.btn-group-dark:hover,
.btn-group-dark:focus {
    margin-top: 24px !important;
    text-indent: -99999px;
    width: 50px;
    height: 40px;
    background: url("../images/group2.svg") center center no-repeat;
}

.btn-public,
.btn-public:hover,
.btn-public:focus {
    text-indent: -99999px;
    width: 50px;
    height: 40px;
    background: url("../images/icon_public2.svg") center center no-repeat;
}

.btn-public-dark,
.btn-public-dark:hover,
.btn-public-dark:focus {
    margin-top: 24px !important;
    text-indent: -99999px;
    width: 50px;
    height: 40px;
    background: url("../images/public.svg") center center no-repeat;
}

.btn-public:hover,
.btn-public:focus:hover,
.btn-group:hover,
.btn-group:focus:hover,
.btn-private:hover,
.btn-private:focus:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, .25);
    box-shadow: 0 0 0 2px rgba(2, 117, 216, .25);
}

.btn-public-dark:hover,
.btn-public-dark:focus:hover,
.btn-group-dark:hover,
.btn-group-dark:focus:hover,
.btn-private-dark:hover,
.btn-private-dark:focus:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, .25);
    box-shadow: 0 0 0 2px rgba(2, 117, 216, .25);
}

.btn-public:focus,
.btn-group:focus,
.btn-private:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-public-dark:focus,
.btn-group-dark:focus,
.btn-private-dark:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.commentset {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-left: 41px;

}
.commentse{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-left : 3px;
}


.commentset li {
    margin: 5px;
}
.migrating-table{
    width: 60%;
}
.migrating-table tr td{
    padding: 12px;
}

@media (max-width: 767px) {
    .commentset li {
        margin: 0;
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .migrating-table{
        width: 100%;
    }
}

.commentset li:last-child {
    flex: 3 100% 40%;
}

.mentions__control_new {
    display: flex;
    flex-flow: row wrap;
}

.mentions__control_new h4 {
    display: block;
    width: 100%;
    flex: 1 100%;
}

.mentions__control_new div {
    flex: 1 auto;
    display: inline-block;
}

.mentions__control_new div.commentbox {
    flex: 30 0px;
}

.commentset .replylink {
    font-size: 14px !important;
    display: flex;
    margin-top: 10px;
    text-indent: 50px !important;
    font-weight: 300;
    font-family: 'vugolight', sans-serif;
    text-transform: none;
    letter-spacing: 0px;
    width: auto !important;
    background-position: 0 0;
    color: #43c5e6;
}

.commentset .reply_private {
    text-indent: 30px !important;
}

.commentset .reply_public {
    text-indent: 40px !important;
}

.commentset .replylink1 {
    font-size: 14px;
    display: inline-block;
    margin-top: 10px;
    text-indent: -99999px !important;
    font-weight: 300;
    font-family: 'vugolight', sans-serif;
    text-transform: none;
    letter-spacing: 0px;
    background-size: 90%;
    color: #43c5e6;
    border: solid 1px #43c5e6;
}

.pointer {
    cursor: pointer !important;
}

.btn-remove-post {
    float: right;
    text-transform: uppercase;
    font-size: 10px;
    padding: 10px;
    margin-left: 5px;
    letter-spacing: 2px;
    color: #ffffff;
    background-color: red;
}

.election-row {
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
}

.election-row .square {
    width: 30%;
    float: left;
}

.election-row .politicians-text-card {
    width: 70%;
    float: left;
    padding-left: 10px;
}

.btn-image {
    padding: 3px 5px !important;
    line-height: 36px;
}

.imgicon {
    display: inline-block;
    width: 36px;
    margin: 3px;
    float: left;
}

.politicians-row .imgicon img {
    height: auto;
    width: 36px;
}

.btn-border {
    background: transparent;
    border: solid 1px #43c5e6;
    color: #43c5e6;
    font-weight: 500;
}

.btn-border-stance {
    background: #43c5e6;
    color: #ffffff;
}

.btn-border-stance:hover,
.btn-border-stance:focus {
    background: transparent;
    border: solid 1px #43c5e6;
    color: #43c5e6;
    font-weight: 500;
}

.audiences .btn-border {
    min-width: 190px;
}

.btn-border:hover,
.btn-border:focus {
    background: #43c5e6;
    color: #ffffff;
}

.btn-border.selected,
.btn-group-lg>.btn,
.btn-lg.selected {
    background: #43c5e6
        /*url("../images/audiences_btn.svg")*/
        5px 5px no-repeat;
    background-size: 36px;
    color: #ffffff;
    padding-left: 40px;
}

ul.aflexbox, ul.aflexbox-fullPost {
    margin: 0px;
    padding-left: 0px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

ul.aflexbox li {
    margin: 5px;
}

.friendslist ul {
    margin: 0;
    padding: 0;
}

.audience,
.friendslist {
    margin-top: 15px;
    padding: 10px;
}

.friendslist ul li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 26px;
}

.friendslist ul li a {
    color: #0a2737;
}

.politicians-row .friendslist img {
    width: 40px;
    height: 40px;
    margin-bottom: 0;
}

.friend {
    line-height: 40px;
}

.friendslist i.icon {
    position: absolute;
    left: 0;
    top: 15px;
}

.friend.added a {
    font-weight: 600;
}

.friendslist i.icon img {
    width: 18px;
    height: auto;
    margin-right: 10px;
    display: inline-block;
}

.politicians-row .audience h4,
.politicians-row .friendslist h4 {
    color: #0a2737;
}

.red {
    color: #ec1c45;
}

.audience .friendslist ul li {
    padding-left: 0;
}

.disabled {
    pointer-events: none;
}

.btn-delete {
    width: 26px;
    height: auto;
    display: inline-block;
}

.icon-close {
    width: 13px;
    height: 13px;
    display: inline-block;
    background: url("../images/close.svg") 0 0 no-repeat;
}

.inline-block {
    display: inline-block;
}

.full {
    width: 100% !important;
}

.static-home input {
    background-color: #ffffff;;
    border: none;
    font-weight: bold;
    border-bottom: solid 0px #ffffff;
}

.static-home input:focus {
    outline-offset: 0;
    background-color: #ffffff;
    color: black;
    border: none;
    border-bottom: solid 2px #ffffff;
}

.static-home label {
    color: #fff;
    font-weight: normal;
    font-size: 13px;
    font-family: "vugo";
    text-transform: uppercase;
}

.fa-icon {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
}

.fa-icon:hover {
    opacity: 0.7;
}

.fa-facebook {
    background: #3B5998;
    color: white !important;
    width: 40px;
}

.fa-twitter {
    background: #55ACEE;
    color: white !important;
    width: 40px;
}

.fa-youtube {
    background: #bb0000;
    color: white !important;
    width: 40px;
}

.thumbvote, .thumbvote-dark {
    margin-top: 15px;
}

.thumbvote a {
    display: inline-block;
    margin-right: 25px;
    padding-left: 48px;
    color: #1f2223;
    height: 47px;
    font-size: 13px;
    line-height: 35px;
    transition: all .2s;
}
.thumbvote-dark a {
    display: inline-block;
    margin-right: 25px;
    padding-left: 48px;
    color: #ffffff;
    height: 45px;
    font-size: 13px;
    line-height: 35px;
    transition: all .2s;
}

.thumbvote a:hover {
    color: #000000;
}
.thumbvote-dark a:hover {
    color: #ffffff;
}

.thumbvote a#maybe, .thumbvote-dark a#maybe {
    background: url("../images/maybe.svg") 0 0 no-repeat;
    background-size: 43px 35px;
}

.thumbvote a#maybe:hover,.thumbvote-dark a#maybe:hover {
    background: url("../images/maybe_1.svg") 0 0 no-repeat;
    background-size: 43px 35px;
}

.thumbvote a#maybe-voted, .thumbvote-dark a#maybe-voted {
    background: url("../images/maybe_1.svg") 0 0 no-repeat;
    background-size: 43px 35px;
}

.thumbvote a#downvote, .thumbvote-dark a#downvote {
    background: url("../images/down.svg") 0 4px no-repeat;
    background-size: 35px 35px;
}

.thumbvote a#downvote-voted, .thumbvote-dark a#downvote-voted {
    background: url("../images/down_1.svg") 0 4px no-repeat;
    background-size: 45px 45px;
}

.thumbvote a#downvote:hover, .thumbvote-dark a#downvote:hover {
    background: url("../images/down_1.svg") 0 4px no-repeat;
    background-size: 45px 45px;
}

.thumbvote a#upvote, .thumbvote-dark a#upvote {
    background: url("../images/up.svg") 0 0 no-repeat;
    background-size: 35px 35px;
}

.thumbvote a#upvote:hover, .thumbvote-dark a#upvote:hover {
    background: url("../images/up_1.svg") 0 0 no-repeat;
    background-size: 45px 45px;
}

.thumbvote a#upvote-voted, .thumbvote-dark a#upvote-voted  {
    background: url("../images/up_1.svg") 0 0 no-repeat;
    background-size: 45px 45px;
}

/*.maybe-voted-cls{*/
/*    background-size: 45px 45px !important;*/
/*    height: 47px !important;*/
/*}*/

.vision-line{
    margin-top: 0.5rem !important;
}

.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
}

.social-share {
    font-size: 14px;
    margin-top: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-top: solid 1px #cad1d1;
    border-bottom: solid 1px #cad1d1;
    line-height: 18px;
}

.btn-model {
    background-color: #e6e6e6;
    color: #202020;
    border: 2px solid #555555;
    border-radius: 5px;
    float: right;
}

.btn-model:hover {
    background-color: #a6a9ba;
    color: white;
}

.wall-div {
    margin: 45px 0px 5px 0px
}

.wall-div textarea {
    width: 100%;
    height: 100px;
}

.dropLanguage {
    position: absolute;
    top: 25px;
    right: 15px;
    background: #111;
}
.dropLanguage-footer {
    top: 25px;
    right: 15px;
    background: #fff;
    color: black;
}

@media (max-width: 992px) {
    .polly-modal {
        padding: 5px;
    }
}

@media (min-width: 1200px) {
    .modal-content {
        overflow-y: auto;
        height: auto;
    }

    .modal .modal-dialog {
        top: 240px;
    }
}

.border-radius-10 {
    border-width: 0px;
    border-radius: 10px !important;
}

.kep-login-facebook {
    background: #386bb1 !important;
}

.rightmenu .right-section>ul>li {
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.rightmenu .right-section>ul>li .thumbvote, .rightmenu .right-section>ul>li .thumbvote-dark {
    margin-top: 0;
}

.rightmenu .right-section>ul>li .thumbvote a, .rightmenu .right-section>ul>li .thumbvote-dark a {
    background-size: 35px 30px !important;
    padding-left: 40px !important;
    margin-right: 5px !important;
    width: 80px !important;
}

.rightmenu .right-section>ul>li .polscores .vote-col-name span {
    font-size: 12px;
}

.rightmenu .right-section>ul>li a.politician {
    margin-bottom: 0;
}

.ability .rangeslider .rangeslider__handle {
    display: none !important;
}

.vision .rangeslider .rangeslider__handle {
    display: none !important;
}

.ability .rangeslider .rangeslider__fill {
    background-color: #dd4965 !important
}

.share-modal {
    margin-bottom: 12px !important;
    height: 25px !important;
}

.share-modal-area {
    min-height: 100px !important;
}




.btn-polly-un-watch,
.btn-polly-watch {
    padding: 2px 4px !important;
    text-align: center;
    height: 33px;
    font-size: 11px;
    letter-spacing: 0;
    border: 2px solid #43c5e6 !important;
    min-width: 110px;
    margin-top: 5px;
    white-space: pre-wrap;
}

.btn.btn-polly-watch.active, .btn.btn-polly-watch.active-dark {
    background: #ec1c45 !important;
    color: #333 !important;
    border: #ec1c45 !important;
    opacity: 1;
}

#votepopup .modal-body {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
#votepopup .modal-body-dark {
    margin-top: 0 !important;
    padding-top: 0 !important;
    background: #000000;
}

#votepopup .pollname {
    font-family: 'vugolight', sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #43c5e6;
    margin: 0 !important;
    padding: 0 !important;
}

#votepopup .pollname span {
    font-weight: normal;
    display: block;
    font-size: 16px;
    color: #8c9398;
}

#votepopup .mainpolinfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#votepopup .mainpolinfo .polthumb img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 15px;
    border: solid 1px #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#votepopup .polscores {
    margin-left: 15px;
    flex: 0 0 50%;
}

#votepopup .polscores .thumbvote, #votepopup .polscores .thumbvote-dark {
    margin: 0;
}

#votepopup .rangeslider-horizontal .rangeslider__handle {
    width: 20px !important;
    height: 20px !important;
    border-radius: 20px !important;
    top: 2px !important;
    transform: translate3d(-50%, -50%, 0);
}

#votepopup .rangeslider .rangeslider__handle {
    background: #43c5e6;
    border: 0px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

#votepopup .rangeslider__handle.fill_pink {
    background: #dd4965;
}

#votepopup .howdoyoufeel {
    background: #f1f6f6;
    padding: 15px;
    flex: 1 100%;
    border-radius: 10px;
    margin-top: 15px;
    flex-wrap: wrap;
}

#votepopup .howdoyoufeel-dark{
    background: #272d2d;
    padding: 15px;
    flex: 1 100%;
    border-radius: 10px;
    margin-top: 15px;
    flex-wrap: wrap;
}

#votepopup .howdoyoufeel h3, #votepopup .howdoyoufeel-dark h3 {
    font-family: 'vugo', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    flex: 1 100%;
    margin-top: 0;
}

#votepopup .toolbar {
    flex: 0 0 10%;
    margin-left: auto;
}

#votepopup .votescore {
    flex: 1 auto;
}

#votepopup .commentarea {
    flex: 0 0 50%;
}

.btn-info {
    background: #43c5e6 !important;
    color: #ffffff;
}

.btn-vote {
    border: solid 1px #43c5e6;
    color: #43c5e6;
    letter-spacing: 0px !important;
    font-weight: 600;
    font-style: normal;
    padding: 7px 30px 7px 7px !important;
    background: url('../images/vote_icon.svg') 95% 3px no-repeat;
    background-size: 30%;
}

.btn-vote:hover {
    background: #ffffff url('../images/vote_icon.svg') 95% 3px no-repeat !important;
    background-size: 30% !important;
}

.btn-vote:focus {
    background: #ffffff url('../images/vote_icon.svg') 95% 3px no-repeat !important;
    background-size: 30% !important;
}

.tag {
    background: #e6ebeb;
    border-radius: 6px;
    padding: 8px 85px 8px 5px !important;
    position: relative;
}
.secondTag{
    margin-right: 5px !important;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background: #e6ebeb;
    border-radius: 6px;
    padding: 8px 5px 8px 5px !important;
    position: relative;
}

.tag .tagname  {
    font-weight: bold;
    color: #000000 !important;
}

.tag  .tagname:hover {
    background: transparent;
    color: #43c5e6;
}

.tag  .btn-vote {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #edf1f1;
    border: none;
    border-radius: 0px 6px 6px 0px;
}

.tag  .btn-vote:hover {
    background-color: #43c5e6 !important;
    color: #ffffff !important;
    border: none !important;
    border-radius: 0px 6px 6px 0px !important;
    outline: none !important;
}

.tag  .btn-vote:focus {
    background-color: #43c5e6 !important;
    color: #ffffff !important;
    border: none !important;
    border-radius: 0px 6px 6px 0px !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn-share {
    padding: 5px !important;
    float: right;
}

.modal-header-sm {
    margin: 0px !important;
    padding: 0px !important;
}

.modal-header-sm .close {
    margin-right: 12px;
}

.modal-header-sm h3 {
    font-size: 20px !important;
    margin-left: 15px;
}

.login-twitter {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s, border-color .3s;
    background-color: #00ACEE;
    border: 1px solid #ffffff;
    font-size: 12px !important;
    border-radius: 5px;
    margin-left: 5px;
    color: white;
}

.btn-twitter {
    background: #00ACEE;
    border-radius: 0;
    color: #fff;
    font-weight: 700;
    border: solid 1px #0075a2;
}

.btn-twitter:active,
.btn-twitter:hover {
    background: #0075a2;
    color: #fff;
}

.login-modal-box {
    width: 350px;
    margin: auto;
}

.login-modal-box .logo {
    width: 125px;
}

.login-modal-box .checkBoxGroup button {
    float: right;
}

.login-modal-box .login-twitter {
    float: right;
}

.login-modal-box .btn-register {
    float: right;
}

.modal-user .modal-dialog .modal-header, .modal-user .modal-dialog .modal-header-dark {
    background: #ececec !important;
    text-align: center !important;
    margin-top: 0px !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

/*.modal-user .modal-dialog .modal-header .modal-title{*/
/*text-align: center;*/
/*}*/

/*.modal-user .modal-dialog header {*/
/*text-align: center !important;*/
/*}*/

.btn-square {
    padding: 12px;
    width: 38px;
    height: 38px;
}

.transparent {
    opacity: 0.5;
}

.form-control-new {
    border: solid 1px #afb6b9;
    border-radius: 6px;
}

.invite-friend input {
    height: 38px;
    padding-left: 5px;
}

.invite-friend textarea {
    min-height: 150px;
    min-width: 450px;
}

.invite-friend .btn {
    margin-left: 5px;
    margin-bottom: 5px;
}

.invite-friend .modal-header, .invite-friend .modal-header-dark {
    background-color: #43c5e6 !important;
    margin-top: 0px !important;
}

.rightmenu .right-section .pointer {
    background-color: #43c5e6;
    color: white;
    padding: 7px 5px;
}

.rightmenu .right-section .thumbvote a#maybe:hover, .rightmenu .right-section .thumbvote-dark a#maybe:hover {
    background: url("../images/maybe.svg") 0 0 no-repeat;
    background-size: 43px 35px;
}

.rightmenu .right-section .thumbvote a#downvote:hover, .rightmenu .right-section .thumbvote-dark a#downvote:hover {
    background: url("../images/down.svg") 0 4px no-repeat;
    background-size: 35px 35px;
}

.rightmenu .right-section .thumbvote a#upvote:hover, .rightmenu .right-section .thumbvote-dark a#upvote:hover {
    background: url("../images/up.svg") 0 0 no-repeat;
    background-size: 35px 35px;
}

.privacy-header .modal-header, .privacy-header .modal-header-dark {
    background-color: #43c5e6;
    margin-top: 0px !important;
}

.privacy-header .modal-dialog .modal-content .modal-footer {
    padding-bottom: 10px !important;
    padding-top: 0px !important;
}

/* --- login siginup --- */

@-webkit-keyframes autofill {
    to {
        color: #45c4e4;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

#logincontent {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-self: stretch;
    z-index: 2;
    flex: 1 100%;
}

#root,
#root>div {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.logincontainer {
    left: 0;
    min-height: 100%;
    top: 0;
    width: 100%;
    position: relative;
    display: flex;
    background-size: cover;
    flex: 1 auto;
}

.loginContainer .login-content .content {
    top: 153px;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 2px;
    position: relative;
    text-align: center;
    padding: 0;
    background: #fff;
    overflow: hidden;
}

.full {
    width: 100% !important;
}

.navbar-light, .navbar-light-dark {
    position: fixed;
    margin-bottom: 0 !important;
    padding-top: 0;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    top: 0;
    background: #f1f6f6;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    min-height: 70px;
    z-index: 3;
}


.navbar-brand {
    display: inline-block;
    background: url(../images/logo.svg) 0 0 no-repeat;
    background-size: contain;
    text-indent: -99999px;
    min-width: 150px;
    padding: 0px;
    flex: 0 0 150px;
    margin-left: 20px !important;
    margin-bottom: 5px;
}
.navbar-brand-dark {
    display: inline-block;
    background: url(../images/logowhite.svg) 0 0 no-repeat;
    background-size: contain;
    text-indent: -99999px;
    min-width: 150px;
    padding: 0px;
    flex: 0 0 150px;
    margin-left: 20px !important;
    margin-bottom: 5px;
}

#signupsection .form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#signupsection .form-group input {
    flex: 1;
    border: 1px solid #969696;
    border-radius: 4px;
    padding: 7px 12px;
    height: 38px;
    font-size: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

#signupsection .form-group a {
    margin-left: 5px;
    margin-top: 3px;
    padding: 6px !important;
    border-radius: 50%;
    align-self: center;
    background: #e1f5fa;
}

#signupsection .form-group a i {
    color: #ec1c45;
}

#loginsection h4.handwritten,
#signupsection h4.handwritten {
    font-family: 'Crimson Text', serif;
    color: #ec1c45;
    letter-spacing: 0px;
    margin-top: -10px;
}

#loginsection header,
#signupsection header {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px
}

#loginsection header .logo,
#signupsection header .logo {
    border: solid 10px #ffffff;
    border-radius: 50%;
    margin-top: -40px;
    align-self: center;
    width: 85px !important;
    height: 85px !important;
    padding: 0px !important;
}

#loginsection .hsContent .content .actionbuttons,
#signupsection .hsContent .content .actionbuttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

#loginsection .hsContent .content .actionbuttons input,
#signupsection .hsContent .content .actionbuttons input {
    display: block;
    width: 100%;
}

#loginsection .hsContent .content .actionbuttons a,
#signupsection .hsContent .content .actionbuttons a {
    font-size: 13px;
    text-decoration: none;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.mt-3 {
    margin-top: 8px;
}

.handwritten {
    font-family: 'Caveat', cursive;
    font-size: 28px;

}

.justify-content-between {
    justify-content: space-between;
}

#loginsection .bcg .content .fa-twitter,
#loginsection .bcg .content .fa-facebook {
    background: none !important;
}

#signupsection .bcg .content .fa-twitter,
#signupsection .bcg .content .fa-facebook {
    background: none !important;
}

#signupsection .bcg .content .actionbuttons .btn-facebook,
#signupsection .bcg .content .actionbuttons div,
#loginsection .bcg .content .actionbuttons span {
    width: 100%;
}

.full-page .page .additional .tags .tag , .full-page .page-dark .additional .tags .tag  {
    margin-right: 5px !important;
}

.vote-popup .modal-header, .vote-popup .modal-header-dark {
    background: white !important;
    margin-bottom: 0px !important;
}
.vote-popup .modal-header-dark {
    background: #43c5e6 !important;
    margin-bottom: 0px !important;
}

.vote-modal .thumbvote, .vote-modal .thumbvote-dark {
    margin-top: 0px !important;
}

#votepopup .modal-body .mainpolinfo textarea, #votepopup .modal-body-dark .mainpolinfo textarea {
    border: solid 1px #969696 !important
}

.home-page .text-center .url-preview-img {
    height: unset !important;
}

.modal-user .hsContent {
    margin: 40px auto !important;
}

.modal-user #loginsection .bcg .login-view-modal .metro {
    padding-left: 67px !important;
    padding-right: 67px !important;
}

.modal-user #loginsection .bcg .signup-view-modal .metro {
    padding-left: 59px !important;
    padding-right: 59px !important;
}

.home-page .content-page .card-wrapper .card-info .url-preview-img {
    height: unset !important;
}

.politicians .articletitle .author a {
    color: #43c5e6 !important;
}

.image-vote {
    width: 37px !important;
}

.vote-maybe {
    width: 44px !important;
}

.text-comment {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 10px;
    min-height: 30px;
}

.card-wrapper .card-info .card, .card-wrapper .card-info .card-dark {
    margin: unset !important;
}

.input-btn-header {
    float: left;
    width: 80%;
}

.search-btn-header {
    width: 18%;
    float: right;
}

.vote-delete-btn {
    padding: 4px;
    float: right;
    height: 26px;
}

.polly-vote-details .politiqbox .row img:hover {
    border: unset !important;
}
.polly-vote-details .politiqbox-dark .row img:hover {
    border: unset !important;
}

.party-vote-details .politiqbox .row img:hover {
    border: unset !important;
}
.party-vote-details .politiqbox-dark .row img:hover {
    border: unset !important;
}

#newsection .card .mainpolinfo .polthumb,#newsection .card-dark .mainpolinfo .polthumb {
    min-height: 105px !important;
}

/*NEW STYLES Feb 14 2019*/
.primary-politicians li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.primary-politicians li .main {
    display: inline-flex;
    width: 100%;
    padding-right: 100px;
}

.primary-politicians li .vote-percent {
    color: #43c5e6;
    font-size: 34px;
    /*margin-right: 95px;*/
    display: inline-flex;
}

.primary-politicians li.hli {
    background: #caeff8;
    border-radius: 15px;
}

.primary-politicians li:hover {
    border-radius: 15px;
}

.primary-politicians li .poli-img img {
    object-fit: scale-down;
    margin-right: 15px;
    width: 90px;
    height: 90px;
    flex: 0 1 auto;
    cursor: pointer;
    position: relative;
}

.primary-politicians li .poli-img img.checked:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.primary-politicians li .politician {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /*margin-right: 95px;*/
}

.primary-politicians li .politician span.gender {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 75px;
    top: -10px;
    background-color: #fff;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    display: block;
    padding: 3px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.primary-politicians li .politician .poliname {
    font-size: 20px;
    font-weight: bold;
}

.primary-politicians li .politician .country {
    display: block;
}

.primary-politicians li [type="radio"]:checked,
.primary-politicians li [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.primary-politicians li [type="radio"]:checked+label,
.primary-politicians li [type="radio"]:not(:checked)+label {
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.primary-politicians li [type="radio"]:checked+label:before,
.primary-politicians li [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 45px;
    height: 45px;
    border: 6px solid #ffffff;
    transition: all 0.3s;
    border-radius: 100%;
    transform: translate(-50%, -80%);
}

.primary-politicians li [type="radio"]:checked+label:after,
.primary-politicians li [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: url("../images/voted.svg") 0 0 no-repeat;
    position: absolute;
    top: -25px;
    left: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.primary-politicians li [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.primary-politicians li [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.primary-politicians li .vote-input {
    width: 90px;
    height: 90px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    background: #43c5e6;
    border-radius: 12px;
    overflow: hidden;
}

.primary-politicians li .vote-input:after {
    content: "VOTE!";
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    bottom: 10px;
    font-size: 12px;
}

.primary-politicians li .vote-input .date {
    position: absolute;
    bottom: 4px;
    font-size: 12px;
    color: #43c5e6;
}

.primary-politicians li .vote-input.checked {
    background: #e1f6fb;
    border-radius: 15px;
}

.primary-politicians li .vote-input.checked [type="radio"]:checked+label:before,
.primary-politicians li .vote-input.checked [type="radio"]:not(:checked)+label:before {
    border: 10px solid #ffffff;
}

.primary-politicians li .vote-input.checked:after {
    content: "YOU VOTED!";
    color: #0a2737;
    font-weight: bold;
    position: absolute;
    bottom: 18px;
    font-size: 12px;
}

.primary-politicians li .thumbvote {
    position: relative;
    /*z-index: 18;*/
    flex: 0 0 100%;
    width: 100%;
    background: #f1f6f6;
    align-items: center;
    margin-top: 3px;
    flex-wrap: wrap;
}
.primary-politicians li .thumbvote-dark {
    position: relative;
    /*z-index: 18;*/
    flex: 0 0 100%;
    width: 100%;
    background: #272d2d;
    align-items: center;
    margin-top: 3px;
    flex-wrap: wrap;
}

.primary-politicians li .thumbvote a , .primary-politicians li .thumbvote-dark a {
    font-size: 14px;
    line-height: 25px;
    height: auto;
    margin-right: 15px;
    padding-left: 38px;
}

.primary-politicians li .thumbvote a:hover, .primary-politicians li .thumbvote-dark a:hover {
    color: #43c5e6;
}

.primary-politicians li .thumbvote a.comments-number, .primary-politicians li .thumbvote-dark a.comments-number {
    background: url("../images/comments.svg") 0 40% no-repeat;
    background-size: 33px 25px;
    position: relative;
    display: block;
    font-weight: bold;
}

.primary-politicians li .thumbvote a.votes-number, .primary-politicians li .thumbvote-dark a.votes-number {
    background: url("../images/votes.svg") 0 40% no-repeat;
    background-size: 33px 25px;
    position: relative;
    display: block;
}

.primary-politicians li .thumbvote a.comment-write, .primary-politicians li .thumbvote-dark a.comment-write {
    background: url("../images/comment1.svg") 0 40% no-repeat;
    background-size: 33px 25px;
    position: relative;
    display: block;
    color: #43c5e6;
    font-weight: bold;
}

.primary-politicians li .collapse {
    width: 100%;
    margin-top: 15px;
    flex: 0 0 100%;
    padding: 10px;
}

.primary-politicians li textarea {
    border: 1px solid #edf1f1 !important;
}

.primary-politicians li .comment .authorimage img.img-circle {
    border-radius: 50% !important;
}

.ml-auto {
    margin-left: auto;
}

.comment .authorimage img.img-circle {
    border-radius: 50% !important;
}

.comment .comment-body {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.comment .comment-body img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-right: 15px;
}

.subfooter .politiqbox {
    min-height: 320px;
    height: 100%;
}
.subfooter .politiqbox-dark {
    min-height: 320px;
    height: 100%;
}

.undecided {
    font-family: 'Caveat', cursive;
    color: #ec1c45;
}

.primary-politicians li .vote-input:hover {
    width: 90px;
    height: 90px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    background: #e1f6fb;
    border-radius: 12px;
    overflow: hidden;
}

.primary-politicians li .vote-input:hover:after {
    font-weight: bold;
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    border-radius: 15px;
    color: black;
}

.loading-spinner {
    background: url("../images/spinner.gif") center no-repeat;
    background-size: 125px 100px;
}

.election-cmt {
    max-height: 300px;
    overflow-x: auto;
}

#election-cmt-scr::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

#election-cmt-scr::-webkit-scrollbar-thumb {
    background-color: #43c5e6;
    border: 1px solid #d3e0e6;
}

article .buttonlist,
.comment .buttonlist,
.friendslist .buttonlist {
    margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
article .buttonlist img,
.comment .buttonlist img,
.friendslist .buttonlist img {
    width: 30px;
    height: 30px;
}
article .buttonlist .dropdown-toggle,
.comment .buttonlist .dropdown-toggle,
.friendslist .buttonlist .dropdown-toggle {
    width: unset;
    height: unset;
}
article .buttonlist .dropdown,
.comment .buttonlist .dropdown,
.friendslist .buttonlist .dropdown {
    display: flex;
    align-items: center;
}
article .buttonlist > div,
.comment .buttonlist > div,
.friendslist .buttonlist > div {
    position: relative;
}
article .buttonlist .pol:hover,
.comment .buttonlist .pol:hover,
.friendslist .buttonlist .pol:hover {
    color: #43c5e6;
}
article .buttonlist .pol:active,
.comment .buttonlist .pol:active,
.friendslist .buttonlist .pol:active {
    color: #43c5e6;
}
article .buttonlist .pol:focus,
.comment .buttonlist .pol:focus,
.friendslist .buttonlist .pol:focus {
    color: #43c5e6;
}
article .buttonlist .icon-dislike,
.comment .buttonlist .icon-dislike,
.friendslist .buttonlist .icon-dislike {
    display: block;
    margin-top: 5px;
}
article .buttonlist .icon-comment,
.comment .buttonlist .icon-comment,
.friendslist .buttonlist .icon-comment {
    font-size: 30px;
}

article .buttonlist .dropdown {
    min-width: 100px;
}
article .buttonlist .dropdown:nth-child(1) .btn-toolbar:before {
    content: 'Importance';
}
article .buttonlist .dropdown:nth-child(2) .btn-toolbar:before {
    content: 'Trust';
}
article .buttonlist .dropdown:nth-child(3) .btn-toolbar:before {
    content: 'Positive Tone';
}
article .buttonlist .dropdown:nth-child(4) .btn-toolbar:before {
    content: 'Relevance to me';
}
article .buttonlist .btn-toolbar {
    margin-bottom: 0 !important;
    padding-right: 4px !important;
    position: relative;
}
article .buttonlist .btn-toolbar:before {
    display: flex;
    color: #ffffff;
    text-align: center;
    left: 0;
    line-height: 17px;
    font-size: .6em;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    background-color: #000000;
    border-radius: 20px;
    position: absolute;
    top: -26px;
    font-size: 11px;
    opacity: 0;
    text-transform: none !important;
    letter-spacing: 0px !important;
    transition: opacity 0.2s ease-in-out 0s;
}
article .buttonlist .btn-toolbar:hover {
    transform: scale(1.2, 1.2);
}
article .buttonlist .btn-toolbar:hover:before {
    opacity: 1;
}

.buttonvalue {
    display: block;
    font-weight: 600;
    font-size: 10px;
}

.comment_card {
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
}
.comment_card .comment_options {
    width: 100%;
    flex: 1 0 100%;
    margin-top: 10px;
}
.comment_card .comment_options button {
    float: right;
    margin-left: auto;
    border: none;
    background: transparent;
    color: #0b5d9b;
    font-size: 13px;
    font-weight: bold;
}
.comment_card .comment_text {
    padding: 10px;
    background: transparent;
    flex: 1 0 auto;
    margin: 0;
    background: #f1f6f6;
    border-radius: 6px;
}
.comment_card .comment_text .date {
    color: #8c9398;
    font-size: 12px;
}
.comment_card h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
}
.comment_card .figure {
    margin: 0;
    display: flex;
    flex: 0 0 40px;
}
.comment_card .figure img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.buttonlist button.dropdown-toggle:hover {
    background-color: transparent !important;
}

.buttonlist button.dropdown-toggle:focus {
    background-color: transparent !important;
}

.post-rating, .post-rating-dark {
    width: auto;
    min-width: 24px;
    height: 24px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.post-rating.good {
    background: rgba(4, 187, 137, 0.2);
    color: #04bb89;
}
.post-rating.bad {
    background: rgba(198, 56, 25, 0.2);
    color: #c63819;
}
.post-rating.neutral {
    background: #f1f6f6;
    color: #000000;
}
.post-rating-dark.neutral {
    background:#272d2d !important;
    color: #8e9ea7 !important;
}

.ant-popover-inner-content {
    height: 170px;
    text-align: -webkit-center;
}

.ant-slider-vertical {
    height: 100px;
}

.ant-popover-title {
    min-width: unset;
}

.buttonlist .dropdown-toggle::after {
    margin-left: unset;
    border-left: unset;
    border-right: unset;
}

.sidebar-color{
    color: white !important;
}
.sidebar-bg{
    background-color: #43c5e6;
    color: white;
    padding: 7px 5px;}
.post-preview-dark{
    background-color: #1d1f20 !important;
    color: white;
}
.post-title-dark{
    color: white;
}
.mt6{
    margin-top: 6px !important;
}
.mt12{
    margin-top: 12px !important;
}
.cw{
    color: white !important;
}
.bgm{
    background: #1d1f20;
}
.bgw{
    background: #ffffff;
}
.bgl{
    background: #272d2d;
}
.bgf{
    background: #000000;
}
.dark .ant-table-thead th{
    background: #272d2d;
    color:white;
}
.dark .ant-table-tbody tr{
    color:white;
}
.dark .ant-table-tbody tr:hover {
    color: #1aa0c3;
}
.dark .ant-steps-item-title{
    color: white !important;
}
.dark .cdw{
    color: white;
}
.dark .modal-body{
    background-color:  #272d2d;
}
.dark .modal-footer{
    background-color:  #272d2d;
}
.dark .modal-content {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #272d2d;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
}

.dark .ant-popover-inner-content{
background:#272d2d !important; ;
}
.dark .anticon {
    display: inline-block;
     color: white !important;
    background-color:#1d1f20 ;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dark .ant-pagination-item-link{
    color: white !important;
    background-color:#1d1f20 !important; ;
}
.dark .ant-pagination-item{
    color: white;
    background-color:#1d1f20 ;
}
.custom-dp:hover{
    background-color: #43c5e6 !important;
}
.pt-75{
    padding-top: 75px !important;
}
.dark .fileuploader-input-caption {
    position: relative;
    display: inline-block;
    flex: 1 1;
    align-self: flex-start;
    padding: 10px 15px;
    margin-right: 15px;
    background:#1d1f20;
    border: 1px solid #dde4f6;
    border-radius: 4px;
    color: #575757;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all .2s ease-out;
}
.dark .react-tagsinput-tag {
    display: inline-block;
    background-color: #000000;
    color: white;
    border-radius: 7px;
    padding: 2px 4px;
    margin-right: 5px;
    opacity: 1;
    margin: 0 5px 4px 0;
}
.dark .bootstrap-tagsinput-dark input{
    width: 100%;
    position: relative;
    z-index: 1;
    color: white !important;
}
.dark .react-autosuggest__suggestions-container{
    color: white;
}
.dark .react-tagsinput-input{
    color: white;
}
.goal-border{
    border: 1px solid #969696 !important;
}
.user-box-name{
    margin-top: -8px;
    margin-left: 5px;
}
.fr{
    float: right;
}
.ml-35{
    margin-left: -35px!important;
}
.election-candidates:hover{
    background-color: #1d1f20 !important;
}
.politiqbox .smallmargintop {
    margin-top: 10px;
}
.quick-btn .btn {
    padding: 12px !important;
    border: 0 !important;
    -webkit-box-shadow:  0 2px 5px 0 #00000033;
    box-shadow: 0 2px 5px 0 #00000033;
    font-weight: 500;
    font-size: .75rem;
    line-height: 0.5;
    vertical-align: bottom;
    color: #4f4f4f;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
}
.quick-btn .btn {
padding: 12px 42px;
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
font-size: 14px;
font-weight: bold;
min-width: 200px;
position: relative;
}

.dark .quick-btn .btn:hover {
   background-color: #1d1f20!important;
    transition: color .30s ease-in-out,background-color .30s ease-in-out,border-color .30s ease-in-out,box-shadow .30s ease-in-out,-webkit-box-shadow .30s ease-in-out;
    -webkit-box-shadow: 0 5px 10px 0 #00000033;
    box-shadow: 0 5px 10px 0 #00000033;
}
.quick-btn .btn:hover {
    transition: color .30s ease-in-out,background-color .30s ease-in-out,border-color .30s ease-in-out,box-shadow .30s ease-in-out,-webkit-box-shadow .30s ease-in-out;
    -webkit-box-shadow: 0 5px 10px 0 #00000033;
    box-shadow: 0 5px 10px 0 #00000033;
}

.quotation {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}
.quotation:before {
    top: 0;
    left: 0;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    border-left: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
    border-top: solid 1px #ffffff;
}
.quotation > div {
    color: #ffffff;
    font-family: "PT Serif";
    font-size: 20px;
}
.quotation .author {
    width: 100%;
    text-align: right;
    padding-right: 80px;
    position: absolute;
    margin-right: 20px;
    color: white;
    bottom: 0px;
}
.quotation .author .name {
    position: relative;
    top: 10px;
    font-family: "Poppins";
    font-size: 14px;
    font-style: italic;
    color: #ffffff;
}
.quotation .author:after {
    bottom: 0;
    right: 0px;
    display: flex;
    position: absolute;
    width: 70px;
    height: 1px;
    content: "";
    border-top: solid 1px #ffffff;
}
.quotation .author:before {
    bottom: 0;
    left: 0px;
    display: flex;
    position: absolute;
    width: 120px;
    height: 1px;
    content: "";
    border-top: solid 1px #ffffff;
}
.btn-special{
    border-radius:4px;
    box-shadow:none;
    position:relative;
    align-items:center;
    padding:10px 42px;
    min-width:150px;
    justify-content:center;
    text-align:center;
    transition:all 0.2s;
}

.btn-special{
    border-radius:4px;
    box-shadow:none;
    position:relative;
    align-items:center;
    padding:10px 42px;
    min-width:150px;
    justify-content:center;
    text-align:center;
}
header #cta .btn-special {
    background: #258fff;
    border-radius: 4px;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 10px 42px;
    color: #ffffff;
    min-width: 150px;
    justify-content: center;
    text-align: center;
    transition: all 0.2s;
}
.btn-special {
    background: #258fff;
    border-radius: 4px;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 10px 42px;
    color: #ffffff;
    min-width: 150px;
    justify-content: center;
    text-align: center;
    transition: all 0.2s;
}
.btn-special:hover {
    background: #014980 !important;
    color: #ffffff;
}
.btn-special span {
    right: 10px;
}
.header li  a {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 14px;
}
.header li  a:hover {
    color: #0a2737;
}
.mb-8,
.my-8 {
    margin-bottom: 3.5rem !important;
}
.mt-8,
.my-8 {
    margin-top: 3.5rem !important;
}
.Latest-vote-header{
    font-size: 42px !important;
    font-weight: bold !important;
    font-family: "PT Serif", sans-serif ;
    color: #258fff !important;
}
#kid{
    background:url("../../src/images/homepage/kidbg.png") 0 0 no-repeat;
    background-size:contain;

}

#kid h5{
    font-family:"PT Serif";
    font-size:20px;
}

.bgh{
    background-color: #fefdfb !important;
    margin-top: -80px;
}
#second h2{
    font-size: 42px !important;
    font-weight: bold !important;
}
.benefits-one{

    background:url("../../src/images/homepage/paperbg.png") 0 0 repeat;

}
.benefits-one, .benefits-two, .benefits-three{

    padding-top:0px;
    padding-bottom:0px;
}
.slider .color-overlay {
    position: fixed;
    background: linear-gradient(
            0deg
            , #161e2e 10%, #2356d8b3 90%);
    height: 100%;
    top: 0;
    width: 100% !important;
    opacity: 0.4 !important;
}
.slide2{
    font-size: 40px;
    font-weight: bold !important;
    }
#screenshot {
    background: #ffffff;
    padding-top: 45px;
}
#screenshot .icontext p {
    font-size: 13px;
}
#screenshot h5 {
    color: #258fff;
}
#screenshot .icontext p{

    font-size:13px;

background-color:white;

}
.fourth .card{
    height: auto;
}
.bgw{
    background: white !important;
}
.cb{
    color: #258fff;
}
.fw-700{
    font-weight: 700 !important;
}
.third-overlay .color-overlay {
    position: absolute;
    background: linear-gradient(0deg, #161e2e 10%, rgba(35, 86, 216, 0.7) 90%);
    height: 100%;
    top: 0;
    width: 100%;
    opacity: 0.4;
}
.cb{
    color: black !important;
}
.pf{
    position: fixed !important;
}
.header-animation {
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 5px;
    z-index: 100000;
    box-shadow: 0 0 0.875rem 0 rgba(0, 0, 0, 0.2);
}

.scroll-header{
    position: fixed !important;
    z-index: 1000 !important;
    background: aliceblue !important;
    left: 5px !important;
    align-items: center;
}
.navbar-scrolled{
    color: black;
    font-weight: bold !important;
}
.mr-0{
    margin: 0!important;
}
.mr-30{
    padding: 30px 0px;
}
.pt-20{
    padding-top: 20px;
}
.pad-20{
    padding: 0px 0px 10px 0px !important;
}
.bg-footer{
    background: #000000;
}
.bg-header{
    margin-top: -2px !important;
    background: #ffffff;
}
.footer-section{
    flex-direction: column;
    line-height: 2;
    text-align: left;
}
.footer-link{
    color: white !important;
}
.footer-link:hover{
    color: #43c5e6 !important;
}
.footer-home{
    position: relative !important;
}
.main-slide{
    position: relative !important;
}
.slide-content{
    position: absolute !important;
    top: 30% !important;
    z-index: 10000 !important;
}
.slide-content-about{
    position: absolute !important;
    top: 30% !important;
    left: 10%;
    z-index: 10000 !important;
}
.slide-image{
    filter: blur(8px);
}
.sign-up-line-height{
    line-height: 30px;
}
.bg-show{
    background: #f7fcfc !important;
}
.pointer-event{
    cursor: pointer;
}
.pointer-event:hover {
    color: #43c5e6
}
.note-nothing-post{
    text-align: center;
    margin-top: 50px;
    font-weight: 700;
}
.custom-gender-child{
    margin-left: 20px;
    width: 50% !important;
    border-radius: 4px !important;
}
.custom-gender{
    display: flex;
    align-items: baseline;
}
.dropLanguage-main{
    align-items: flex-start !important;
    display: flex !important;
}
.mt-100{
    margin-top: 100px;
}
button.dropdown-toggle-home {
    margin-top: 0px;
    border-radius: 0px !important;
    background: transparent !important;
    color: #258fff;
    border: 0;
    border: none;
    display: inline-block;
    height: 70px;
    width: 70px;
    font-size: 22px;
    padding: 0;
    text-align: center;
}
.home ul{
    background: transparent !important;
}

.home-w-scroll{
    align-items: baseline !important;
}
.delete-comment{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer;
}
.ant-popover-inner-content {
    height: auto;
    text-align: inherit;
}
.p-l-20{
    padding-left: 20px !important;
}
.ant-picker{
    border: solid 1px #969696 !important;
}
.features-title{
    color: #078bff;
    font-size: 18px;
    font-weight: 600;
}
.features{
    margin-bottom: 100px;
}
.m-t-section1{
    margin-top:100px
}
.fit-width{
    width: fit-content;
}
.static-page{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
}
.p-b-90{
    padding-bottom: 90px !important;
}
.cb{
    color: black !important;
}
.mt-50{
    margin-top: 50px;
}
.desktop-width{
    height: auto !important;
}
.mentions__control textarea{
    overflow: inherit !important;
}
.p-b-10{
    padding-bottom: 10px !important;
}
.mention-textarea .mentions__input{
    min-height: 202px !important;
    resize: vertical !important;
    position: unset !important;
}

.mention-textarea .mentions__highlighter{
    display: none !important;
}
.max-counter{
    position: relative;
    left: 80%;
    z-index: 1;
    font-weight: bolder
}
.submit-content-party-details{
    display: contents;
}
.quick-post-upload-img img{
    width: 150px !important;
}
.text-wrap{
    overflow-wrap: anywhere !important;
}
.handle-abuse-row{
    background-color: #fe4043;
}
.handle-abuse-row:hover > td {
    background-color: #fe4043 !important;
}
.post-activity .ant-table{
    overflow: auto;
}
.slug-pointer{
    cursor: pointer;
}
.pb-200{
    padding-bottom: 200px;
}
.handle-status-draft-row{
    background-color: #cccccc;
}
.admin-setting-table-width{
    max-height: 70vh !important;
}
.topic-header{
    display: flex;
    justify-content: space-between;
}
.topic-stances-header-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.stance-edit-text{
    color: red;
}
.d-flex-align-item-base{
    display: flex;
    align-items: baseline;
}
.m-l-4{
    margin-left: 4px;
}
.f-w-n{
    font-weight: unset !important;
}
.topicStance-modify-button{
    display: flex;
    justify-content: space-between;
}
.topic-stance-header-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.polly-page-image {
    height: fit-content;
}
.truncate{
    color:#43c5e6
}
.group-short{
    display: flex;
    justify-content: end;
    align-items: center;
}
.member-sort-btn {
    padding: 2px 4px !important;
    text-align: center;
    height: 33px;
    font-size: 11px;
    letter-spacing: 0;
    border: 2px solid #43c5e6 !important;
    min-width: 110px;
    margin-top: 5px;
    white-space: pre-wrap;
    transition: all 0.5s;
}
.member-sort-btn:hover{
    border: 2px solid #43c5e6 !important;
    background: grey;
}
.member-sort-btn.active{
    border: 2px solid #43c5e6 !important;
    background: grey;
}
.election-page-comment-range {
    display: flex;
    justify-content: end;
}
.progress-bar-danger {
    background-color: #e90f10;
    height: 3px;
}
.progress-bar-warning{
    background-color: #ffad00;
    height: 3px;
}
.progress-bar-success{
    background-color: #02b502;
    height: 3px;
}
.modal-backdrop{
    z-index: 11;
}
.polly-btn{
    font-size: 11px !important;
}
.polly-profile-box{
    background-color: #ffffff;
    padding: 15px 5px;
    border-radius: 10px;
}
.blog-container ul li, .blog-container ol li{
    list-style: disc;
}
