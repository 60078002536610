@charset "UTF-8";

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url('./fonts/fontawesome-webfont.eot');
  src: url('./fonts/fontawesome-webfont.eot') format('embedded-opentype'), url('./fonts/fontawesome-webfont.woff2') format('woff2'), url('./fonts/fontawesome-webfont.woff') format('woff'), url('./fonts/fontawesome-webfont.ttf') format('truetype'), url('./fonts/fontawesome-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: .3em;
}
.fa.fa-pull-right {
  margin-left: .3em;
}
/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: .3em;
}
.fa.pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #ffffff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f003";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-question-circle-dark:before {
  content: "\f059";
  color: #43c5e6;
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f096";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}
.fa-won:before,
.fa-krw:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before {
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-sticky-note-o:before {
  content: "\f24a";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-hourglass-o:before {
  content: "\f250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}
.fa-hand-scissors-o:before {
  content: "\f257";
}
.fa-hand-lizard-o:before {
  content: "\f258";
}
.fa-hand-spock-o:before {
  content: "\f259";
}
.fa-hand-pointer-o:before {
  content: "\f25a";
}
.fa-hand-peace-o:before {
  content: "\f25b";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-safari:before {
  content: "\f267";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-calendar-plus-o:before {
  content: "\f271";
}
.fa-calendar-minus-o:before {
  content: "\f272";
}
.fa-calendar-times-o:before {
  content: "\f273";
}
.fa-calendar-check-o:before {
  content: "\f274";
}
.fa-industry:before {
  content: "\f275";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-map-o:before {
  content: "\f278";
}
.fa-map:before {
  content: "\f279";
}
.fa-commenting:before {
  content: "\f27a";
}
.fa-commenting-o:before {
  content: "\f27b";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-edge:before {
  content: "\f282";
}
.fa-credit-card-alt:before {
  content: "\f283";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-modx:before {
  content: "\f285";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-usb:before {
  content: "\f287";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-pause-circle-o:before {
  content: "\f28c";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stop-circle-o:before {
  content: "\f28e";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-percent:before {
  content: "\f295";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-envira:before {
  content: "\f299";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-wheelchair-alt:before {
  content: "\f29b";
}
.fa-question-circle-o:before {
  content: "\f29c";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-volume-control-phone:before {
  content: "\f2a0";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}
.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-handshake-o:before {
  content: "\f2b5";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-o:before {
  content: "\f2b7";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-book-o:before {
  content: "\f2ba";
}
.fa-vcard:before,
.fa-address-card:before {
  content: "\f2bb";
}
.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "\f2bc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-circle-o:before {
  content: "\f2be";
}
.fa-user-o:before {
  content: "\f2c0";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2";
}
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "\f2cd";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3";
}
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-eercast:before {
  content: "\f2da";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-snowflake-o:before {
  content: "\f2dc";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-meetup:before {
  content: "\f2e0";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("./fonts/materialdesignicons-webfont.eot");
  src: url("./fonts/materialdesignicons-webfont.eot") format("embedded-opentype"), url("./fonts/materialdesignicons-webfont.woff2") format("woff2"), url("./fonts/materialdesignicons-webfont.woff") format("woff"), url("./fonts/materialdesignicons-webfont.ttf") format("truetype"), url("./fonts/materialdesignicons-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mdi {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.mdi-access-point:before {
  content: "\F002";
}
.mdi-access-point-network:before {
  content: "\F003";
}
.mdi-account:before {
  content: "\F004";
}
.mdi-account-alert:before {
  content: "\F005";
}
.mdi-account-box:before {
  content: "\F006";
}
.mdi-account-box-outline:before {
  content: "\F007";
}
.mdi-account-card-details:before {
  content: "\F5D2";
}
.mdi-account-check:before {
  content: "\F008";
}
.mdi-account-circle:before {
  content: "\F009";
}
.mdi-account-convert:before {
  content: "\F00A";
}
.mdi-account-key:before {
  content: "\F00B";
}
.mdi-account-location:before {
  content: "\F00C";
}
.mdi-account-minus:before {
  content: "\F00D";
}
.mdi-account-multiple:before {
  content: "\F00E";
}
.mdi-account-multiple-minus:before {
  content: "\F5D3";
}
.mdi-account-multiple-outline:before {
  content: "\F00F";
}
.mdi-account-multiple-plus:before {
  content: "\F010";
}
.mdi-account-network:before {
  content: "\F011";
}
.mdi-account-off:before {
  content: "\F012";
}
.mdi-account-outline:before {
  content: "\F013";
}
.mdi-account-plus:before {
  content: "\F014";
}
.mdi-account-remove:before {
  content: "\F015";
}
.mdi-account-search:before {
  content: "\F016";
}
.mdi-account-settings:before {
  content: "\F630";
}
.mdi-account-settings-variant:before {
  content: "\F631";
}
.mdi-account-star:before {
  content: "\F017";
}
.mdi-account-star-variant:before {
  content: "\F018";
}
.mdi-account-switch:before {
  content: "\F019";
}
.mdi-adjust:before {
  content: "\F01A";
}
.mdi-air-conditioner:before {
  content: "\F01B";
}
.mdi-airballoon:before {
  content: "\F01C";
}
.mdi-airplane:before {
  content: "\F01D";
}
.mdi-airplane-landing:before {
  content: "\F5D4";
}
.mdi-airplane-off:before {
  content: "\F01E";
}
.mdi-airplane-takeoff:before {
  content: "\F5D5";
}
.mdi-airplay:before {
  content: "\F01F";
}
.mdi-alarm:before {
  content: "\F020";
}
.mdi-alarm-check:before {
  content: "\F021";
}
.mdi-alarm-multiple:before {
  content: "\F022";
}
.mdi-alarm-off:before {
  content: "\F023";
}
.mdi-alarm-plus:before {
  content: "\F024";
}
.mdi-album:before {
  content: "\F025";
}
.mdi-alert:before {
  content: "\F026";
}
.mdi-alert-box:before {
  content: "\F027";
}
.mdi-alert-circle:before {
  content: "\F028";
}
.mdi-alert-circle-outline:before {
  content: "\F5D6";
}
.mdi-alert-octagon:before {
  content: "\F029";
}
.mdi-alert-outline:before {
  content: "\F02A";
}
.mdi-alpha:before {
  content: "\F02B";
}
.mdi-alphabetical:before {
  content: "\F02C";
}
.mdi-altimeter:before {
  content: "\F5D7";
}
.mdi-amazon:before {
  content: "\F02D";
}
.mdi-amazon-clouddrive:before {
  content: "\F02E";
}
.mdi-ambulance:before {
  content: "\F02F";
}
.mdi-amplifier:before {
  content: "\F030";
}
.mdi-anchor:before {
  content: "\F031";
}
.mdi-android:before {
  content: "\F032";
}
.mdi-android-debug-bridge:before {
  content: "\F033";
}
.mdi-android-studio:before {
  content: "\F034";
}
.mdi-animation:before {
  content: "\F5D8";
}
.mdi-apple:before {
  content: "\F035";
}
.mdi-apple-finder:before {
  content: "\F036";
}
.mdi-apple-ios:before {
  content: "\F037";
}
.mdi-apple-keyboard-caps:before {
  content: "\F632";
}
.mdi-apple-keyboard-command:before {
  content: "\F633";
}
.mdi-apple-keyboard-control:before {
  content: "\F634";
}
.mdi-apple-keyboard-option:before {
  content: "\F635";
}
.mdi-apple-keyboard-shift:before {
  content: "\F636";
}
.mdi-apple-mobileme:before {
  content: "\F038";
}
.mdi-apple-safari:before {
  content: "\F039";
}
.mdi-application:before {
  content: "\F614";
}
.mdi-appnet:before {
  content: "\F03A";
}
.mdi-apps:before {
  content: "\F03B";
}
.mdi-archive:before {
  content: "\F03C";
}
.mdi-arrange-bring-forward:before {
  content: "\F03D";
}
.mdi-arrange-bring-to-front:before {
  content: "\F03E";
}
.mdi-arrange-send-backward:before {
  content: "\F03F";
}
.mdi-arrange-send-to-back:before {
  content: "\F040";
}
.mdi-arrow-all:before {
  content: "\F041";
}
.mdi-arrow-bottom-left:before {
  content: "\F042";
}
.mdi-arrow-bottom-right:before {
  content: "\F043";
}
.mdi-arrow-compress:before {
  content: "\F615";
}
.mdi-arrow-compress-all:before {
  content: "\F044";
}
.mdi-arrow-down:before {
  content: "\F045";
}
.mdi-arrow-down-bold:before {
  content: "\F046";
}
.mdi-arrow-down-bold-circle:before {
  content: "\F047";
}
.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048";
}
.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049";
}
.mdi-arrow-down-drop-circle:before {
  content: "\F04A";
}
.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B";
}
.mdi-arrow-expand:before {
  content: "\F616";
}
.mdi-arrow-expand-all:before {
  content: "\F04C";
}
.mdi-arrow-left:before {
  content: "\F04D";
}
.mdi-arrow-left-bold:before {
  content: "\F04E";
}
.mdi-arrow-left-bold-circle:before {
  content: "\F04F";
}
.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050";
}
.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051";
}
.mdi-arrow-left-drop-circle:before {
  content: "\F052";
}
.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053";
}
.mdi-arrow-right:before {
  content: "\F054";
}
.mdi-arrow-right-bold:before {
  content: "\F055";
}
.mdi-arrow-right-bold-circle:before {
  content: "\F056";
}
.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057";
}
.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058";
}
.mdi-arrow-right-drop-circle:before {
  content: "\F059";
}
.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A";
}
.mdi-arrow-top-left:before {
  content: "\F05B";
}
.mdi-arrow-top-right:before {
  content: "\F05C";
}
.mdi-arrow-up:before {
  content: "\F05D";
}
.mdi-arrow-up-bold:before {
  content: "\F05E";
}
.mdi-arrow-up-bold-circle:before {
  content: "\F05F";
}
.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060";
}
.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061";
}
.mdi-arrow-up-drop-circle:before {
  content: "\F062";
}
.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063";
}
.mdi-assistant:before {
  content: "\F064";
}
.mdi-at:before {
  content: "\F065";
}
.mdi-attachment:before {
  content: "\F066";
}
.mdi-audiobook:before {
  content: "\F067";
}
.mdi-auto-fix:before {
  content: "\F068";
}
.mdi-auto-upload:before {
  content: "\F069";
}
.mdi-autorenew:before {
  content: "\F06A";
}
.mdi-av-timer:before {
  content: "\F06B";
}
.mdi-baby:before {
  content: "\F06C";
}
.mdi-backburger:before {
  content: "\F06D";
}
.mdi-backspace:before {
  content: "\F06E";
}
.mdi-backup-restore:before {
  content: "\F06F";
}
.mdi-bank:before {
  content: "\F070";
}
.mdi-barcode:before {
  content: "\F071";
}
.mdi-barcode-scan:before {
  content: "\F072";
}
.mdi-barley:before {
  content: "\F073";
}
.mdi-barrel:before {
  content: "\F074";
}
.mdi-basecamp:before {
  content: "\F075";
}
.mdi-basket:before {
  content: "\F076";
}
.mdi-basket-fill:before {
  content: "\F077";
}
.mdi-basket-unfill:before {
  content: "\F078";
}
.mdi-battery:before {
  content: "\F079";
}
.mdi-battery-10:before {
  content: "\F07A";
}
.mdi-battery-20:before {
  content: "\F07B";
}
.mdi-battery-30:before {
  content: "\F07C";
}
.mdi-battery-40:before {
  content: "\F07D";
}
.mdi-battery-50:before {
  content: "\F07E";
}
.mdi-battery-60:before {
  content: "\F07F";
}
.mdi-battery-70:before {
  content: "\F080";
}
.mdi-battery-80:before {
  content: "\F081";
}
.mdi-battery-90:before {
  content: "\F082";
}
.mdi-battery-alert:before {
  content: "\F083";
}
.mdi-battery-charging:before {
  content: "\F084";
}
.mdi-battery-charging-100:before {
  content: "\F085";
}
.mdi-battery-charging-20:before {
  content: "\F086";
}
.mdi-battery-charging-30:before {
  content: "\F087";
}
.mdi-battery-charging-40:before {
  content: "\F088";
}
.mdi-battery-charging-60:before {
  content: "\F089";
}
.mdi-battery-charging-80:before {
  content: "\F08A";
}
.mdi-battery-charging-90:before {
  content: "\F08B";
}
.mdi-battery-minus:before {
  content: "\F08C";
}
.mdi-battery-negative:before {
  content: "\F08D";
}
.mdi-battery-outline:before {
  content: "\F08E";
}
.mdi-battery-plus:before {
  content: "\F08F";
}
.mdi-battery-positive:before {
  content: "\F090";
}
.mdi-battery-unknown:before {
  content: "\F091";
}
.mdi-beach:before {
  content: "\F092";
}
.mdi-beats:before {
  content: "\F097";
}
.mdi-beer:before {
  content: "\F098";
}
.mdi-behance:before {
  content: "\F099";
}
.mdi-bell:before {
  content: "\F09A";
}
.mdi-bell-off:before {
  content: "\F09B";
}
.mdi-bell-outline:before {
  content: "\F09C";
}
.mdi-bell-plus:before {
  content: "\F09D";
}
.mdi-bell-ring:before {
  content: "\F09E";
}
.mdi-bell-ring-outline:before {
  content: "\F09F";
}
.mdi-bell-sleep:before {
  content: "\F0A0";
}
.mdi-beta:before {
  content: "\F0A1";
}
.mdi-bible:before {
  content: "\F0A2";
}
.mdi-bike:before {
  content: "\F0A3";
}
.mdi-bing:before {
  content: "\F0A4";
}
.mdi-binoculars:before {
  content: "\F0A5";
}
.mdi-bio:before {
  content: "\F0A6";
}
.mdi-biohazard:before {
  content: "\F0A7";
}
.mdi-bitbucket:before {
  content: "\F0A8";
}
.mdi-black-mesa:before {
  content: "\F0A9";
}
.mdi-blackberry:before {
  content: "\F0AA";
}
.mdi-blender:before {
  content: "\F0AB";
}
.mdi-blinds:before {
  content: "\F0AC";
}
.mdi-block-helper:before {
  content: "\F0AD";
}
.mdi-blogger:before {
  content: "\F0AE";
}
.mdi-bluetooth:before {
  content: "\F0AF";
}
.mdi-bluetooth-audio:before {
  content: "\F0B0";
}
.mdi-bluetooth-connect:before {
  content: "\F0B1";
}
.mdi-bluetooth-off:before {
  content: "\F0B2";
}
.mdi-bluetooth-settings:before {
  content: "\F0B3";
}
.mdi-bluetooth-transfer:before {
  content: "\F0B4";
}
.mdi-blur:before {
  content: "\F0B5";
}
.mdi-blur-linear:before {
  content: "\F0B6";
}
.mdi-blur-off:before {
  content: "\F0B7";
}
.mdi-blur-radial:before {
  content: "\F0B8";
}
.mdi-bone:before {
  content: "\F0B9";
}
.mdi-book:before {
  content: "\F0BA";
}
.mdi-book-minus:before {
  content: "\F5D9";
}
.mdi-book-multiple:before {
  content: "\F0BB";
}
.mdi-book-multiple-variant:before {
  content: "\F0BC";
}
.mdi-book-open:before {
  content: "\F0BD";
}
.mdi-book-open-page-variant:before {
  content: "\F5DA";
}
.mdi-book-open-variant:before {
  content: "\F0BE";
}
.mdi-book-plus:before {
  content: "\F5DB";
}
.mdi-book-variant:before {
  content: "\F0BF";
}
.mdi-bookmark:before {
  content: "\F0C0";
}
.mdi-bookmark-check:before {
  content: "\F0C1";
}
.mdi-bookmark-music:before {
  content: "\F0C2";
}
.mdi-bookmark-outline:before {
  content: "\F0C3";
}
.mdi-bookmark-plus:before {
  content: "\F0C5";
}
.mdi-bookmark-plus-outline:before {
  content: "\F0C4";
}
.mdi-bookmark-remove:before {
  content: "\F0C6";
}
.mdi-boombox:before {
  content: "\F5DC";
}
.mdi-border-all:before {
  content: "\F0C7";
}
.mdi-border-bottom:before {
  content: "\F0C8";
}
.mdi-border-color:before {
  content: "\F0C9";
}
.mdi-border-horizontal:before {
  content: "\F0CA";
}
.mdi-border-inside:before {
  content: "\F0CB";
}
.mdi-border-left:before {
  content: "\F0CC";
}
.mdi-border-none:before {
  content: "\F0CD";
}
.mdi-border-outside:before {
  content: "\F0CE";
}
.mdi-border-right:before {
  content: "\F0CF";
}
.mdi-border-style:before {
  content: "\F0D0";
}
.mdi-border-top:before {
  content: "\F0D1";
}
.mdi-border-vertical:before {
  content: "\F0D2";
}
.mdi-bowl:before {
  content: "\F617";
}
.mdi-bowling:before {
  content: "\F0D3";
}
.mdi-box:before {
  content: "\F0D4";
}
.mdi-box-cutter:before {
  content: "\F0D5";
}
.mdi-box-shadow:before {
  content: "\F637";
}
.mdi-bridge:before {
  content: "\F618";
}
.mdi-briefcase:before {
  content: "\F0D6";
}
.mdi-briefcase-check:before {
  content: "\F0D7";
}
.mdi-briefcase-download:before {
  content: "\F0D8";
}
.mdi-briefcase-upload:before {
  content: "\F0D9";
}
.mdi-brightness-1:before {
  content: "\F0DA";
}
.mdi-brightness-2:before {
  content: "\F0DB";
}
.mdi-brightness-3:before {
  content: "\F0DC";
}
.mdi-brightness-4:before {
  content: "\F0DD";
}
.mdi-brightness-5:before {
  content: "\F0DE";
}
.mdi-brightness-6:before {
  content: "\F0DF";
}
.mdi-brightness-7:before {
  content: "\F0E0";
}
.mdi-brightness-auto:before {
  content: "\F0E1";
}
.mdi-broom:before {
  content: "\F0E2";
}
.mdi-brush:before {
  content: "\F0E3";
}
.mdi-buffer:before {
  content: "\F619";
}
.mdi-bug:before {
  content: "\F0E4";
}
.mdi-bulletin-board:before {
  content: "\F0E5";
}
.mdi-bullhorn:before {
  content: "\F0E6";
}
.mdi-bullseye:before {
  content: "\F5DD";
}
.mdi-burst-mode:before {
  content: "\F5DE";
}
.mdi-bus:before {
  content: "\F0E7";
}
.mdi-cached:before {
  content: "\F0E8";
}
.mdi-cake:before {
  content: "\F0E9";
}
.mdi-cake-layered:before {
  content: "\F0EA";
}
.mdi-cake-variant:before {
  content: "\F0EB";
}
.mdi-calculator:before {
  content: "\F0EC";
}
.mdi-calendar:before {
  content: "\F0ED";
}
.mdi-calendar-blank:before {
  content: "\F0EE";
}
.mdi-calendar-check:before {
  content: "\F0EF";
}
.mdi-calendar-clock:before {
  content: "\F0F0";
}
.mdi-calendar-multiple:before {
  content: "\F0F1";
}
.mdi-calendar-multiple-check:before {
  content: "\F0F2";
}
.mdi-calendar-plus:before {
  content: "\F0F3";
}
.mdi-calendar-remove:before {
  content: "\F0F4";
}
.mdi-calendar-text:before {
  content: "\F0F5";
}
.mdi-calendar-today:before {
  content: "\F0F6";
}
.mdi-call-made:before {
  content: "\F0F7";
}
.mdi-call-merge:before {
  content: "\F0F8";
}
.mdi-call-missed:before {
  content: "\F0F9";
}
.mdi-call-received:before {
  content: "\F0FA";
}
.mdi-call-split:before {
  content: "\F0FB";
}
.mdi-camcorder:before {
  content: "\F0FC";
}
.mdi-camcorder-box:before {
  content: "\F0FD";
}
.mdi-camcorder-box-off:before {
  content: "\F0FE";
}
.mdi-camcorder-off:before {
  content: "\F0FF";
}
.mdi-camera:before {
  content: "\F100";
}
.mdi-camera-enhance:before {
  content: "\F101";
}
.mdi-camera-front:before {
  content: "\F102";
}
.mdi-camera-front-variant:before {
  content: "\F103";
}
.mdi-camera-iris:before {
  content: "\F104";
}
.mdi-camera-off:before {
  content: "\F5DF";
}
.mdi-camera-party-mode:before {
  content: "\F105";
}
.mdi-camera-rear:before {
  content: "\F106";
}
.mdi-camera-rear-variant:before {
  content: "\F107";
}
.mdi-camera-switch:before {
  content: "\F108";
}
.mdi-camera-timer:before {
  content: "\F109";
}
.mdi-candle:before {
  content: "\F5E2";
}
.mdi-candycane:before {
  content: "\F10A";
}
.mdi-car:before {
  content: "\F10B";
}
.mdi-car-battery:before {
  content: "\F10C";
}
.mdi-car-connected:before {
  content: "\F10D";
}
.mdi-car-wash:before {
  content: "\F10E";
}
.mdi-cards:before {
  content: "\F638";
}
.mdi-cards-outline:before {
  content: "\F639";
}
.mdi-cards-playing-outline:before {
  content: "\F63A";
}
.mdi-carrot:before {
  content: "\F10F";
}
.mdi-cart:before {
  content: "\F110";
}
.mdi-cart-off:before {
  content: "\F66B";
}
.mdi-cart-outline:before {
  content: "\F111";
}
.mdi-cart-plus:before {
  content: "\F112";
}
.mdi-case-sensitive-alt:before {
  content: "\F113";
}
.mdi-cash:before {
  content: "\F114";
}
.mdi-cash-100:before {
  content: "\F115";
}
.mdi-cash-multiple:before {
  content: "\F116";
}
.mdi-cash-usd:before {
  content: "\F117";
}
.mdi-cast:before {
  content: "\F118";
}
.mdi-cast-connected:before {
  content: "\F119";
}
.mdi-castle:before {
  content: "\F11A";
}
.mdi-cat:before {
  content: "\F11B";
}
.mdi-cellphone:before {
  content: "\F11C";
}
.mdi-cellphone-android:before {
  content: "\F11D";
}
.mdi-cellphone-basic:before {
  content: "\F11E";
}
.mdi-cellphone-dock:before {
  content: "\F11F";
}
.mdi-cellphone-iphone:before {
  content: "\F120";
}
.mdi-cellphone-link:before {
  content: "\F121";
}
.mdi-cellphone-link-off:before {
  content: "\F122";
}
.mdi-cellphone-settings:before {
  content: "\F123";
}
.mdi-certificate:before {
  content: "\F124";
}
.mdi-chair-school:before {
  content: "\F125";
}
.mdi-chart-arc:before {
  content: "\F126";
}
.mdi-chart-areaspline:before {
  content: "\F127";
}
.mdi-chart-bar:before {
  content: "\F128";
}
.mdi-chart-bubble:before {
  content: "\F5E3";
}
.mdi-chart-gantt:before {
  content: "\F66C";
}
.mdi-chart-histogram:before {
  content: "\F129";
}
.mdi-chart-line:before {
  content: "\F12A";
}
.mdi-chart-pie:before {
  content: "\F12B";
}
.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D";
}
.mdi-chart-timeline:before {
  content: "\F66E";
}
.mdi-check:before {
  content: "\F12C";
}
.mdi-check-all:before {
  content: "\F12D";
}
.mdi-check-circle:before {
  content: "\F5E0";
}
.mdi-check-circle-outline:before {
  content: "\F5E1";
}
.mdi-checkbox-blank:before {
  content: "\F12E";
}
.mdi-checkbox-blank-circle:before {
  content: "\F12F";
}
.mdi-checkbox-blank-circle-outline:before {
  content: "\F130";
}
.mdi-checkbox-blank-outline:before {
  content: "\F131";
}
.mdi-checkbox-marked:before {
  content: "\F132";
}
.mdi-checkbox-marked-circle:before {
  content: "\F133";
}
.mdi-checkbox-marked-circle-outline:before {
  content: "\F134";
}
.mdi-checkbox-marked-outline:before {
  content: "\F135";
}
.mdi-checkbox-multiple-blank:before {
  content: "\F136";
}
.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B";
}
.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C";
}
.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137";
}
.mdi-checkbox-multiple-marked:before {
  content: "\F138";
}
.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D";
}
.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E";
}
.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139";
}
.mdi-checkerboard:before {
  content: "\F13A";
}
.mdi-chemical-weapon:before {
  content: "\F13B";
}
.mdi-chevron-double-down:before {
  content: "\F13C";
}
.mdi-chevron-double-left:before {
  content: "\F13D";
}
.mdi-chevron-double-right:before {
  content: "\F13E";
}
.mdi-chevron-double-up:before {
  content: "\F13F";
}
.mdi-chevron-down:before {
  content: "\F140";
}
.mdi-chevron-left:before {
  content: "\F141";
}
.mdi-chevron-right:before {
  content: "\F142";
}
.mdi-chevron-up:before {
  content: "\F143";
}
.mdi-chip:before {
  content: "\F61A";
}
.mdi-church:before {
  content: "\F144";
}
.mdi-cisco-webex:before {
  content: "\F145";
}
.mdi-city:before {
  content: "\F146";
}
.mdi-clipboard:before {
  content: "\F147";
}
.mdi-clipboard-account:before {
  content: "\F148";
}
.mdi-clipboard-alert:before {
  content: "\F149";
}
.mdi-clipboard-arrow-down:before {
  content: "\F14A";
}
.mdi-clipboard-arrow-left:before {
  content: "\F14B";
}
.mdi-clipboard-check:before {
  content: "\F14C";
}
.mdi-clipboard-outline:before {
  content: "\F14D";
}
.mdi-clipboard-text:before {
  content: "\F14E";
}
.mdi-clippy:before {
  content: "\F14F";
}
.mdi-clock:before {
  content: "\F150";
}
.mdi-clock-alert:before {
  content: "\F5CE";
}
.mdi-clock-end:before {
  content: "\F151";
}
.mdi-clock-fast:before {
  content: "\F152";
}
.mdi-clock-in:before {
  content: "\F153";
}
.mdi-clock-out:before {
  content: "\F154";
}
.mdi-clock-start:before {
  content: "\F155";
}
.mdi-close:before {
  content: "\F156";
}
.mdi-close-box:before {
  content: "\F157";
}
.mdi-close-box-outline:before {
  content: "\F158";
}
.mdi-close-circle:before {
  content: "\F159";
}
.mdi-close-circle-outline:before {
  content: "\F15A";
}
.mdi-close-network:before {
  content: "\F15B";
}
.mdi-close-octagon:before {
  content: "\F15C";
}
.mdi-close-octagon-outline:before {
  content: "\F15D";
}
.mdi-closed-caption:before {
  content: "\F15E";
}
.mdi-cloud:before {
  content: "\F15F";
}
.mdi-cloud-check:before {
  content: "\F160";
}
.mdi-cloud-circle:before {
  content: "\F161";
}
.mdi-cloud-download:before {
  content: "\F162";
}
.mdi-cloud-outline:before {
  content: "\F163";
}
.mdi-cloud-outline-off:before {
  content: "\F164";
}
.mdi-cloud-print:before {
  content: "\F165";
}
.mdi-cloud-print-outline:before {
  content: "\F166";
}
.mdi-cloud-sync:before {
  content: "\F63F";
}
.mdi-cloud-upload:before {
  content: "\F167";
}
.mdi-code-array:before {
  content: "\F168";
}
.mdi-code-braces:before {
  content: "\F169";
}
.mdi-code-brackets:before {
  content: "\F16A";
}
.mdi-code-equal:before {
  content: "\F16B";
}
.mdi-code-greater-than:before {
  content: "\F16C";
}
.mdi-code-greater-than-or-equal:before {
  content: "\F16D";
}
.mdi-code-less-than:before {
  content: "\F16E";
}
.mdi-code-less-than-or-equal:before {
  content: "\F16F";
}
.mdi-code-not-equal:before {
  content: "\F170";
}
.mdi-code-not-equal-variant:before {
  content: "\F171";
}
.mdi-code-parentheses:before {
  content: "\F172";
}
.mdi-code-string:before {
  content: "\F173";
}
.mdi-code-tags:before {
  content: "\F174";
}
.mdi-codepen:before {
  content: "\F175";
}
.mdi-coffee:before {
  content: "\F176";
}
.mdi-coffee-to-go:before {
  content: "\F177";
}
.mdi-coin:before {
  content: "\F178";
}
.mdi-collage:before {
  content: "\F640";
}
.mdi-color-helper:before {
  content: "\F179";
}
.mdi-comment:before {
  content: "\F17A";
}
.mdi-comment-account:before {
  content: "\F17B";
}
.mdi-comment-account-outline:before {
  content: "\F17C";
}
.mdi-comment-alert:before {
  content: "\F17D";
}
.mdi-comment-alert-outline:before {
  content: "\F17E";
}
.mdi-comment-check:before {
  content: "\F17F";
}
.mdi-comment-check-outline:before {
  content: "\F180";
}
.mdi-comment-multiple-outline:before {
  content: "\F181";
}
.mdi-comment-outline:before {
  content: "\F182";
}
.mdi-comment-plus-outline:before {
  content: "\F183";
}
.mdi-comment-processing:before {
  content: "\F184";
}
.mdi-comment-processing-outline:before {
  content: "\F185";
}
.mdi-comment-question-outline:before {
  content: "\F186";
}
.mdi-comment-remove-outline:before {
  content: "\F187";
}
.mdi-comment-text:before {
  content: "\F188";
}
.mdi-comment-text-outline:before {
  content: "\F189";
}
.mdi-compare:before {
  content: "\F18A";
}
.mdi-compass:before {
  content: "\F18B";
}
.mdi-compass-outline:before {
  content: "\F18C";
}
.mdi-console:before {
  content: "\F18D";
}
.mdi-contact-mail:before {
  content: "\F18E";
}
.mdi-content-copy:before {
  content: "\F18F";
}
.mdi-content-cut:before {
  content: "\F190";
}
.mdi-content-duplicate:before {
  content: "\F191";
}
.mdi-content-paste:before {
  content: "\F192";
}
.mdi-content-save:before {
  content: "\F193";
}
.mdi-content-save-all:before {
  content: "\F194";
}
.mdi-content-save-settings:before {
  content: "\F61B";
}
.mdi-contrast:before {
  content: "\F195";
}
.mdi-contrast-box:before {
  content: "\F196";
}
.mdi-contrast-circle:before {
  content: "\F197";
}
.mdi-cookie:before {
  content: "\F198";
}
.mdi-copyright:before {
  content: "\F5E6";
}
.mdi-counter:before {
  content: "\F199";
}
.mdi-cow:before {
  content: "\F19A";
}
.mdi-credit-card:before {
  content: "\F19B";
}
.mdi-credit-card-multiple:before {
  content: "\F19C";
}
.mdi-credit-card-off:before {
  content: "\F5E4";
}
.mdi-credit-card-scan:before {
  content: "\F19D";
}
.mdi-crop:before {
  content: "\F19E";
}
.mdi-crop-free:before {
  content: "\F19F";
}
.mdi-crop-landscape:before {
  content: "\F1A0";
}
.mdi-crop-portrait:before {
  content: "\F1A1";
}
.mdi-crop-square:before {
  content: "\F1A2";
}
.mdi-crosshairs:before {
  content: "\F1A3";
}
.mdi-crosshairs-gps:before {
  content: "\F1A4";
}
.mdi-crown:before {
  content: "\F1A5";
}
.mdi-cube:before {
  content: "\F1A6";
}
.mdi-cube-outline:before {
  content: "\F1A7";
}
.mdi-cube-send:before {
  content: "\F1A8";
}
.mdi-cube-unfolded:before {
  content: "\F1A9";
}
.mdi-cup:before {
  content: "\F1AA";
}
.mdi-cup-off:before {
  content: "\F5E5";
}
.mdi-cup-water:before {
  content: "\F1AB";
}
.mdi-currency-btc:before {
  content: "\F1AC";
}
.mdi-currency-eur:before {
  content: "\F1AD";
}
.mdi-currency-gbp:before {
  content: "\F1AE";
}
.mdi-currency-inr:before {
  content: "\F1AF";
}
.mdi-currency-ngn:before {
  content: "\F1B0";
}
.mdi-currency-rub:before {
  content: "\F1B1";
}
.mdi-currency-try:before {
  content: "\F1B2";
}
.mdi-currency-usd:before {
  content: "\F1B3";
}
.mdi-cursor-default:before {
  content: "\F1B4";
}
.mdi-cursor-default-outline:before {
  content: "\F1B5";
}
.mdi-cursor-move:before {
  content: "\F1B6";
}
.mdi-cursor-pointer:before {
  content: "\F1B7";
}
.mdi-cursor-text:before {
  content: "\F5E7";
}
.mdi-database:before {
  content: "\F1B8";
}
.mdi-database-minus:before {
  content: "\F1B9";
}
.mdi-database-plus:before {
  content: "\F1BA";
}
.mdi-debug-step-into:before {
  content: "\F1BB";
}
.mdi-debug-step-out:before {
  content: "\F1BC";
}
.mdi-debug-step-over:before {
  content: "\F1BD";
}
.mdi-decimal-decrease:before {
  content: "\F1BE";
}
.mdi-decimal-increase:before {
  content: "\F1BF";
}
.mdi-delete:before {
  content: "\F1C0";
}
.mdi-delete-forever:before {
  content: "\F5E8";
}
.mdi-delete-sweep:before {
  content: "\F5E9";
}
.mdi-delete-variant:before {
  content: "\F1C1";
}
.mdi-delta:before {
  content: "\F1C2";
}
.mdi-deskphone:before {
  content: "\F1C3";
}
.mdi-desktop-mac:before {
  content: "\F1C4";
}
.mdi-desktop-tower:before {
  content: "\F1C5";
}
.mdi-details:before {
  content: "\F1C6";
}
.mdi-deviantart:before {
  content: "\F1C7";
}
.mdi-dialpad:before {
  content: "\F61C";
}
.mdi-diamond:before {
  content: "\F1C8";
}
.mdi-dice-1:before {
  content: "\F1CA";
}
.mdi-dice-2:before {
  content: "\F1CB";
}
.mdi-dice-3:before {
  content: "\F1CC";
}
.mdi-dice-4:before {
  content: "\F1CD";
}
.mdi-dice-5:before {
  content: "\F1CE";
}
.mdi-dice-6:before {
  content: "\F1CF";
}
.mdi-dice-d20:before {
  content: "\F5EA";
}
.mdi-dice-d4:before {
  content: "\F5EB";
}
.mdi-dice-d6:before {
  content: "\F5EC";
}
.mdi-dice-d8:before {
  content: "\F5ED";
}
.mdi-dictionary:before {
  content: "\F61D";
}
.mdi-directions:before {
  content: "\F1D0";
}
.mdi-directions-fork:before {
  content: "\F641";
}
.mdi-discord:before {
  content: "\F66F";
}
.mdi-disk:before {
  content: "\F5EE";
}
.mdi-disk-alert:before {
  content: "\F1D1";
}
.mdi-disqus:before {
  content: "\F1D2";
}
.mdi-disqus-outline:before {
  content: "\F1D3";
}
.mdi-division:before {
  content: "\F1D4";
}
.mdi-division-box:before {
  content: "\F1D5";
}
.mdi-dns:before {
  content: "\F1D6";
}
.mdi-domain:before {
  content: "\F1D7";
}
.mdi-dots-horizontal:before {
  content: "\F1D8";
}
.mdi-dots-vertical:before {
  content: "\F1D9";
}
.mdi-download:before {
  content: "\F1DA";
}
.mdi-drag:before {
  content: "\F1DB";
}
.mdi-drag-horizontal:before {
  content: "\F1DC";
}
.mdi-drag-vertical:before {
  content: "\F1DD";
}
.mdi-drawing:before {
  content: "\F1DE";
}
.mdi-drawing-box:before {
  content: "\F1DF";
}
.mdi-dribbble:before {
  content: "\F1E0";
}
.mdi-dribbble-box:before {
  content: "\F1E1";
}
.mdi-drone:before {
  content: "\F1E2";
}
.mdi-dropbox:before {
  content: "\F1E3";
}
.mdi-drupal:before {
  content: "\F1E4";
}
.mdi-duck:before {
  content: "\F1E5";
}
.mdi-dumbbell:before {
  content: "\F1E6";
}
.mdi-earth:before {
  content: "\F1E7";
}
.mdi-earth-off:before {
  content: "\F1E8";
}
.mdi-edge:before {
  content: "\F1E9";
}
.mdi-eject:before {
  content: "\F1EA";
}
.mdi-elevation-decline:before {
  content: "\F1EB";
}
.mdi-elevation-rise:before {
  content: "\F1EC";
}
.mdi-elevator:before {
  content: "\F1ED";
}
.mdi-email:before {
  content: "\F1EE";
}
.mdi-email-open:before {
  content: "\F1EF";
}
.mdi-email-open-outline:before {
  content: "\F5EF";
}
.mdi-email-outline:before {
  content: "\F1F0";
}
.mdi-email-secure:before {
  content: "\F1F1";
}
.mdi-email-variant:before {
  content: "\F5F0";
}
.mdi-emoticon:before {
  content: "\F1F2";
}
.mdi-emoticon-cool:before {
  content: "\F1F3";
}
.mdi-emoticon-devil:before {
  content: "\F1F4";
}
.mdi-emoticon-happy:before {
  content: "\F1F5";
}
.mdi-emoticon-neutral:before {
  content: "\F1F6";
}
.mdi-emoticon-poop:before {
  content: "\F1F7";
}
.mdi-emoticon-sad:before {
  content: "\F1F8";
}
.mdi-emoticon-tongue:before {
  content: "\F1F9";
}
.mdi-engine:before {
  content: "\F1FA";
}
.mdi-engine-outline:before {
  content: "\F1FB";
}
.mdi-equal:before {
  content: "\F1FC";
}
.mdi-equal-box:before {
  content: "\F1FD";
}
.mdi-eraser:before {
  content: "\F1FE";
}
.mdi-eraser-variant:before {
  content: "\F642";
}
.mdi-escalator:before {
  content: "\F1FF";
}
.mdi-ethernet:before {
  content: "\F200";
}
.mdi-ethernet-cable:before {
  content: "\F201";
}
.mdi-ethernet-cable-off:before {
  content: "\F202";
}
.mdi-etsy:before {
  content: "\F203";
}
.mdi-ev-station:before {
  content: "\F5F1";
}
.mdi-evernote:before {
  content: "\F204";
}
.mdi-exclamation:before {
  content: "\F205";
}
.mdi-exit-to-app:before {
  content: "\F206";
}
.mdi-export:before {
  content: "\F207";
}
.mdi-eye:before {
  content: "\F208";
}
.mdi-eye-off:before {
  content: "\F209";
}
.mdi-eyedropper:before {
  content: "\F20A";
}
.mdi-eyedropper-variant:before {
  content: "\F20B";
}
.mdi-face:before {
  content: "\F643";
}
.mdi-face-profile:before {
  content: "\F644";
}
.mdi-facebook:before {
  content: "\F20C";
}
.mdi-facebook-box:before {
  content: "\F20D";
}
.mdi-facebook-messenger:before {
  content: "\F20E";
}
.mdi-factory:before {
  content: "\F20F";
}
.mdi-fan:before {
  content: "\F210";
}
.mdi-fast-forward:before {
  content: "\F211";
}
.mdi-fax:before {
  content: "\F212";
}
.mdi-ferry:before {
  content: "\F213";
}
.mdi-file:before {
  content: "\F214";
}
.mdi-file-chart:before {
  content: "\F215";
}
.mdi-file-check:before {
  content: "\F216";
}
.mdi-file-cloud:before {
  content: "\F217";
}
.mdi-file-delimited:before {
  content: "\F218";
}
.mdi-file-document:before {
  content: "\F219";
}
.mdi-file-document-box:before {
  content: "\F21A";
}
.mdi-file-excel:before {
  content: "\F21B";
}
.mdi-file-excel-box:before {
  content: "\F21C";
}
.mdi-file-export:before {
  content: "\F21D";
}
.mdi-file-find:before {
  content: "\F21E";
}
.mdi-file-hidden:before {
  content: "\F613";
}
.mdi-file-image:before {
  content: "\F21F";
}
.mdi-file-import:before {
  content: "\F220";
}
.mdi-file-lock:before {
  content: "\F221";
}
.mdi-file-multiple:before {
  content: "\F222";
}
.mdi-file-music:before {
  content: "\F223";
}
.mdi-file-outline:before {
  content: "\F224";
}
.mdi-file-pdf:before {
  content: "\F225";
}
.mdi-file-pdf-box:before {
  content: "\F226";
}
.mdi-file-powerpoint:before {
  content: "\F227";
}
.mdi-file-powerpoint-box:before {
  content: "\F228";
}
.mdi-file-presentation-box:before {
  content: "\F229";
}
.mdi-file-restore:before {
  content: "\F670";
}
.mdi-file-send:before {
  content: "\F22A";
}
.mdi-file-tree:before {
  content: "\F645";
}
.mdi-file-video:before {
  content: "\F22B";
}
.mdi-file-word:before {
  content: "\F22C";
}
.mdi-file-word-box:before {
  content: "\F22D";
}
.mdi-file-xml:before {
  content: "\F22E";
}
.mdi-film:before {
  content: "\F22F";
}
.mdi-filmstrip:before {
  content: "\F230";
}
.mdi-filmstrip-off:before {
  content: "\F231";
}
.mdi-filter:before {
  content: "\F232";
}
.mdi-filter-outline:before {
  content: "\F233";
}
.mdi-filter-remove:before {
  content: "\F234";
}
.mdi-filter-remove-outline:before {
  content: "\F235";
}
.mdi-filter-variant:before {
  content: "\F236";
}
.mdi-fingerprint:before {
  content: "\F237";
}
.mdi-fire:before {
  content: "\F238";
}
.mdi-firefox:before {
  content: "\F239";
}
.mdi-fish:before {
  content: "\F23A";
}
.mdi-flag:before {
  content: "\F23B";
}
.mdi-flag-checkered:before {
  content: "\F23C";
}
.mdi-flag-outline:before {
  content: "\F23D";
}
.mdi-flag-outline-variant:before {
  content: "\F23E";
}
.mdi-flag-triangle:before {
  content: "\F23F";
}
.mdi-flag-variant:before {
  content: "\F240";
}
.mdi-flash:before {
  content: "\F241";
}
.mdi-flash-auto:before {
  content: "\F242";
}
.mdi-flash-off:before {
  content: "\F243";
}
.mdi-flashlight:before {
  content: "\F244";
}
.mdi-flashlight-off:before {
  content: "\F245";
}
.mdi-flask:before {
  content: "\F093";
}
.mdi-flask-empty:before {
  content: "\F094";
}
.mdi-flask-empty-outline:before {
  content: "\F095";
}
.mdi-flask-outline:before {
  content: "\F096";
}
.mdi-flattr:before {
  content: "\F246";
}
.mdi-flip-to-back:before {
  content: "\F247";
}
.mdi-flip-to-front:before {
  content: "\F248";
}
.mdi-floppy:before {
  content: "\F249";
}
.mdi-flower:before {
  content: "\F24A";
}
.mdi-folder:before {
  content: "\F24B";
}
.mdi-folder-account:before {
  content: "\F24C";
}
.mdi-folder-download:before {
  content: "\F24D";
}
.mdi-folder-google-drive:before {
  content: "\F24E";
}
.mdi-folder-image:before {
  content: "\F24F";
}
.mdi-folder-lock:before {
  content: "\F250";
}
.mdi-folder-lock-open:before {
  content: "\F251";
}
.mdi-folder-move:before {
  content: "\F252";
}
.mdi-folder-multiple:before {
  content: "\F253";
}
.mdi-folder-multiple-image:before {
  content: "\F254";
}
.mdi-folder-multiple-outline:before {
  content: "\F255";
}
.mdi-folder-outline:before {
  content: "\F256";
}
.mdi-folder-plus:before {
  content: "\F257";
}
.mdi-folder-remove:before {
  content: "\F258";
}
.mdi-folder-upload:before {
  content: "\F259";
}
.mdi-food:before {
  content: "\F25A";
}
.mdi-food-apple:before {
  content: "\F25B";
}
.mdi-food-fork-drink:before {
  content: "\F5F2";
}
.mdi-food-off:before {
  content: "\F5F3";
}
.mdi-food-variant:before {
  content: "\F25C";
}
.mdi-football:before {
  content: "\F25D";
}
.mdi-football-australian:before {
  content: "\F25E";
}
.mdi-football-helmet:before {
  content: "\F25F";
}
.mdi-format-align-center:before {
  content: "\F260";
}
.mdi-format-align-justify:before {
  content: "\F261";
}
.mdi-format-align-left:before {
  content: "\F262";
}
.mdi-format-align-right:before {
  content: "\F263";
}
.mdi-format-annotation-plus:before {
  content: "\F646";
}
.mdi-format-bold:before {
  content: "\F264";
}
.mdi-format-clear:before {
  content: "\F265";
}
.mdi-format-color-fill:before {
  content: "\F266";
}
.mdi-format-float-center:before {
  content: "\F267";
}
.mdi-format-float-left:before {
  content: "\F268";
}
.mdi-format-float-none:before {
  content: "\F269";
}
.mdi-format-float-right:before {
  content: "\F26A";
}
.mdi-format-header-1:before {
  content: "\F26B";
}
.mdi-format-header-2:before {
  content: "\F26C";
}
.mdi-format-header-3:before {
  content: "\F26D";
}
.mdi-format-header-4:before {
  content: "\F26E";
}
.mdi-format-header-5:before {
  content: "\F26F";
}
.mdi-format-header-6:before {
  content: "\F270";
}
.mdi-format-header-decrease:before {
  content: "\F271";
}
.mdi-format-header-equal:before {
  content: "\F272";
}
.mdi-format-header-increase:before {
  content: "\F273";
}
.mdi-format-header-pound:before {
  content: "\F274";
}
.mdi-format-horizontal-align-center:before {
  content: "\F61E";
}
.mdi-format-horizontal-align-left:before {
  content: "\F61F";
}
.mdi-format-horizontal-align-right:before {
  content: "\F620";
}
.mdi-format-indent-decrease:before {
  content: "\F275";
}
.mdi-format-indent-increase:before {
  content: "\F276";
}
.mdi-format-italic:before {
  content: "\F277";
}
.mdi-format-line-spacing:before {
  content: "\F278";
}
.mdi-format-line-style:before {
  content: "\F5C8";
}
.mdi-format-line-weight:before {
  content: "\F5C9";
}
.mdi-format-list-bulleted:before {
  content: "\F279";
}
.mdi-format-list-bulleted-type:before {
  content: "\F27A";
}
.mdi-format-list-numbers:before {
  content: "\F27B";
}
.mdi-format-paint:before {
  content: "\F27C";
}
.mdi-format-paragraph:before {
  content: "\F27D";
}
.mdi-format-quote:before {
  content: "\F27E";
}
.mdi-format-size:before {
  content: "\F27F";
}
.mdi-format-strikethrough:before {
  content: "\F280";
}
.mdi-format-strikethrough-variant:before {
  content: "\F281";
}
.mdi-format-subscript:before {
  content: "\F282";
}
.mdi-format-superscript:before {
  content: "\F283";
}
.mdi-format-text:before {
  content: "\F284";
}
.mdi-format-textdirection-l-to-r:before {
  content: "\F285";
}
.mdi-format-textdirection-r-to-l:before {
  content: "\F286";
}
.mdi-format-title:before {
  content: "\F5F4";
}
.mdi-format-underline:before {
  content: "\F287";
}
.mdi-format-vertical-align-bottom:before {
  content: "\F621";
}
.mdi-format-vertical-align-center:before {
  content: "\F622";
}
.mdi-format-vertical-align-top:before {
  content: "\F623";
}
.mdi-format-wrap-inline:before {
  content: "\F288";
}
.mdi-format-wrap-square:before {
  content: "\F289";
}
.mdi-format-wrap-tight:before {
  content: "\F28A";
}
.mdi-format-wrap-top-bottom:before {
  content: "\F28B";
}
.mdi-forum:before {
  content: "\F28C";
}
.mdi-forward:before {
  content: "\F28D";
}
.mdi-foursquare:before {
  content: "\F28E";
}
.mdi-fridge:before {
  content: "\F28F";
}
.mdi-fridge-filled:before {
  content: "\F290";
}
.mdi-fridge-filled-bottom:before {
  content: "\F291";
}
.mdi-fridge-filled-top:before {
  content: "\F292";
}
.mdi-fullscreen:before {
  content: "\F293";
}
.mdi-fullscreen-exit:before {
  content: "\F294";
}
.mdi-function:before {
  content: "\F295";
}
.mdi-gamepad:before {
  content: "\F296";
}
.mdi-gamepad-variant:before {
  content: "\F297";
}
.mdi-gas-cylinder:before {
  content: "\F647";
}
.mdi-gas-station:before {
  content: "\F298";
}
.mdi-gate:before {
  content: "\F299";
}
.mdi-gauge:before {
  content: "\F29A";
}
.mdi-gavel:before {
  content: "\F29B";
}
.mdi-gender-female:before {
  content: "\F29C";
}
.mdi-gender-male:before {
  content: "\F29D";
}
.mdi-gender-male-female:before {
  content: "\F29E";
}
.mdi-gender-transgender:before {
  content: "\F29F";
}
.mdi-ghost:before {
  content: "\F2A0";
}
.mdi-gift:before {
  content: "\F2A1";
}
.mdi-git:before {
  content: "\F2A2";
}
.mdi-github-box:before {
  content: "\F2A3";
}
.mdi-github-circle:before {
  content: "\F2A4";
}
.mdi-glass-flute:before {
  content: "\F2A5";
}
.mdi-glass-mug:before {
  content: "\F2A6";
}
.mdi-glass-stange:before {
  content: "\F2A7";
}
.mdi-glass-tulip:before {
  content: "\F2A8";
}
.mdi-glassdoor:before {
  content: "\F2A9";
}
.mdi-glasses:before {
  content: "\F2AA";
}
.mdi-gmail:before {
  content: "\F2AB";
}
.mdi-gnome:before {
  content: "\F2AC";
}
.mdi-google:before {
  content: "\F2AD";
}
.mdi-google-cardboard:before {
  content: "\F2AE";
}
.mdi-google-chrome:before {
  content: "\F2AF";
}
.mdi-google-circles:before {
  content: "\F2B0";
}
.mdi-google-circles-communities:before {
  content: "\F2B1";
}
.mdi-google-circles-extended:before {
  content: "\F2B2";
}
.mdi-google-circles-group:before {
  content: "\F2B3";
}
.mdi-google-controller:before {
  content: "\F2B4";
}
.mdi-google-controller-off:before {
  content: "\F2B5";
}
.mdi-google-drive:before {
  content: "\F2B6";
}
.mdi-google-earth:before {
  content: "\F2B7";
}
.mdi-google-glass:before {
  content: "\F2B8";
}
.mdi-google-maps:before {
  content: "\F5F5";
}
.mdi-google-nearby:before {
  content: "\F2B9";
}
.mdi-google-pages:before {
  content: "\F2BA";
}
.mdi-google-physical-web:before {
  content: "\F2BB";
}
.mdi-google-play:before {
  content: "\F2BC";
}
.mdi-google-plus:before {
  content: "\F2BD";
}
.mdi-google-plus-box:before {
  content: "\F2BE";
}
.mdi-google-translate:before {
  content: "\F2BF";
}
.mdi-google-wallet:before {
  content: "\F2C0";
}
.mdi-grease-pencil:before {
  content: "\F648";
}
.mdi-grid:before {
  content: "\F2C1";
}
.mdi-grid-off:before {
  content: "\F2C2";
}
.mdi-group:before {
  content: "\F2C3";
}
.mdi-guitar-electric:before {
  content: "\F2C4";
}
.mdi-guitar-pick:before {
  content: "\F2C5";
}
.mdi-guitar-pick-outline:before {
  content: "\F2C6";
}
.mdi-hackernews:before {
  content: "\F624";
}
.mdi-hand-pointing-right:before {
  content: "\F2C7";
}
.mdi-hanger:before {
  content: "\F2C8";
}
.mdi-hangouts:before {
  content: "\F2C9";
}
.mdi-harddisk:before {
  content: "\F2CA";
}
.mdi-headphones:before {
  content: "\F2CB";
}
.mdi-headphones-box:before {
  content: "\F2CC";
}
.mdi-headphones-settings:before {
  content: "\F2CD";
}
.mdi-headset:before {
  content: "\F2CE";
}
.mdi-headset-dock:before {
  content: "\F2CF";
}
.mdi-headset-off:before {
  content: "\F2D0";
}
.mdi-heart:before {
  content: "\F2D1";
}
.mdi-heart-box:before {
  content: "\F2D2";
}
.mdi-heart-box-outline:before {
  content: "\F2D3";
}
.mdi-heart-broken:before {
  content: "\F2D4";
}
.mdi-heart-outline:before {
  content: "\F2D5";
}
.mdi-heart-pulse:before {
  content: "\F5F6";
}
.mdi-help:before {
  content: "\F2D6";
}
.mdi-help-circle:before {
  content: "\F2D7";
}
.mdi-help-circle-outline:before {
  content: "\F625";
}
.mdi-hexagon:before {
  content: "\F2D8";
}
.mdi-hexagon-outline:before {
  content: "\F2D9";
}
.mdi-highway:before {
  content: "\F5F7";
}
.mdi-history:before {
  content: "\F2DA";
}
.mdi-hololens:before {
  content: "\F2DB";
}
.mdi-home:before {
  content: "\F2DC";
}
.mdi-home-map-marker:before {
  content: "\F5F8";
}
.mdi-home-modern:before {
  content: "\F2DD";
}
.mdi-home-variant:before {
  content: "\F2DE";
}
.mdi-hops:before {
  content: "\F2DF";
}
.mdi-hospital:before {
  content: "\F2E0";
}
.mdi-hospital-building:before {
  content: "\F2E1";
}
.mdi-hospital-marker:before {
  content: "\F2E2";
}
.mdi-hotel:before {
  content: "\F2E3";
}
.mdi-houzz:before {
  content: "\F2E4";
}
.mdi-houzz-box:before {
  content: "\F2E5";
}
.mdi-human:before {
  content: "\F2E6";
}
.mdi-human-child:before {
  content: "\F2E7";
}
.mdi-human-female:before {
  content: "\F649";
}
.mdi-human-greeting:before {
  content: "\F64A";
}
.mdi-human-handsdown:before {
  content: "\F64B";
}
.mdi-human-handsup:before {
  content: "\F64C";
}
.mdi-human-male:before {
  content: "\F64D";
}
.mdi-human-male-female:before {
  content: "\F2E8";
}
.mdi-human-pregnant:before {
  content: "\F5CF";
}
.mdi-image:before {
  content: "\F2E9";
}
.mdi-image-album:before {
  content: "\F2EA";
}
.mdi-image-area:before {
  content: "\F2EB";
}
.mdi-image-area-close:before {
  content: "\F2EC";
}
.mdi-image-broken:before {
  content: "\F2ED";
}
.mdi-image-broken-variant:before {
  content: "\F2EE";
}
.mdi-image-filter:before {
  content: "\F2EF";
}
.mdi-image-filter-black-white:before {
  content: "\F2F0";
}
.mdi-image-filter-center-focus:before {
  content: "\F2F1";
}
.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2";
}
.mdi-image-filter-drama:before {
  content: "\F2F3";
}
.mdi-image-filter-frames:before {
  content: "\F2F4";
}
.mdi-image-filter-hdr:before {
  content: "\F2F5";
}
.mdi-image-filter-none:before {
  content: "\F2F6";
}
.mdi-image-filter-tilt-shift:before {
  content: "\F2F7";
}
.mdi-image-filter-vintage:before {
  content: "\F2F8";
}
.mdi-image-multiple:before {
  content: "\F2F9";
}
.mdi-import:before {
  content: "\F2FA";
}
.mdi-inbox:before {
  content: "\F2FB";
}
.mdi-incognito:before {
  content: "\F5F9";
}
.mdi-information:before {
  content: "\F2FC";
}
.mdi-information-outline:before {
  content: "\F2FD";
}
.mdi-information-variant:before {
  content: "\F64E";
}
.mdi-instagram:before {
  content: "\F2FE";
}
.mdi-instapaper:before {
  content: "\F2FF";
}
.mdi-internet-explorer:before {
  content: "\F300";
}
.mdi-invert-colors:before {
  content: "\F301";
}
.mdi-jeepney:before {
  content: "\F302";
}
.mdi-jira:before {
  content: "\F303";
}
.mdi-jsfiddle:before {
  content: "\F304";
}
.mdi-json:before {
  content: "\F626";
}
.mdi-keg:before {
  content: "\F305";
}
.mdi-kettle:before {
  content: "\F5FA";
}
.mdi-key:before {
  content: "\F306";
}
.mdi-key-change:before {
  content: "\F307";
}
.mdi-key-minus:before {
  content: "\F308";
}
.mdi-key-plus:before {
  content: "\F309";
}
.mdi-key-remove:before {
  content: "\F30A";
}
.mdi-key-variant:before {
  content: "\F30B";
}
.mdi-keyboard:before {
  content: "\F30C";
}
.mdi-keyboard-backspace:before {
  content: "\F30D";
}
.mdi-keyboard-caps:before {
  content: "\F30E";
}
.mdi-keyboard-close:before {
  content: "\F30F";
}
.mdi-keyboard-off:before {
  content: "\F310";
}
.mdi-keyboard-return:before {
  content: "\F311";
}
.mdi-keyboard-tab:before {
  content: "\F312";
}
.mdi-keyboard-variant:before {
  content: "\F313";
}
.mdi-kodi:before {
  content: "\F314";
}
.mdi-label:before {
  content: "\F315";
}
.mdi-label-outline:before {
  content: "\F316";
}
.mdi-lambda:before {
  content: "\F627";
}
.mdi-lan:before {
  content: "\F317";
}
.mdi-lan-connect:before {
  content: "\F318";
}
.mdi-lan-disconnect:before {
  content: "\F319";
}
.mdi-lan-pending:before {
  content: "\F31A";
}
.mdi-language-c:before {
  content: "\F671";
}
.mdi-language-cpp:before {
  content: "\F672";
}
.mdi-language-csharp:before {
  content: "\F31B";
}
.mdi-language-css3:before {
  content: "\F31C";
}
.mdi-language-html5:before {
  content: "\F31D";
}
.mdi-language-javascript:before {
  content: "\F31E";
}
.mdi-language-php:before {
  content: "\F31F";
}
.mdi-language-python:before {
  content: "\F320";
}
.mdi-language-python-text:before {
  content: "\F321";
}
.mdi-laptop:before {
  content: "\F322";
}
.mdi-laptop-chromebook:before {
  content: "\F323";
}
.mdi-laptop-mac:before {
  content: "\F324";
}
.mdi-laptop-windows:before {
  content: "\F325";
}
.mdi-lastfm:before {
  content: "\F326";
}
.mdi-launch:before {
  content: "\F327";
}
.mdi-layers:before {
  content: "\F328";
}
.mdi-layers-off:before {
  content: "\F329";
}
.mdi-lead-pencil:before {
  content: "\F64F";
}
.mdi-leaf:before {
  content: "\F32A";
}
.mdi-led-off:before {
  content: "\F32B";
}
.mdi-led-on:before {
  content: "\F32C";
}
.mdi-led-outline:before {
  content: "\F32D";
}
.mdi-led-variant-off:before {
  content: "\F32E";
}
.mdi-led-variant-on:before {
  content: "\F32F";
}
.mdi-led-variant-outline:before {
  content: "\F330";
}
.mdi-library:before {
  content: "\F331";
}
.mdi-library-books:before {
  content: "\F332";
}
.mdi-library-music:before {
  content: "\F333";
}
.mdi-library-plus:before {
  content: "\F334";
}
.mdi-lightbulb:before {
  content: "\F335";
}
.mdi-lightbulb-outline:before {
  content: "\F336";
}
.mdi-link:before {
  content: "\F337";
}
.mdi-link-off:before {
  content: "\F338";
}
.mdi-link-variant:before {
  content: "\F339";
}
.mdi-link-variant-off:before {
  content: "\F33A";
}
.mdi-linkedin:before {
  content: "\F33B";
}
.mdi-linkedin-box:before {
  content: "\F33C";
}
.mdi-linux:before {
  content: "\F33D";
}
.mdi-lock:before {
  content: "\F33E";
}
.mdi-lock-open:before {
  content: "\F33F";
}
.mdi-lock-open-outline:before {
  content: "\F340";
}
.mdi-lock-outline:before {
  content: "\F341";
}
.mdi-lock-plus:before {
  content: "\F5FB";
}
.mdi-login:before {
  content: "\F342";
}
.mdi-login-variant:before {
  content: "\F5FC";
}
.mdi-logout:before {
  content: "\F343";
}
.mdi-logout-variant:before {
  content: "\F5FD";
}
.mdi-looks:before {
  content: "\F344";
}
.mdi-loupe:before {
  content: "\F345";
}
.mdi-lumx:before {
  content: "\F346";
}
.mdi-magnet:before {
  content: "\F347";
}
.mdi-magnet-on:before {
  content: "\F348";
}
.mdi-magnify:before {
  content: "\F349";
}
.mdi-magnify-minus:before {
  content: "\F34A";
}
.mdi-magnify-plus:before {
  content: "\F34B";
}
.mdi-mail-ru:before {
  content: "\F34C";
}
.mdi-map:before {
  content: "\F34D";
}
.mdi-map-marker:before {
  content: "\F34E";
}
.mdi-map-marker-circle:before {
  content: "\F34F";
}
.mdi-map-marker-minus:before {
  content: "\F650";
}
.mdi-map-marker-multiple:before {
  content: "\F350";
}
.mdi-map-marker-off:before {
  content: "\F351";
}
.mdi-map-marker-plus:before {
  content: "\F651";
}
.mdi-map-marker-radius:before {
  content: "\F352";
}
.mdi-margin:before {
  content: "\F353";
}
.mdi-markdown:before {
  content: "\F354";
}
.mdi-marker:before {
  content: "\F652";
}
.mdi-marker-check:before {
  content: "\F355";
}
.mdi-martini:before {
  content: "\F356";
}
.mdi-material-ui:before {
  content: "\F357";
}
.mdi-math-compass:before {
  content: "\F358";
}
.mdi-matrix:before {
  content: "\F628";
}
.mdi-maxcdn:before {
  content: "\F359";
}
.mdi-medium:before {
  content: "\F35A";
}
.mdi-memory:before {
  content: "\F35B";
}
.mdi-menu:before {
  content: "\F35C";
}
.mdi-menu-down:before {
  content: "\F35D";
}
.mdi-menu-left:before {
  content: "\F35E";
}
.mdi-menu-right:before {
  content: "\F35F";
}
.mdi-menu-up:before {
  content: "\F360";
}
.mdi-message:before {
  content: "\F361";
}
.mdi-message-alert:before {
  content: "\F362";
}
.mdi-message-draw:before {
  content: "\F363";
}
.mdi-message-image:before {
  content: "\F364";
}
.mdi-message-outline:before {
  content: "\F365";
}
.mdi-message-plus:before {
  content: "\F653";
}
.mdi-message-processing:before {
  content: "\F366";
}
.mdi-message-reply:before {
  content: "\F367";
}
.mdi-message-reply-text:before {
  content: "\F368";
}
.mdi-message-text:before {
  content: "\F369";
}
.mdi-message-text-outline:before {
  content: "\F36A";
}
.mdi-message-video:before {
  content: "\F36B";
}
.mdi-meteor:before {
  content: "\F629";
}
.mdi-microphone:before {
  content: "\F36C";
}
.mdi-microphone-off:before {
  content: "\F36D";
}
.mdi-microphone-outline:before {
  content: "\F36E";
}
.mdi-microphone-settings:before {
  content: "\F36F";
}
.mdi-microphone-variant:before {
  content: "\F370";
}
.mdi-microphone-variant-off:before {
  content: "\F371";
}
.mdi-microscope:before {
  content: "\F654";
}
.mdi-microsoft:before {
  content: "\F372";
}
.mdi-minecraft:before {
  content: "\F373";
}
.mdi-minus:before {
  content: "\F374";
}
.mdi-minus-box:before {
  content: "\F375";
}
.mdi-minus-circle:before {
  content: "\F376";
}
.mdi-minus-circle-outline:before {
  content: "\F377";
}
.mdi-minus-network:before {
  content: "\F378";
}
.mdi-mixcloud:before {
  content: "\F62A";
}
.mdi-monitor:before {
  content: "\F379";
}
.mdi-monitor-multiple:before {
  content: "\F37A";
}
.mdi-more:before {
  content: "\F37B";
}
.mdi-motorbike:before {
  content: "\F37C";
}
.mdi-mouse:before {
  content: "\F37D";
}
.mdi-mouse-off:before {
  content: "\F37E";
}
.mdi-mouse-variant:before {
  content: "\F37F";
}
.mdi-mouse-variant-off:before {
  content: "\F380";
}
.mdi-move-resize:before {
  content: "\F655";
}
.mdi-move-resize-variant:before {
  content: "\F656";
}
.mdi-movie:before {
  content: "\F381";
}
.mdi-multiplication:before {
  content: "\F382";
}
.mdi-multiplication-box:before {
  content: "\F383";
}
.mdi-music-box:before {
  content: "\F384";
}
.mdi-music-box-outline:before {
  content: "\F385";
}
.mdi-music-circle:before {
  content: "\F386";
}
.mdi-music-note:before {
  content: "\F387";
}
.mdi-music-note-bluetooth:before {
  content: "\F5FE";
}
.mdi-music-note-bluetooth-off:before {
  content: "\F5FF";
}
.mdi-music-note-eighth:before {
  content: "\F388";
}
.mdi-music-note-half:before {
  content: "\F389";
}
.mdi-music-note-off:before {
  content: "\F38A";
}
.mdi-music-note-quarter:before {
  content: "\F38B";
}
.mdi-music-note-sixteenth:before {
  content: "\F38C";
}
.mdi-music-note-whole:before {
  content: "\F38D";
}
.mdi-nature:before {
  content: "\F38E";
}
.mdi-nature-people:before {
  content: "\F38F";
}
.mdi-navigation:before {
  content: "\F390";
}
.mdi-near-me:before {
  content: "\F5CD";
}
.mdi-needle:before {
  content: "\F391";
}
.mdi-nest-protect:before {
  content: "\F392";
}
.mdi-nest-thermostat:before {
  content: "\F393";
}
.mdi-new-box:before {
  content: "\F394";
}
.mdi-newspaper:before {
  content: "\F395";
}
.mdi-nfc:before {
  content: "\F396";
}
.mdi-nfc-tap:before {
  content: "\F397";
}
.mdi-nfc-variant:before {
  content: "\F398";
}
.mdi-nodejs:before {
  content: "\F399";
}
.mdi-note:before {
  content: "\F39A";
}
.mdi-note-outline:before {
  content: "\F39B";
}
.mdi-note-plus:before {
  content: "\F39C";
}
.mdi-note-plus-outline:before {
  content: "\F39D";
}
.mdi-note-text:before {
  content: "\F39E";
}
.mdi-notification-clear-all:before {
  content: "\F39F";
}
.mdi-numeric:before {
  content: "\F3A0";
}
.mdi-numeric-0-box:before {
  content: "\F3A1";
}
.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2";
}
.mdi-numeric-0-box-outline:before {
  content: "\F3A3";
}
.mdi-numeric-1-box:before {
  content: "\F3A4";
}
.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5";
}
.mdi-numeric-1-box-outline:before {
  content: "\F3A6";
}
.mdi-numeric-2-box:before {
  content: "\F3A7";
}
.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8";
}
.mdi-numeric-2-box-outline:before {
  content: "\F3A9";
}
.mdi-numeric-3-box:before {
  content: "\F3AA";
}
.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB";
}
.mdi-numeric-3-box-outline:before {
  content: "\F3AC";
}
.mdi-numeric-4-box:before {
  content: "\F3AD";
}
.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE";
}
.mdi-numeric-4-box-outline:before {
  content: "\F3AF";
}
.mdi-numeric-5-box:before {
  content: "\F3B0";
}
.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1";
}
.mdi-numeric-5-box-outline:before {
  content: "\F3B2";
}
.mdi-numeric-6-box:before {
  content: "\F3B3";
}
.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4";
}
.mdi-numeric-6-box-outline:before {
  content: "\F3B5";
}
.mdi-numeric-7-box:before {
  content: "\F3B6";
}
.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7";
}
.mdi-numeric-7-box-outline:before {
  content: "\F3B8";
}
.mdi-numeric-8-box:before {
  content: "\F3B9";
}
.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA";
}
.mdi-numeric-8-box-outline:before {
  content: "\F3BB";
}
.mdi-numeric-9-box:before {
  content: "\F3BC";
}
.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD";
}
.mdi-numeric-9-box-outline:before {
  content: "\F3BE";
}
.mdi-numeric-9-plus-box:before {
  content: "\F3BF";
}
.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0";
}
.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1";
}
.mdi-nutrition:before {
  content: "\F3C2";
}
.mdi-octagon:before {
  content: "\F3C3";
}
.mdi-octagon-outline:before {
  content: "\F3C4";
}
.mdi-odnoklassniki:before {
  content: "\F3C5";
}
.mdi-office:before {
  content: "\F3C6";
}
.mdi-oil:before {
  content: "\F3C7";
}
.mdi-oil-temperature:before {
  content: "\F3C8";
}
.mdi-omega:before {
  content: "\F3C9";
}
.mdi-onedrive:before {
  content: "\F3CA";
}
.mdi-opacity:before {
  content: "\F5CC";
}
.mdi-open-in-app:before {
  content: "\F3CB";
}
.mdi-open-in-new:before {
  content: "\F3CC";
}
.mdi-openid:before {
  content: "\F3CD";
}
.mdi-opera:before {
  content: "\F3CE";
}
.mdi-ornament:before {
  content: "\F3CF";
}
.mdi-ornament-variant:before {
  content: "\F3D0";
}
.mdi-outbox:before {
  content: "\F3D1";
}
.mdi-owl:before {
  content: "\F3D2";
}
.mdi-package:before {
  content: "\F3D3";
}
.mdi-package-down:before {
  content: "\F3D4";
}
.mdi-package-up:before {
  content: "\F3D5";
}
.mdi-package-variant:before {
  content: "\F3D6";
}
.mdi-package-variant-closed:before {
  content: "\F3D7";
}
.mdi-page-first:before {
  content: "\F600";
}
.mdi-page-last:before {
  content: "\F601";
}
.mdi-palette:before {
  content: "\F3D8";
}
.mdi-palette-advanced:before {
  content: "\F3D9";
}
.mdi-panda:before {
  content: "\F3DA";
}
.mdi-pandora:before {
  content: "\F3DB";
}
.mdi-panorama:before {
  content: "\F3DC";
}
.mdi-panorama-fisheye:before {
  content: "\F3DD";
}
.mdi-panorama-horizontal:before {
  content: "\F3DE";
}
.mdi-panorama-vertical:before {
  content: "\F3DF";
}
.mdi-panorama-wide-angle:before {
  content: "\F3E0";
}
.mdi-paper-cut-vertical:before {
  content: "\F3E1";
}
.mdi-paperclip:before {
  content: "\F3E2";
}
.mdi-parking:before {
  content: "\F3E3";
}
.mdi-pause:before {
  content: "\F3E4";
}
.mdi-pause-circle:before {
  content: "\F3E5";
}
.mdi-pause-circle-outline:before {
  content: "\F3E6";
}
.mdi-pause-octagon:before {
  content: "\F3E7";
}
.mdi-pause-octagon-outline:before {
  content: "\F3E8";
}
.mdi-paw:before {
  content: "\F3E9";
}
.mdi-paw-off:before {
  content: "\F657";
}
.mdi-pen:before {
  content: "\F3EA";
}
.mdi-pencil:before {
  content: "\F3EB";
}
.mdi-pencil-box:before {
  content: "\F3EC";
}
.mdi-pencil-box-outline:before {
  content: "\F3ED";
}
.mdi-pencil-lock:before {
  content: "\F3EE";
}
.mdi-pencil-off:before {
  content: "\F3EF";
}
.mdi-percent:before {
  content: "\F3F0";
}
.mdi-pharmacy:before {
  content: "\F3F1";
}
.mdi-phone:before {
  content: "\F3F2";
}
.mdi-phone-bluetooth:before {
  content: "\F3F3";
}
.mdi-phone-classic:before {
  content: "\F602";
}
.mdi-phone-forward:before {
  content: "\F3F4";
}
.mdi-phone-hangup:before {
  content: "\F3F5";
}
.mdi-phone-in-talk:before {
  content: "\F3F6";
}
.mdi-phone-incoming:before {
  content: "\F3F7";
}
.mdi-phone-locked:before {
  content: "\F3F8";
}
.mdi-phone-log:before {
  content: "\F3F9";
}
.mdi-phone-minus:before {
  content: "\F658";
}
.mdi-phone-missed:before {
  content: "\F3FA";
}
.mdi-phone-outgoing:before {
  content: "\F3FB";
}
.mdi-phone-paused:before {
  content: "\F3FC";
}
.mdi-phone-plus:before {
  content: "\F659";
}
.mdi-phone-settings:before {
  content: "\F3FD";
}
.mdi-phone-voip:before {
  content: "\F3FE";
}
.mdi-pi:before {
  content: "\F3FF";
}
.mdi-pi-box:before {
  content: "\F400";
}
.mdi-pig:before {
  content: "\F401";
}
.mdi-pill:before {
  content: "\F402";
}
.mdi-pin:before {
  content: "\F403";
}
.mdi-pin-off:before {
  content: "\F404";
}
.mdi-pine-tree:before {
  content: "\F405";
}
.mdi-pine-tree-box:before {
  content: "\F406";
}
.mdi-pinterest:before {
  content: "\F407";
}
.mdi-pinterest-box:before {
  content: "\F408";
}
.mdi-pizza:before {
  content: "\F409";
}
.mdi-play:before {
  content: "\F40A";
}
.mdi-play-box-outline:before {
  content: "\F40B";
}
.mdi-play-circle:before {
  content: "\F40C";
}
.mdi-play-circle-outline:before {
  content: "\F40D";
}
.mdi-play-pause:before {
  content: "\F40E";
}
.mdi-play-protected-content:before {
  content: "\F40F";
}
.mdi-playlist-check:before {
  content: "\F5C7";
}
.mdi-playlist-minus:before {
  content: "\F410";
}
.mdi-playlist-play:before {
  content: "\F411";
}
.mdi-playlist-plus:before {
  content: "\F412";
}
.mdi-playlist-remove:before {
  content: "\F413";
}
.mdi-playstation:before {
  content: "\F414";
}
.mdi-plus:before {
  content: "\F415";
}
.mdi-plus-box:before {
  content: "\F416";
}
.mdi-plus-circle:before {
  content: "\F417";
}
.mdi-plus-circle-multiple-outline:before {
  content: "\F418";
}
.mdi-plus-circle-outline:before {
  content: "\F419";
}
.mdi-plus-network:before {
  content: "\F41A";
}
.mdi-plus-one:before {
  content: "\F41B";
}
.mdi-pocket:before {
  content: "\F41C";
}
.mdi-pokeball:before {
  content: "\F41D";
}
.mdi-polaroid:before {
  content: "\F41E";
}
.mdi-poll:before {
  content: "\F41F";
}
.mdi-poll-box:before {
  content: "\F420";
}
.mdi-polymer:before {
  content: "\F421";
}
.mdi-pool:before {
  content: "\F606";
}
.mdi-popcorn:before {
  content: "\F422";
}
.mdi-pot:before {
  content: "\F65A";
}
.mdi-pot-mix:before {
  content: "\F65B";
}
.mdi-pound:before {
  content: "\F423";
}
.mdi-pound-box:before {
  content: "\F424";
}
.mdi-power:before {
  content: "\F425";
}
.mdi-power-settings:before {
  content: "\F426";
}
.mdi-power-socket:before {
  content: "\F427";
}
.mdi-presentation:before {
  content: "\F428";
}
.mdi-presentation-play:before {
  content: "\F429";
}
.mdi-printer:before {
  content: "\F42A";
}
.mdi-printer-3d:before {
  content: "\F42B";
}
.mdi-printer-alert:before {
  content: "\F42C";
}
.mdi-priority-high:before {
  content: "\F603";
}
.mdi-priority-low:before {
  content: "\F604";
}
.mdi-professional-hexagon:before {
  content: "\F42D";
}
.mdi-projector:before {
  content: "\F42E";
}
.mdi-projector-screen:before {
  content: "\F42F";
}
.mdi-pulse:before {
  content: "\F430";
}
.mdi-puzzle:before {
  content: "\F431";
}
.mdi-qqchat:before {
  content: "\F605";
}
.mdi-qrcode:before {
  content: "\F432";
}
.mdi-qrcode-scan:before {
  content: "\F433";
}
.mdi-quadcopter:before {
  content: "\F434";
}
.mdi-quality-high:before {
  content: "\F435";
}
.mdi-quicktime:before {
  content: "\F436";
}
.mdi-radar:before {
  content: "\F437";
}
.mdi-radiator:before {
  content: "\F438";
}
.mdi-radio:before {
  content: "\F439";
}
.mdi-radio-handheld:before {
  content: "\F43A";
}
.mdi-radio-tower:before {
  content: "\F43B";
}
.mdi-radioactive:before {
  content: "\F43C";
}
.mdi-radiobox-blank:before {
  content: "\F43D";
}
.mdi-radiobox-marked:before {
  content: "\F43E";
}
.mdi-raspberrypi:before {
  content: "\F43F";
}
.mdi-ray-end:before {
  content: "\F440";
}
.mdi-ray-end-arrow:before {
  content: "\F441";
}
.mdi-ray-start:before {
  content: "\F442";
}
.mdi-ray-start-arrow:before {
  content: "\F443";
}
.mdi-ray-start-end:before {
  content: "\F444";
}
.mdi-ray-vertex:before {
  content: "\F445";
}
.mdi-rdio:before {
  content: "\F446";
}
.mdi-read:before {
  content: "\F447";
}
.mdi-readability:before {
  content: "\F448";
}
.mdi-receipt:before {
  content: "\F449";
}
.mdi-record:before {
  content: "\F44A";
}
.mdi-record-rec:before {
  content: "\F44B";
}
.mdi-recycle:before {
  content: "\F44C";
}
.mdi-reddit:before {
  content: "\F44D";
}
.mdi-redo:before {
  content: "\F44E";
}
.mdi-redo-variant:before {
  content: "\F44F";
}
.mdi-refresh:before {
  content: "\F450";
}
.mdi-regex:before {
  content: "\F451";
}
.mdi-relative-scale:before {
  content: "\F452";
}
.mdi-reload:before {
  content: "\F453";
}
.mdi-remote:before {
  content: "\F454";
}
.mdi-rename-box:before {
  content: "\F455";
}
.mdi-repeat:before {
  content: "\F456";
}
.mdi-repeat-off:before {
  content: "\F457";
}
.mdi-repeat-once:before {
  content: "\F458";
}
.mdi-replay:before {
  content: "\F459";
}
.mdi-reply:before {
  content: "\F45A";
}
.mdi-reply-all:before {
  content: "\F45B";
}
.mdi-reproduction:before {
  content: "\F45C";
}
.mdi-resize-bottom-right:before {
  content: "\F45D";
}
.mdi-responsive:before {
  content: "\F45E";
}
.mdi-rewind:before {
  content: "\F45F";
}
.mdi-ribbon:before {
  content: "\F460";
}
.mdi-road:before {
  content: "\F461";
}
.mdi-road-variant:before {
  content: "\F462";
}
.mdi-rocket:before {
  content: "\F463";
}
.mdi-rotate-3d:before {
  content: "\F464";
}
.mdi-rotate-left:before {
  content: "\F465";
}
.mdi-rotate-left-variant:before {
  content: "\F466";
}
.mdi-rotate-right:before {
  content: "\F467";
}
.mdi-rotate-right-variant:before {
  content: "\F468";
}
.mdi-rounded-corner:before {
  content: "\F607";
}
.mdi-router-wireless:before {
  content: "\F469";
}
.mdi-routes:before {
  content: "\F46A";
}
.mdi-rowing:before {
  content: "\F608";
}
.mdi-rss:before {
  content: "\F46B";
}
.mdi-rss-box:before {
  content: "\F46C";
}
.mdi-ruler:before {
  content: "\F46D";
}
.mdi-run:before {
  content: "\F46E";
}
.mdi-sale:before {
  content: "\F46F";
}
.mdi-satellite:before {
  content: "\F470";
}
.mdi-satellite-variant:before {
  content: "\F471";
}
.mdi-saxophone:before {
  content: "\F609";
}
.mdi-scale:before {
  content: "\F472";
}
.mdi-scale-balance:before {
  content: "\F5D1";
}
.mdi-scale-bathroom:before {
  content: "\F473";
}
.mdi-school:before {
  content: "\F474";
}
.mdi-screen-rotation:before {
  content: "\F475";
}
.mdi-screen-rotation-lock:before {
  content: "\F476";
}
.mdi-screwdriver:before {
  content: "\F477";
}
.mdi-script:before {
  content: "\F478";
}
.mdi-sd:before {
  content: "\F479";
}
.mdi-seal:before {
  content: "\F47A";
}
.mdi-seat-flat:before {
  content: "\F47B";
}
.mdi-seat-flat-angled:before {
  content: "\F47C";
}
.mdi-seat-individual-suite:before {
  content: "\F47D";
}
.mdi-seat-legroom-extra:before {
  content: "\F47E";
}
.mdi-seat-legroom-normal:before {
  content: "\F47F";
}
.mdi-seat-legroom-reduced:before {
  content: "\F480";
}
.mdi-seat-recline-extra:before {
  content: "\F481";
}
.mdi-seat-recline-normal:before {
  content: "\F482";
}
.mdi-security:before {
  content: "\F483";
}
.mdi-security-network:before {
  content: "\F484";
}
.mdi-select:before {
  content: "\F485";
}
.mdi-select-all:before {
  content: "\F486";
}
.mdi-select-inverse:before {
  content: "\F487";
}
.mdi-select-off:before {
  content: "\F488";
}
.mdi-selection:before {
  content: "\F489";
}
.mdi-send:before {
  content: "\F48A";
}
.mdi-serial-port:before {
  content: "\F65C";
}
.mdi-server:before {
  content: "\F48B";
}
.mdi-server-minus:before {
  content: "\F48C";
}
.mdi-server-network:before {
  content: "\F48D";
}
.mdi-server-network-off:before {
  content: "\F48E";
}
.mdi-server-off:before {
  content: "\F48F";
}
.mdi-server-plus:before {
  content: "\F490";
}
.mdi-server-remove:before {
  content: "\F491";
}
.mdi-server-security:before {
  content: "\F492";
}
.mdi-settings:before {
  content: "\F493";
}
.mdi-settings-box:before {
  content: "\F494";
}
.mdi-shape-circle-plus:before {
  content: "\F65D";
}
.mdi-shape-plus:before {
  content: "\F495";
}
.mdi-shape-polygon-plus:before {
  content: "\F65E";
}
.mdi-shape-rectangle-plus:before {
  content: "\F65F";
}
.mdi-shape-square-plus:before {
  content: "\F660";
}
.mdi-share:before {
  content: "\F496";
}
.mdi-share-variant:before {
  content: "\F497";
}
.mdi-shield:before {
  content: "\F498";
}
.mdi-shield-outline:before {
  content: "\F499";
}
.mdi-shopping:before {
  content: "\F49A";
}
.mdi-shopping-music:before {
  content: "\F49B";
}
.mdi-shredder:before {
  content: "\F49C";
}
.mdi-shuffle:before {
  content: "\F49D";
}
.mdi-shuffle-disabled:before {
  content: "\F49E";
}
.mdi-shuffle-variant:before {
  content: "\F49F";
}
.mdi-sigma:before {
  content: "\F4A0";
}
.mdi-sigma-lower:before {
  content: "\F62B";
}
.mdi-sign-caution:before {
  content: "\F4A1";
}
.mdi-signal:before {
  content: "\F4A2";
}
.mdi-signal-variant:before {
  content: "\F60A";
}
.mdi-silverware:before {
  content: "\F4A3";
}
.mdi-silverware-fork:before {
  content: "\F4A4";
}
.mdi-silverware-spoon:before {
  content: "\F4A5";
}
.mdi-silverware-variant:before {
  content: "\F4A6";
}
.mdi-sim:before {
  content: "\F4A7";
}
.mdi-sim-alert:before {
  content: "\F4A8";
}
.mdi-sim-off:before {
  content: "\F4A9";
}
.mdi-sitemap:before {
  content: "\F4AA";
}
.mdi-skip-backward:before {
  content: "\F4AB";
}
.mdi-skip-forward:before {
  content: "\F4AC";
}
.mdi-skip-next:before {
  content: "\F4AD";
}
.mdi-skip-next-circle:before {
  content: "\F661";
}
.mdi-skip-next-circle-outline:before {
  content: "\F662";
}
.mdi-skip-previous:before {
  content: "\F4AE";
}
.mdi-skip-previous-circle:before {
  content: "\F663";
}
.mdi-skip-previous-circle-outline:before {
  content: "\F664";
}
.mdi-skype:before {
  content: "\F4AF";
}
.mdi-skype-business:before {
  content: "\F4B0";
}
.mdi-slack:before {
  content: "\F4B1";
}
.mdi-sleep:before {
  content: "\F4B2";
}
.mdi-sleep-off:before {
  content: "\F4B3";
}
.mdi-smoking:before {
  content: "\F4B4";
}
.mdi-smoking-off:before {
  content: "\F4B5";
}
.mdi-snapchat:before {
  content: "\F4B6";
}
.mdi-snowman:before {
  content: "\F4B7";
}
.mdi-soccer:before {
  content: "\F4B8";
}
.mdi-sofa:before {
  content: "\F4B9";
}
.mdi-sort:before {
  content: "\F4BA";
}
.mdi-sort-alphabetical:before {
  content: "\F4BB";
}
.mdi-sort-ascending:before {
  content: "\F4BC";
}
.mdi-sort-descending:before {
  content: "\F4BD";
}
.mdi-sort-numeric:before {
  content: "\F4BE";
}
.mdi-sort-variant:before {
  content: "\F4BF";
}
.mdi-soundcloud:before {
  content: "\F4C0";
}
.mdi-source-branch:before {
  content: "\F62C";
}
.mdi-source-fork:before {
  content: "\F4C1";
}
.mdi-source-merge:before {
  content: "\F62D";
}
.mdi-source-pull:before {
  content: "\F4C2";
}
.mdi-speaker:before {
  content: "\F4C3";
}
.mdi-speaker-off:before {
  content: "\F4C4";
}
.mdi-speedometer:before {
  content: "\F4C5";
}
.mdi-spellcheck:before {
  content: "\F4C6";
}
.mdi-spotify:before {
  content: "\F4C7";
}
.mdi-spotlight:before {
  content: "\F4C8";
}
.mdi-spotlight-beam:before {
  content: "\F4C9";
}
.mdi-spray:before {
  content: "\F665";
}
.mdi-square-inc:before {
  content: "\F4CA";
}
.mdi-square-inc-cash:before {
  content: "\F4CB";
}
.mdi-stackexchange:before {
  content: "\F60B";
}
.mdi-stackoverflow:before {
  content: "\F4CC";
}
.mdi-stairs:before {
  content: "\F4CD";
}
.mdi-star:before {
  content: "\F4CE";
}
.mdi-star-circle:before {
  content: "\F4CF";
}
.mdi-star-half:before {
  content: "\F4D0";
}
.mdi-star-off:before {
  content: "\F4D1";
}
.mdi-star-outline:before {
  content: "\F4D2";
}
.mdi-steam:before {
  content: "\F4D3";
}
.mdi-steering:before {
  content: "\F4D4";
}
.mdi-step-backward:before {
  content: "\F4D5";
}
.mdi-step-backward-2:before {
  content: "\F4D6";
}
.mdi-step-forward:before {
  content: "\F4D7";
}
.mdi-step-forward-2:before {
  content: "\F4D8";
}
.mdi-stethoscope:before {
  content: "\F4D9";
}
.mdi-sticker:before {
  content: "\F5D0";
}
.mdi-stocking:before {
  content: "\F4DA";
}
.mdi-stop:before {
  content: "\F4DB";
}
.mdi-stop-circle:before {
  content: "\F666";
}
.mdi-stop-circle-outline:before {
  content: "\F667";
}
.mdi-store:before {
  content: "\F4DC";
}
.mdi-store-24-hour:before {
  content: "\F4DD";
}
.mdi-stove:before {
  content: "\F4DE";
}
.mdi-subdirectory-arrow-left:before {
  content: "\F60C";
}
.mdi-subdirectory-arrow-right:before {
  content: "\F60D";
}
.mdi-subway:before {
  content: "\F4DF";
}
.mdi-sunglasses:before {
  content: "\F4E0";
}
.mdi-surround-sound:before {
  content: "\F5C5";
}
.mdi-swap-horizontal:before {
  content: "\F4E1";
}
.mdi-swap-vertical:before {
  content: "\F4E2";
}
.mdi-swim:before {
  content: "\F4E3";
}
.mdi-switch:before {
  content: "\F4E4";
}
.mdi-sword:before {
  content: "\F4E5";
}
.mdi-sync:before {
  content: "\F4E6";
}
.mdi-sync-alert:before {
  content: "\F4E7";
}
.mdi-sync-off:before {
  content: "\F4E8";
}
.mdi-tab:before {
  content: "\F4E9";
}
.mdi-tab-unselected:before {
  content: "\F4EA";
}
.mdi-table:before {
  content: "\F4EB";
}
.mdi-table-column-plus-after:before {
  content: "\F4EC";
}
.mdi-table-column-plus-before:before {
  content: "\F4ED";
}
.mdi-table-column-remove:before {
  content: "\F4EE";
}
.mdi-table-column-width:before {
  content: "\F4EF";
}
.mdi-table-edit:before {
  content: "\F4F0";
}
.mdi-table-large:before {
  content: "\F4F1";
}
.mdi-table-row-height:before {
  content: "\F4F2";
}
.mdi-table-row-plus-after:before {
  content: "\F4F3";
}
.mdi-table-row-plus-before:before {
  content: "\F4F4";
}
.mdi-table-row-remove:before {
  content: "\F4F5";
}
.mdi-tablet:before {
  content: "\F4F6";
}
.mdi-tablet-android:before {
  content: "\F4F7";
}
.mdi-tablet-ipad:before {
  content: "\F4F8";
}
.mdi-tag:before {
  content: "\F4F9";
}
.mdi-tag-faces:before {
  content: "\F4FA";
}
.mdi-tag-multiple:before {
  content: "\F4FB";
}
.mdi-tag-outline:before {
  content: "\F4FC";
}
.mdi-tag-text-outline:before {
  content: "\F4FD";
}
.mdi-target:before {
  content: "\F4FE";
}
.mdi-taxi:before {
  content: "\F4FF";
}
.mdi-teamviewer:before {
  content: "\F500";
}
.mdi-telegram:before {
  content: "\F501";
}
.mdi-television:before {
  content: "\F502";
}
.mdi-television-guide:before {
  content: "\F503";
}
.mdi-temperature-celsius:before {
  content: "\F504";
}
.mdi-temperature-fahrenheit:before {
  content: "\F505";
}
.mdi-temperature-kelvin:before {
  content: "\F506";
}
.mdi-tennis:before {
  content: "\F507";
}
.mdi-tent:before {
  content: "\F508";
}
.mdi-terrain:before {
  content: "\F509";
}
.mdi-test-tube:before {
  content: "\F668";
}
.mdi-text-shadow:before {
  content: "\F669";
}
.mdi-text-to-speech:before {
  content: "\F50A";
}
.mdi-text-to-speech-off:before {
  content: "\F50B";
}
.mdi-textbox:before {
  content: "\F60E";
}
.mdi-texture:before {
  content: "\F50C";
}
.mdi-theater:before {
  content: "\F50D";
}
.mdi-theme-light-dark:before {
  content: "\F50E";
}
.mdi-thermometer:before {
  content: "\F50F";
}
.mdi-thermometer-lines:before {
  content: "\F510";
}
.mdi-thumb-down:before {
  content: "\F511";
}
.mdi-thumb-down-outline:before {
  content: "\F512";
}
.mdi-thumb-up:before {
  content: "\F513";
}
.mdi-thumb-up-outline:before {
  content: "\F514";
}
.mdi-thumbs-up-down:before {
  content: "\F515";
}
.mdi-ticket:before {
  content: "\F516";
}
.mdi-ticket-account:before {
  content: "\F517";
}
.mdi-ticket-confirmation:before {
  content: "\F518";
}
.mdi-tie:before {
  content: "\F519";
}
.mdi-timelapse:before {
  content: "\F51A";
}
.mdi-timer:before {
  content: "\F51B";
}
.mdi-timer-10:before {
  content: "\F51C";
}
.mdi-timer-3:before {
  content: "\F51D";
}
.mdi-timer-off:before {
  content: "\F51E";
}
.mdi-timer-sand:before {
  content: "\F51F";
}
.mdi-timetable:before {
  content: "\F520";
}
.mdi-toggle-switch:before {
  content: "\F521";
}
.mdi-toggle-switch-off:before {
  content: "\F522";
}
.mdi-tooltip:before {
  content: "\F523";
}
.mdi-tooltip-edit:before {
  content: "\F524";
}
.mdi-tooltip-image:before {
  content: "\F525";
}
.mdi-tooltip-outline:before {
  content: "\F526";
}
.mdi-tooltip-outline-plus:before {
  content: "\F527";
}
.mdi-tooltip-text:before {
  content: "\F528";
}
.mdi-tooth:before {
  content: "\F529";
}
.mdi-tor:before {
  content: "\F52A";
}
.mdi-traffic-light:before {
  content: "\F52B";
}
.mdi-train:before {
  content: "\F52C";
}
.mdi-tram:before {
  content: "\F52D";
}
.mdi-transcribe:before {
  content: "\F52E";
}
.mdi-transcribe-close:before {
  content: "\F52F";
}
.mdi-transfer:before {
  content: "\F530";
}
.mdi-translate:before {
  content: "\F5CA";
}
.mdi-tree:before {
  content: "\F531";
}
.mdi-trello:before {
  content: "\F532";
}
.mdi-trending-down:before {
  content: "\F533";
}
.mdi-trending-neutral:before {
  content: "\F534";
}
.mdi-trending-up:before {
  content: "\F535";
}
.mdi-triangle:before {
  content: "\F536";
}
.mdi-triangle-outline:before {
  content: "\F537";
}
.mdi-trophy:before {
  content: "\F538";
}
.mdi-trophy-award:before {
  content: "\F539";
}
.mdi-trophy-outline:before {
  content: "\F53A";
}
.mdi-trophy-variant:before {
  content: "\F53B";
}
.mdi-trophy-variant-outline:before {
  content: "\F53C";
}
.mdi-truck:before {
  content: "\F53D";
}
.mdi-truck-delivery:before {
  content: "\F53E";
}
.mdi-tshirt-crew:before {
  content: "\F53F";
}
.mdi-tshirt-v:before {
  content: "\F540";
}
.mdi-tumblr:before {
  content: "\F541";
}
.mdi-tumblr-reblog:before {
  content: "\F542";
}
.mdi-tune:before {
  content: "\F62E";
}
.mdi-tune-vertical:before {
  content: "\F66A";
}
.mdi-twitch:before {
  content: "\F543";
}
.mdi-twitter:before {
  content: "\F544";
}
.mdi-twitter-box:before {
  content: "\F545";
}
.mdi-twitter-circle:before {
  content: "\F546";
}
.mdi-twitter-retweet:before {
  content: "\F547";
}
.mdi-ubuntu:before {
  content: "\F548";
}
.mdi-umbraco:before {
  content: "\F549";
}
.mdi-umbrella:before {
  content: "\F54A";
}
.mdi-umbrella-outline:before {
  content: "\F54B";
}
.mdi-undo:before {
  content: "\F54C";
}
.mdi-undo-variant:before {
  content: "\F54D";
}
.mdi-unfold-less:before {
  content: "\F54E";
}
.mdi-unfold-more:before {
  content: "\F54F";
}
.mdi-ungroup:before {
  content: "\F550";
}
.mdi-untappd:before {
  content: "\F551";
}
.mdi-upload:before {
  content: "\F552";
}
.mdi-usb:before {
  content: "\F553";
}
.mdi-vector-arrange-above:before {
  content: "\F554";
}
.mdi-vector-arrange-below:before {
  content: "\F555";
}
.mdi-vector-circle:before {
  content: "\F556";
}
.mdi-vector-circle-variant:before {
  content: "\F557";
}
.mdi-vector-combine:before {
  content: "\F558";
}
.mdi-vector-curve:before {
  content: "\F559";
}
.mdi-vector-difference:before {
  content: "\F55A";
}
.mdi-vector-difference-ab:before {
  content: "\F55B";
}
.mdi-vector-difference-ba:before {
  content: "\F55C";
}
.mdi-vector-intersection:before {
  content: "\F55D";
}
.mdi-vector-line:before {
  content: "\F55E";
}
.mdi-vector-point:before {
  content: "\F55F";
}
.mdi-vector-polygon:before {
  content: "\F560";
}
.mdi-vector-polyline:before {
  content: "\F561";
}
.mdi-vector-rectangle:before {
  content: "\F5C6";
}
.mdi-vector-selection:before {
  content: "\F562";
}
.mdi-vector-square:before {
  content: "\F001";
}
.mdi-vector-triangle:before {
  content: "\F563";
}
.mdi-vector-union:before {
  content: "\F564";
}
.mdi-verified:before {
  content: "\F565";
}
.mdi-vibrate:before {
  content: "\F566";
}
.mdi-video:before {
  content: "\F567";
}
.mdi-video-off:before {
  content: "\F568";
}
.mdi-video-switch:before {
  content: "\F569";
}
.mdi-view-agenda:before {
  content: "\F56A";
}
.mdi-view-array:before {
  content: "\F56B";
}
.mdi-view-carousel:before {
  content: "\F56C";
}
.mdi-view-column:before {
  content: "\F56D";
}
.mdi-view-dashboard:before {
  content: "\F56E";
}
.mdi-view-day:before {
  content: "\F56F";
}
.mdi-view-grid:before {
  content: "\F570";
}
.mdi-view-headline:before {
  content: "\F571";
}
.mdi-view-list:before {
  content: "\F572";
}
.mdi-view-module:before {
  content: "\F573";
}
.mdi-view-quilt:before {
  content: "\F574";
}
.mdi-view-stream:before {
  content: "\F575";
}
.mdi-view-week:before {
  content: "\F576";
}
.mdi-vimeo:before {
  content: "\F577";
}
.mdi-vine:before {
  content: "\F578";
}
.mdi-violin:before {
  content: "\F60F";
}
.mdi-visualstudio:before {
  content: "\F610";
}
.mdi-vk:before {
  content: "\F579";
}
.mdi-vk-box:before {
  content: "\F57A";
}
.mdi-vk-circle:before {
  content: "\F57B";
}
.mdi-vlc:before {
  content: "\F57C";
}
.mdi-voice:before {
  content: "\F5CB";
}
.mdi-voicemail:before {
  content: "\F57D";
}
.mdi-volume-high:before {
  content: "\F57E";
}
.mdi-volume-low:before {
  content: "\F57F";
}
.mdi-volume-medium:before {
  content: "\F580";
}
.mdi-volume-off:before {
  content: "\F581";
}
.mdi-vpn:before {
  content: "\F582";
}
.mdi-walk:before {
  content: "\F583";
}
.mdi-wallet:before {
  content: "\F584";
}
.mdi-wallet-giftcard:before {
  content: "\F585";
}
.mdi-wallet-membership:before {
  content: "\F586";
}
.mdi-wallet-travel:before {
  content: "\F587";
}
.mdi-wan:before {
  content: "\F588";
}
.mdi-watch:before {
  content: "\F589";
}
.mdi-watch-export:before {
  content: "\F58A";
}
.mdi-watch-import:before {
  content: "\F58B";
}
.mdi-water:before {
  content: "\F58C";
}
.mdi-water-off:before {
  content: "\F58D";
}
.mdi-water-percent:before {
  content: "\F58E";
}
.mdi-water-pump:before {
  content: "\F58F";
}
.mdi-watermark:before {
  content: "\F612";
}
.mdi-weather-cloudy:before {
  content: "\F590";
}
.mdi-weather-fog:before {
  content: "\F591";
}
.mdi-weather-hail:before {
  content: "\F592";
}
.mdi-weather-lightning:before {
  content: "\F593";
}
.mdi-weather-night:before {
  content: "\F594";
}
.mdi-weather-partlycloudy:before {
  content: "\F595";
}
.mdi-weather-pouring:before {
  content: "\F596";
}
.mdi-weather-rainy:before {
  content: "\F597";
}
.mdi-weather-snowy:before {
  content: "\F598";
}
.mdi-weather-sunny:before {
  content: "\F599";
}
.mdi-weather-sunset:before {
  content: "\F59A";
}
.mdi-weather-sunset-down:before {
  content: "\F59B";
}
.mdi-weather-sunset-up:before {
  content: "\F59C";
}
.mdi-weather-windy:before {
  content: "\F59D";
}
.mdi-weather-windy-variant:before {
  content: "\F59E";
}
.mdi-web:before {
  content: "\F59F";
}
.mdi-webcam:before {
  content: "\F5A0";
}
.mdi-webhook:before {
  content: "\F62F";
}
.mdi-wechat:before {
  content: "\F611";
}
.mdi-weight:before {
  content: "\F5A1";
}
.mdi-weight-kilogram:before {
  content: "\F5A2";
}
.mdi-whatsapp:before {
  content: "\F5A3";
}
.mdi-wheelchair-accessibility:before {
  content: "\F5A4";
}
.mdi-white-balance-auto:before {
  content: "\F5A5";
}
.mdi-white-balance-incandescent:before {
  content: "\F5A6";
}
.mdi-white-balance-iridescent:before {
  content: "\F5A7";
}
.mdi-white-balance-sunny:before {
  content: "\F5A8";
}
.mdi-wifi:before {
  content: "\F5A9";
}
.mdi-wifi-off:before {
  content: "\F5AA";
}
.mdi-wii:before {
  content: "\F5AB";
}
.mdi-wikipedia:before {
  content: "\F5AC";
}
.mdi-window-close:before {
  content: "\F5AD";
}
.mdi-window-closed:before {
  content: "\F5AE";
}
.mdi-window-maximize:before {
  content: "\F5AF";
}
.mdi-window-minimize:before {
  content: "\F5B0";
}
.mdi-window-open:before {
  content: "\F5B1";
}
.mdi-window-restore:before {
  content: "\F5B2";
}
.mdi-windows:before {
  content: "\F5B3";
}
.mdi-wordpress:before {
  content: "\F5B4";
}
.mdi-worker:before {
  content: "\F5B5";
}
.mdi-wrap:before {
  content: "\F5B6";
}
.mdi-wrench:before {
  content: "\F5B7";
}
.mdi-wunderlist:before {
  content: "\F5B8";
}
.mdi-xaml:before {
  content: "\F673";
}
.mdi-xbox:before {
  content: "\F5B9";
}
.mdi-xbox-controller:before {
  content: "\F5BA";
}
.mdi-xbox-controller-off:before {
  content: "\F5BB";
}
.mdi-xda:before {
  content: "\F5BC";
}
.mdi-xing:before {
  content: "\F5BD";
}
.mdi-xing-box:before {
  content: "\F5BE";
}
.mdi-xing-circle:before {
  content: "\F5BF";
}
.mdi-xml:before {
  content: "\F5C0";
}
.mdi-yeast:before {
  content: "\F5C1";
}
.mdi-yelp:before {
  content: "\F5C2";
}
.mdi-youtube-play:before {
  content: "\F5C3";
}
.mdi-zip-box:before {
  content: "\F5C4";
}
.mdi-18px {
  font-size: 18px;
}
.mdi-24px {
  font-size: 24px;
}
.mdi-36px {
  font-size: 36px;
}
.mdi-48px {
  font-size: 48px;
}
.mdi-dark {
  color: rgba(0, 0, 0, 0.54);
}
.mdi-dark.mdi-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.mdi-light {
  color: white;
}
.mdi-light.mdi-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.mdi-rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mdi-rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mdi-rotate-135 {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.mdi-rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.mdi-rotate-225 {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}
.mdi-rotate-270 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.mdi-rotate-315 {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}
.mdi-flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.mdi-flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}
/*# sourceMappingURL=materialdesignicons.css.map */
@font-face {
  font-family: "feather";
  src: url("./fonts/feather-webfont.eot");
  src: url("./fonts/feather-webfont.eot") format("embedded-opentype"), url("./fonts/feather-webfont.woff") format("woff"), url("./fonts/feather-webfont.ttf") format("truetype"), url("./fonts/feather-webfont.svg#feather") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Character Mapping Method */
[data-fi]:before {
  display: inline-block;
  font-family: "feather";
  content: attr(data-icon);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* CSS Class Mapping Method */
[class^="fi-"],
[class*=" fi-"] {
  display: inline-block;
  font-family: "feather";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fi-eye:before {
  content: "\e000";
}
.fi-paper-clip:before {
  content: "\e001";
}
.fi-mail:before {
  content: "\e002";
}
.fi-mail:before {
  content: "\e002";
}
.fi-toggle:before {
  content: "\e003";
}
.fi-layout:before {
  content: "\e004";
}
.fi-link:before {
  content: "\e005";
}
.fi-bell:before {
  content: "\e006";
}
.fi-lock:before {
  content: "\e007";
}
.fi-unlock:before {
  content: "\e008";
}
.fi-ribbon:before {
  content: "\e009";
}
.fi-image:before {
  content: "\e010";
}
.fi-signal:before {
  content: "\e011";
}
.fi-target:before {
  content: "\e012";
}
.fi-clipboard:before {
  content: "\e013";
}
.fi-clock:before {
  content: "\e014";
}
.fi-clock:before {
  content: "\e014";
}
.fi-watch:before {
  content: "\e015";
}
.fi-air-play:before {
  content: "\e016";
}
.fi-camera:before {
  content: "\e017";
}
.fi-video:before {
  content: "\e018";
}
.fi-disc:before {
  content: "\e019";
}
.fi-printer:before {
  content: "\e020";
}
.fi-monitor:before {
  content: "\e021";
}
.fi-server:before {
  content: "\e022";
}
.fi-cog:before {
  content: "\e023";
}
.fi-heart:before {
  content: "\e024";
}
.fi-paragraph:before {
  content: "\e025";
}
.fi-align-justify:before {
  content: "\e026";
}
.fi-align-left:before {
  content: "\e027";
}
.fi-align-center:before {
  content: "\e028";
}
.fi-align-right:before {
  content: "\e029";
}
.fi-book:before {
  content: "\e030";
}
.fi-layers:before {
  content: "\e031";
}
.fi-stack:before {
  content: "\e032";
}
.fi-stack-2:before {
  content: "\e033";
}
.fi-paper:before {
  content: "\e034";
}
.fi-paper-stack:before {
  content: "\e035";
}
.fi-search:before {
  content: "\e036";
}
.fi-zoom-in:before {
  content: "\e037";
}
.fi-zoom-out:before {
  content: "\e038";
}
.fi-reply:before {
  content: "\e039";
}
.fi-circle-plus:before {
  content: "\e040";
}
.fi-circle-minus:before {
  content: "\e041";
}
.fi-circle-check:before {
  content: "\e042";
}
.fi-circle-cross:before {
  content: "\e043";
}
.fi-square-plus:before {
  content: "\e044";
}
.fi-square-minus:before {
  content: "\e045";
}
.fi-square-check:before {
  content: "\e046";
}
.fi-square-cross:before {
  content: "\e047";
}
.fi-microphone:before {
  content: "\e048";
}
.fi-record:before {
  content: "\e049";
}
.fi-skip-back:before {
  content: "\e050";
}
.fi-rewind:before {
  content: "\e051";
}
.fi-play:before {
  content: "\e052";
}
.fi-pause:before {
  content: "\e053";
}
.fi-stop:before {
  content: "\e054";
}
.fi-fast-forward:before {
  content: "\e055";
}
.fi-skip-forward:before {
  content: "\e056";
}
.fi-shuffle:before {
  content: "\e057";
}
.fi-repeat:before {
  content: "\e058";
}
.fi-folder:before {
  content: "\e059";
}
.fi-umbrella:before {
  content: "\e060";
}
.fi-moon:before {
  content: "\e061";
}
.fi-thermometer:before {
  content: "\e062";
}
.fi-drop:before {
  content: "\e063";
}
.fi-sun:before {
  content: "\e064";
}
.fi-cloud:before {
  content: "\e065";
}
.fi-cloud-upload:before {
  content: "\e066";
}
.fi-cloud-download:before {
  content: "\e067";
}
.fi-upload:before {
  content: "\e068";
}
.fi-download:before {
  content: "\e069";
}
.fi-location:before {
  content: "\e070";
}
.fi-location-2:before {
  content: "\e071";
}
.fi-map:before {
  content: "\e072";
}
.fi-battery:before {
  content: "\e073";
}
.fi-head:before {
  content: "\e074";
}
.fi-briefcase:before {
  content: "\e075";
}
.fi-speech-bubble:before {
  content: "\e076";
}
.fi-anchor:before {
  content: "\e077";
}
.fi-globe:before {
  content: "\e078";
}
.fi-box:before {
  content: "\e079";
}
.fi-reload:before {
  content: "\e080";
}
.fi-share:before {
  content: "\e081";
}
.fi-marquee:before {
  content: "\e082";
}
.fi-marquee-plus:before {
  content: "\e083";
}
.fi-marquee-minus:before {
  content: "\e084";
}
.fi-tag:before {
  content: "\e085";
}
.fi-power:before {
  content: "\e086";
}
.fi-command:before {
  content: "\e087";
}
.fi-alt:before {
  content: "\e088";
}
.fi-esc:before {
  content: "\e089";
}
.fi-bar-graph:before {
  content: "\e090";
}
.fi-bar-graph-2:before {
  content: "\e091";
}
.fi-pie-graph:before {
  content: "\e092";
}
.fi-star:before {
  content: "\e093";
}
.fi-arrow-left:before {
  content: "\e094";
}
.fi-arrow-right:before {
  content: "\e095";
}
.fi-arrow-up:before {
  content: "\e096";
}
.fi-arrow-down:before {
  content: "\e097";
}
.fi-volume:before {
  content: "\e098";
}
.fi-mute:before {
  content: "\e099";
}
.fi-content-right:before {
  content: "\e100";
}
.fi-content-left:before {
  content: "\e101";
}
.fi-grid:before {
  content: "\e102";
}
.fi-grid-2:before {
  content: "\e103";
}
.fi-columns:before {
  content: "\e104";
}
.fi-loader:before {
  content: "\e105";
}
.fi-bag:before {
  content: "\e106";
}
.fi-ban:before {
  content: "\e107";
}
.fi-flag:before {
  content: "\e108";
}
.fi-trash:before {
  content: "\e109";
}
.fi-expand:before {
  content: "\e110";
}
.fi-contract:before {
  content: "\e111";
}
.fi-maximize:before {
  content: "\e112";
}
.fi-minimize:before {
  content: "\e113";
}
.fi-plus:before {
  content: "\e114";
}
.fi-minus:before {
  content: "\e115";
}
.fi-check:before {
  content: "\e116";
}
.fi-cross:before {
  content: "\e117";
}
.fi-move:before {
  content: "\e118";
}
.fi-delete:before {
  content: "\e119";
}
.fi-menu:before {
  content: "\e120";
}
.fi-archive:before {
  content: "\e121";
}
.fi-inbox:before {
  content: "\e122";
}
.fi-outbox:before {
  content: "\e123";
}
.fi-file:before {
  content: "\e124";
}
.fi-file-add:before {
  content: "\e125";
}
.fi-file-subtract:before {
  content: "\e126";
}
.fi-help:before {
  content: "\e127";
}
.fi-open:before {
  content: "\e128";
}
.fi-ellipsis:before {
  content: "\e129";
}
@font-face {
  font-family: "dripicons-v2";
  src: url("./fonts/dripicons-v2.eot");
  src: url("./fonts/dripicons-v2.eot") format("embedded-opentype"), url("./fonts/dripicons-v2.woff") format("woff"), url("./fonts/dripicons-v2.ttf") format("truetype"), url("./fonts/dripicons-v2.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dripicons-alarm:before {
  content: "\61";
}
.dripicons-align-center:before {
  content: "\62";
}
.dripicons-align-justify:before {
  content: "\63";
}
.dripicons-align-left:before {
  content: "\64";
}
.dripicons-align-right:before {
  content: "\65";
}
.dripicons-anchor:before {
  content: "\66";
}
.dripicons-archive:before {
  content: "\67";
}
.dripicons-arrow-down:before {
  content: "\68";
}
.dripicons-arrow-left:before {
  content: "\69";
}
.dripicons-arrow-right:before {
  content: "\6a";
}
.dripicons-arrow-thin-down:before {
  content: "\6b";
}
.dripicons-arrow-thin-left:before {
  content: "\6c";
}
.dripicons-arrow-thin-right:before {
  content: "\6d";
}
.dripicons-arrow-thin-up:before {
  content: "\6e";
}
.dripicons-arrow-up:before {
  content: "\6f";
}
.dripicons-article:before {
  content: "\70";
}
.dripicons-backspace:before {
  content: "\71";
}
.dripicons-basket:before {
  content: "\72";
}
.dripicons-basketball:before {
  content: "\73";
}
.dripicons-battery-empty:before {
  content: "\74";
}
.dripicons-battery-full:before {
  content: "\75";
}
.dripicons-battery-low:before {
  content: "\76";
}
.dripicons-battery-medium:before {
  content: "\77";
}
.dripicons-bell:before {
  content: "\78";
}
.dripicons-blog:before {
  content: "\79";
}
.dripicons-bluetooth:before {
  content: "\7a";
}
.dripicons-bold:before {
  content: "\41";
}
.dripicons-bookmark:before {
  content: "\42";
}
.dripicons-bookmarks:before {
  content: "\43";
}
.dripicons-box:before {
  content: "\44";
}
.dripicons-briefcase:before {
  content: "\45";
}
.dripicons-brightness-low:before {
  content: "\46";
}
.dripicons-brightness-max:before {
  content: "\47";
}
.dripicons-brightness-medium:before {
  content: "\48";
}
.dripicons-broadcast:before {
  content: "\49";
}
.dripicons-browser:before {
  content: "\4a";
}
.dripicons-browser-upload:before {
  content: "\4b";
}
.dripicons-brush:before {
  content: "\4c";
}
.dripicons-calendar:before {
  content: "\4d";
}
.dripicons-camcorder:before {
  content: "\4e";
}
.dripicons-camera:before {
  content: "\4f";
}
.dripicons-card:before {
  content: "\50";
}
.dripicons-cart:before {
  content: "\51";
}
.dripicons-checklist:before {
  content: "\52";
}
.dripicons-checkmark:before {
  content: "\53";
}
.dripicons-chevron-down:before {
  content: "\54";
}
.dripicons-chevron-left:before {
  content: "\55";
}
.dripicons-chevron-right:before {
  content: "\56";
}
.dripicons-chevron-up:before {
  content: "\57";
}
.dripicons-clipboard:before {
  content: "\58";
}
.dripicons-clock:before {
  content: "\59";
}
.dripicons-clockwise:before {
  content: "\5a";
}
.dripicons-cloud:before {
  content: "\30";
}
.dripicons-cloud-download:before {
  content: "\31";
}
.dripicons-cloud-upload:before {
  content: "\32";
}
.dripicons-code:before {
  content: "\33";
}
.dripicons-contract:before {
  content: "\34";
}
.dripicons-contract-2:before {
  content: "\35";
}
.dripicons-conversation:before {
  content: "\36";
}
.dripicons-copy:before {
  content: "\37";
}
.dripicons-crop:before {
  content: "\38";
}
.dripicons-cross:before {
  content: "\39";
}
.dripicons-crosshair:before {
  content: "\21";
}
.dripicons-cutlery:before {
  content: "\22";
}
.dripicons-device-desktop:before {
  content: "\23";
}
.dripicons-device-mobile:before {
  content: "\24";
}
.dripicons-device-tablet:before {
  content: "\25";
}
.dripicons-direction:before {
  content: "\26";
}
.dripicons-disc:before {
  content: "\27";
}
.dripicons-document:before {
  content: "\28";
}
.dripicons-document-delete:before {
  content: "\29";
}
.dripicons-document-edit:before {
  content: "\2a";
}
.dripicons-document-new:before {
  content: "\2b";
}
.dripicons-document-remove:before {
  content: "\2c";
}
.dripicons-dot:before {
  content: "\2d";
}
.dripicons-dots-2:before {
  content: "\2e";
}
.dripicons-dots-3:before {
  content: "\2f";
}
.dripicons-download:before {
  content: "\3a";
}
.dripicons-duplicate:before {
  content: "\3b";
}
.dripicons-enter:before {
  content: "\3c";
}
.dripicons-exit:before {
  content: "\3d";
}
.dripicons-expand:before {
  content: "\3e";
}
.dripicons-expand-2:before {
  content: "\3f";
}
.dripicons-experiment:before {
  content: "\40";
}
.dripicons-export:before {
  content: "\5b";
}
.dripicons-feed:before {
  content: "\5d";
}
.dripicons-flag:before {
  content: "\5e";
}
.dripicons-flashlight:before {
  content: "\5f";
}
.dripicons-folder:before {
  content: "\60";
}
.dripicons-folder-open:before {
  content: "\7b";
}
.dripicons-forward:before {
  content: "\7c";
}
.dripicons-gaming:before {
  content: "\7d";
}
.dripicons-gear:before {
  content: "\7e";
}
.dripicons-graduation:before {
  content: "\5c";
}
.dripicons-graph-bar:before {
  content: "\e000";
}
.dripicons-graph-line:before {
  content: "\e001";
}
.dripicons-graph-pie:before {
  content: "\e002";
}
.dripicons-headset:before {
  content: "\e003";
}
.dripicons-heart:before {
  content: "\e004";
}
.dripicons-help:before {
  content: "\e005";
}
.dripicons-home:before {
  content: "\e006";
}
.dripicons-hourglass:before {
  content: "\e007";
}
.dripicons-inbox:before {
  content: "\e008";
}
.dripicons-information:before {
  content: "\e009";
}
.dripicons-italic:before {
  content: "\e00a";
}
.dripicons-jewel:before {
  content: "\e00b";
}
.dripicons-lifting:before {
  content: "\e00c";
}
.dripicons-lightbulb:before {
  content: "\e00d";
}
.dripicons-link:before {
  content: "\e00e";
}
.dripicons-link-broken:before {
  content: "\e00f";
}
.dripicons-list:before {
  content: "\e010";
}
.dripicons-loading:before {
  content: "\e011";
}
.dripicons-location:before {
  content: "\e012";
}
.dripicons-lock:before {
  content: "\e013";
}
.dripicons-lock-open:before {
  content: "\e014";
}
.dripicons-mail:before {
  content: "\e015";
}
.dripicons-map:before {
  content: "\e016";
}
.dripicons-media-loop:before {
  content: "\e017";
}
.dripicons-media-next:before {
  content: "\e018";
}
.dripicons-media-pause:before {
  content: "\e019";
}
.dripicons-media-play:before {
  content: "\e01a";
}
.dripicons-media-previous:before {
  content: "\e01b";
}
.dripicons-media-record:before {
  content: "\e01c";
}
.dripicons-media-shuffle:before {
  content: "\e01d";
}
.dripicons-media-stop:before {
  content: "\e01e";
}
.dripicons-medical:before {
  content: "\e01f";
}
.dripicons-menu:before {
  content: "\e020";
}
.dripicons-message:before {
  content: "\e021";
}
.dripicons-meter:before {
  content: "\e022";
}
.dripicons-microphone:before {
  content: "\e023";
}
.dripicons-minus:before {
  content: "\e024";
}
.dripicons-monitor:before {
  content: "\e025";
}
.dripicons-move:before {
  content: "\e026";
}
.dripicons-music:before {
  content: "\e027";
}
.dripicons-network-1:before {
  content: "\e028";
}
.dripicons-network-2:before {
  content: "\e029";
}
.dripicons-network-3:before {
  content: "\e02a";
}
.dripicons-network-4:before {
  content: "\e02b";
}
.dripicons-network-5:before {
  content: "\e02c";
}
.dripicons-pamphlet:before {
  content: "\e02d";
}
.dripicons-paperclip:before {
  content: "\e02e";
}
.dripicons-pencil:before {
  content: "\e02f";
}
.dripicons-phone:before {
  content: "\e030";
}
.dripicons-photo:before {
  content: "\e031";
}
.dripicons-photo-group:before {
  content: "\e032";
}
.dripicons-pill:before {
  content: "\e033";
}
.dripicons-pin:before {
  content: "\e034";
}
.dripicons-plus:before {
  content: "\e035";
}
.dripicons-power:before {
  content: "\e036";
}
.dripicons-preview:before {
  content: "\e037";
}
.dripicons-print:before {
  content: "\e038";
}
.dripicons-pulse:before {
  content: "\e039";
}
.dripicons-question:before {
  content: "\e03a";
}
.dripicons-reply:before {
  content: "\e03b";
}
.dripicons-reply-all:before {
  content: "\e03c";
}
.dripicons-return:before {
  content: "\e03d";
}
.dripicons-retweet:before {
  content: "\e03e";
}
.dripicons-rocket:before {
  content: "\e03f";
}
.dripicons-scale:before {
  content: "\e040";
}
.dripicons-search:before {
  content: "\e041";
}
.dripicons-shopping-bag:before {
  content: "\e042";
}
.dripicons-skip:before {
  content: "\e043";
}
.dripicons-stack:before {
  content: "\e044";
}
.dripicons-star:before {
  content: "\e045";
}
.dripicons-stopwatch:before {
  content: "\e046";
}
.dripicons-store:before {
  content: "\e047";
}
.dripicons-suitcase:before {
  content: "\e048";
}
.dripicons-swap:before {
  content: "\e049";
}
.dripicons-tag:before {
  content: "\e04a";
}
.dripicons-tag-delete:before {
  content: "\e04b";
}
.dripicons-tags:before {
  content: "\e04c";
}
.dripicons-thumbs-down:before {
  content: "\e04d";
}
.dripicons-thumbs-up:before {
  content: "\e04e";
}
.dripicons-ticket:before {
  content: "\e04f";
}
.dripicons-time-reverse:before {
  content: "\e050";
}
.dripicons-to-do:before {
  content: "\e051";
}
.dripicons-toggles:before {
  content: "\e052";
}
.dripicons-trash:before {
  content: "\e053";
}
.dripicons-trophy:before {
  content: "\e054";
}
.dripicons-upload:before {
  content: "\e055";
}
.dripicons-user:before {
  content: "\e056";
}
.dripicons-user-group:before {
  content: "\e057";
}
.dripicons-user-id:before {
  content: "\e058";
}
.dripicons-vibrate:before {
  content: "\e059";
}
.dripicons-view-apps:before {
  content: "\e05a";
}
.dripicons-view-list:before {
  content: "\e05b";
}
.dripicons-view-list-large:before {
  content: "\e05c";
}
.dripicons-view-thumb:before {
  content: "\e05d";
}
.dripicons-volume-full:before {
  content: "\e05e";
}
.dripicons-volume-low:before {
  content: "\e05f";
}
.dripicons-volume-medium:before {
  content: "\e060";
}
.dripicons-volume-off:before {
  content: "\e061";
}
.dripicons-wallet:before {
  content: "\e062";
}
.dripicons-warning:before {
  content: "\e063";
}
.dripicons-web:before {
  content: "\e064";
}
.dripicons-weight:before {
  content: "\e065";
}
.dripicons-wifi:before {
  content: "\e066";
}
.dripicons-wrong:before {
  content: "\e067";
}
.dripicons-zoom-in:before {
  content: "\e068";
}
.dripicons-zoom-out:before {
  content: "\e069";
}
