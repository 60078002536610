@font-face {
    font-family: 'politiq';
    src: url('./fonts/politiq.woff');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
    display: inline-block;
    font-family: 'politiq';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.icon-comment:before{content:'\0041';}
.icon-contextmenu:before{content:'\0042';}
.icon-dislike:before{content:'\0043';}
.icon-fav:before{content:'\0044';}
.icon-ico_female:before{content:'\0045';}
.icon-unwatch:before{content:'\0046';}
.icon-ico_male:before{content:'\0047';}
.icon-like:before{content:'\0048';}
.icon-pic:before{content:'\0049';}
.icon-pic-dark:before{content:'\0049'; color: #43c5e6}
.icon-tag:before{content:'\004a';}
.icon-tag-dark:before{content:'\004a'; color: #43c5e6}
.icon-unwatch_closely:before{content:'\004b';}
.icon-arrow:before{content:'\004c';}
.icon-watch:before{content:'\004d';}
.icon-watch_closely:before{content:'\004e';}
