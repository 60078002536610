/* =============
  == Components List==

   - Popover / Tooltips
   - Buttons
   - Panels
   - Checkbox and radio
   - Modals
   - Tabs
   - Progressbars
   - Notifications
   - Alerts
   - Carousel
   - Sweet Alert
   - Widgets
   - Nestable
   - Rating
   - Calendar
   - Form
   - Tables
   - Charts

============= */
/* =================
   Popover / Tooltips
==================== */
/* Popover */
.popover {
    font-family: inherit;
    border: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
}

.popover .popover-title {
    background-color: transparent;
    color: #43c5e6;
    padding: 12px 15px;
    font-size: 15px;
}

.popover .arrow {
    border-color: transparent !important;
}

/* Tooltips */
.tooltip {
    font-family: 'vugo', sans-serif;
}

.tooltip .tooltip-inner {
    padding: 4px 10px;
    border-radius: 2px;
    background-color: #969696;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #969696;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #969696;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #969696;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #969696;
}

/* Tooltpster */
.tooltipster-sidetip .tooltipster-box {
    background-color: #43c5e6;
    border: 2px solid #43c5e6;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
    border-top-color: #43c5e6;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
    border-bottom-color: #43c5e6;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
    border-left-color: #43c5e6;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
    border-right-color: #43c5e6;
}

/*POLITIQ ICONS*/
.pol {
    color: #000000;
    font-size: 28px;
}

/*END OF POLITIQ ICONS*/
/* =============
   Buttons
============= */
.btn {
    border-radius: 6px;
    font-size: 16px;
    padding: 12px 24px;
    outline: none !important;
    font-family: 'vugo', sans-serif;
}

.btn-mini {
    /*background:@white !important;
      border:solid 1px @dark !important;*/
    border: solid 1px #969696 !important;
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 8px;
    outline: none !important;
    font-family: 'vugo', sans-serif;
}
.btn-mini-dark {
    /*background:@white !important;
      border:solid 1px @dark !important;*/
    /*border: solid 1px #969696 !important;*/
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 8px;
    outline: none !important;
    font-family: 'vugo', sans-serif;
}

.btn-md {
    padding: 9px 18px;
}

.btn-group-lg > .btn,
.btn-lg {
    padding: 12px 20px !important;
    font-size: 18px;
}

.btn-group-sm > .btn,
.btn-sm {
    padding: 5px 10px !important;
    font-size: 14px;
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px !important;
    font-size: 12px;
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.btn-custom,
.btn-primary,
.btn-primary-dark,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink {
    color: #ffffff !important;
}

.btn-custom {
    background-color: #43c5e6;
    border-color: #43c5e6;
}

.btn-mini:hover i,
.btn-mini:focus i,
.btn-mini:active i {
    color: #43c5e6;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
    background-color: #2cbee3 !important;
    border: 1px solid #2cbee3 !important;
}

.btn-default {
    background-color: #ffffff;
    border-color: rgba(150, 150, 150, 0.2);
}

.btn-default-dark {
    background-color: #1d1f20;
    border: 0;
    /*border-color: rgba(150, 150, 150, 0.2);*/
}

.btn-toolbar:hover,
.btn-toolbar:focus,
.btn-toolbar:active,
.btn-toolbar.active,
.btn-toolbar.focus,
.btn-toolbar:active,
.btn-toolbar:focus,
.btn-toolbar:hover,
.open > .dropdown-toggle.btn-toolbar {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
    background-color: rgba(150, 150, 150, 0.07) !important;
    border-color: rgba(150, 150, 150, 0.2) !important;
}

.btn-toolbar {
    padding: 0px 15px !important;
    background: transparent !important;
    min-width: 30px;
}

.btn-mini, .btn-mini-dark:hover,
.btn-mini, .btn-mini-dark:focus,
.btn-mini, .btn-mini-dark:active,
.btn-mini, .btn-mini-dark.active,
.btn-mini, .btn-mini-dark.focus,
.btn-mini, .btn-mini-dark:active,
.btn-mini, .btn-mini-dark:focus,
.btn-mini, .btn-mini-dark:hover,
.open > .dropdown-toggle.btn-mini {
    color: #43c5e6;
    border: solid 1px #43c5e6 !important;
    background: #ffffff !important;
    outline: none;
    box-shadow: none;
}

.btn-primary {
    background-color: #000000 !important;
    border: 0px solid #0a2737 !important;
}

.btn-primary-dark {
    background-color: #46bcf2 !important;
    border: 2px solid #1266f1 !important;
}

.btn-upload {
    background-color: #43c5e6 !important;
    border: 0px solid #0a2737 !important;
    color: #ffffff;
}

.btn-upload:focus,
.btn-upload:active,
.btn-upload.active,
.btn-upload.hover {
    background-color: #1aa0c3 !important;
    outline: none !important;
    box-shadow: none !important;
    border: 0px solid #0a2737 !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
    background-color: #43c5e6;
    outline: none !important;
    box-shadow: none !important;
    border-color: #061821 !important;
}
.btn-primary-dark:focus,
.btn-primary-dark:active,
.btn-primary-dark.active,
.btn-primary-dark.focus,
.btn-primary-dark:active,
.btn-primary-dark:focus,
.btn-primary-dark:hover,
.open > .dropdown-toggle.btn-primary-dark {
    background-color: #43c5e6;
    outline: none !important;
    box-shadow: none !important;
    border-color: #061821 !important;
}

.btn-success {
    background-color: #4acf95 !important;
    border: 1px solid #4acf95 !important;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
    background-color: #36ca89 !important;
    border-color: #36ca89 !important;
}

.btn-info {
    background-color: #ea624c !important;
    border: 0px solid #45bbe0 !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
    background-color: #2fb3dc !important;
    border-color: #2fb3dc !important;
}

.btn-warning {
    background-color: #ff9800 !important;
    border: 1px solid #ff9800 !important;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
    background-color: #e68900 !important;
    border-color: #e68900 !important;
}

.btn-danger {
    background-color: #f7531f !important;
    border: 1px solid #f7531f !important;
}

.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
    background-color: #f44109 !important;
    border-color: #f44109 !important;
}

.btn-inverse {
    background-color: #3b3e47 !important;
    border: 1px solid #3b3e47 !important;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
    background-color: #2f3239 !important;
    border-color: #2f3239 !important;
}

.btn-purple {
    background-color: #8892d6 !important;
    border: 1px solid #8892d6 !important;
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
    background-color: #7581cf !important;
    border-color: #7581cf !important;
}

.btn-pink {
    background-color: #ec1d45 !important;
    border: 1px solid #ec1d45 !important;
}

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
    background-color: #dd133a !important;
    border-color: #dd133a !important;
}

.btn-bordered {
    background-color: transparent !important;
}

.btn-bordered.btn-default {
    background-color: #ffffff;
    border: 2px solid rgba(150, 150, 150, 0.2) !important;
}

.btn-bordered.btn-custom {
    color: #43c5e6 !important;
    border: 2px solid #43c5e6 !important;
}

.btn-bordered.btn-custom:hover,
.btn-bordered.btn-custom:focus,
.btn-bordered.btn-custom:active {
    background-color: #43c5e6;
    color: #ffffff !important;
    border: 2px solid #23bbe2 !important;
}

.btn-bordered.btn-primary, .btn-bordered.btn-primary-dark {
    color: #0a2737 !important;
    border: 2px solid #0a2737 !important;
}

.btn-bordered.btn-primary:hover,
.btn-bordered.btn-primary:focus,
.btn-bordered.btn-primary:active {
    background-color: #0a2737;
    color: #ffffff !important;
    border: 2px solid #051219 !important;
}
.btn-bordered.btn-primary-dark:hover,
.btn-bordered.btn-primary-dark:focus,
.btn-bordered.btn-primary-dark:active {
    background-color: #0a2737;
    color: #ffffff !important;
    border: 2px solid #051219 !important;
}

.btn-bordered.btn-success {
    color: #4acf95 !important;
    border: 2px solid #4acf95 !important;
}

.btn-bordered.btn-success:hover,
.btn-bordered.btn-success:focus,
.btn-bordered.btn-success:active {
    background-color: #4acf95;
    color: #ffffff !important;
    border: 2px solid #33c284 !important;
}

.btn-bordered.btn-info {
    color: #45bbe0 !important;
    border: 2px solid #45bbe0 !important;
}

.btn-bordered.btn-info:hover,
.btn-bordered.btn-info:focus,
.btn-bordered.btn-info:active {
    background-color: #45bbe0;
    color: #ffffff !important;
    border: 2px solid #26b0db !important;
}

.btn-bordered.btn-warning {
    color: #ff9800 !important;
    border: 2px solid #ff9800 !important;
}

.btn-bordered.btn-warning:hover,
.btn-bordered.btn-warning:focus,
.btn-bordered.btn-warning:active {
    background-color: #ff9800;
    color: #ffffff !important;
    border: 2px solid #db8300 !important;
}

.btn-bordered.btn-danger {
    color: #f7531f !important;
    border: 2px solid #f7531f !important;
}

.btn-bordered.btn-danger:hover,
.btn-bordered.btn-danger:focus,
.btn-bordered.btn-danger:active {
    background-color: #f7531f;
    color: #ffffff !important;
    border: 2px solid #ea3f08 !important;
}

.btn-bordered.btn-inverse {
    color: #3b3e47 !important;
    border: 2px solid #3b3e47 !important;
}

.btn-bordered.btn-inverse:hover,
.btn-bordered.btn-inverse:focus,
.btn-bordered.btn-inverse:active {
    background-color: #3b3e47;
    color: #ffffff !important;
    border: 2px solid #2b2d34 !important;
}

.btn-bordered.btn-purple {
    color: #8892d6 !important;
    border: 2px solid #8892d6 !important;
}

.btn-bordered.btn-purple:hover,
.btn-bordered.btn-purple:focus,
.btn-bordered.btn-purple:active {
    background-color: #8892d6;
    color: #ffffff !important;
    border: 2px solid #6d7acd !important;
}

.btn-bordered.btn-pink {
    color: #ec1d45 !important;
    border: 2px solid #ec1d45 !important;
}

.btn-bordered.btn-pink:hover,
.btn-bordered.btn-pink:focus,
.btn-bordered.btn-pink:active {
    background-color: #ec1d45;
    color: #ffffff !important;
    border: 2px solid #d41237 !important;
}

.btn-rounded {
    border-radius: 2em;
}

/* Social Buttons */
.btn-facebook {
    color: #ffffff !important;
    background-color: #3b5998 !important;
}

.btn-twitter {
    color: #ffffff !important;
    background-color: #00aced !important;
}

.btn-linkedin {
    color: #ffffff !important;
    background-color: #007bb6 !important;
}

.btn-dribbble {
    color: #ffffff !important;
    background-color: #ea4c89 !important;
}

.btn-googleplus {
    color: #ffffff !important;
    background-color: #dd4b39 !important;
}

.btn-instagram {
    color: #ffffff !important;
    background-color: #517fa4 !important;
}

.btn-pinterest {
    color: #ffffff !important;
    background-color: #cb2027 !important;
}

.btn-dropbox {
    color: #ffffff !important;
    background-color: #007ee5 !important;
}

.btn-flickr {
    color: #ffffff !important;
    background-color: #ff0084 !important;
}

.btn-tumblr {
    color: #ffffff !important;
    background-color: #32506d !important;
}

.btn-skype {
    color: #ffffff !important;
    background-color: #00aff0 !important;
}

.btn-youtube {
    color: #ffffff !important;
    background-color: #bb0000 !important;
}

.btn-github {
    color: #ffffff !important;
    background-color: #171515 !important;
}

/* ===========
   Panels
 =============*/
.panel {
    border: 1px solid #edf1f1;
    box-shadow: none;
    margin-bottom: 20px;
}

.panel .panel-body {
    padding: 5px;
}

.panel .panel-body p {
    margin-bottom: 0;
    line-height: 24px;
}

.panel .panel-body p + p {
    padding-top: 10px;
}

.panel-heading {
    border: none !important;
    padding: 15px 20px;
    margin: -1px;
    border-radius: 4px 4px 0 0;
}

.panel-default > .panel-heading {
    background-color: #ffffff;
    margin: 0;
    color: #f1f6f6;
}

.panel-title {
    font-weight: 700;
    margin: 0;
    font-family: 'vugo', sans-serif;
}

.panel-sub-title {
    color: rgba(255, 255, 255, 0.7) !important;
    margin-top: 3px;
    font-size: 14px;
    margin-bottom: -4px;
}

.panel-footer {
    background: #ffffff;
}

.panel-default .panel-sub-title {
    color: inherit !important;
}

.panel-color .panel-title {
    color: #ffffff;
}

.panel-custom > .panel-heading {
    background-color: #43c5e6;
}

.panel-primary > .panel-heading {
    background-color: #0a2737;
}

.panel-success > .panel-heading {
    background-color: #4acf95;
}

.panel-info > .panel-heading {
    background-color: #45bbe0;
}

.panel-warning > .panel-heading {
    background-color: #ff9800;
}

.panel-danger > .panel-heading {
    background-color: #f7531f;
}

.panel-purple > .panel-heading {
    background-color: #8892d6;
}

.panel-pink > .panel-heading {
    background-color: #ec1d45;
}

.panel-inverse > .panel-heading {
    background-color: #3b3e47;
}

.panel-border {
    border-radius: 3px;
}

.panel-border .panel-heading {
    background-color: #ffffff;
    border-top: 3px solid #ccc !important;
    border-radius: 3px;
    padding: 15px 20px 0;
    margin: -2px 0 0 0 !important;
}

.panel-border .panel-body {
    padding: 15px 20px 20px 20px;
}

.panel-border.panel-primary .panel-heading {
    border-color: #0a2737 !important;
    color: #0a2737 !important;
}

.panel-border.panel-success .panel-heading {
    border-color: #4acf95 !important;
    color: #4acf95 !important;
}

.panel-border.panel-info .panel-heading {
    border-color: #45bbe0 !important;
    color: #45bbe0 !important;
}

.panel-border.panel-warning .panel-heading {
    border-color: #ff9800 !important;
    color: #ff9800 !important;
}

.panel-border.panel-danger .panel-heading {
    border-color: #f7531f !important;
    color: #f7531f !important;
}

.panel-border.panel-purple .panel-heading {
    border-color: #8892d6 !important;
    color: #8892d6 !important;
}

.panel-border.panel-pink .panel-heading {
    border-color: #ec1d45 !important;
    color: #ec1d45 !important;
}

.panel-border.panel-inverse .panel-heading {
    border-color: #3b3e47 !important;
    color: #3b3e47 !important;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: 0 !important;
}

/* =============
   Checkbox and Radios
============= */
.checkbox label,
.radio label {
    padding-left: 25px !important;
    font-size: 15px;
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: -25px;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 11px;
    line-height: 0;
    top: 9px;
    left: 3px;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none !important;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    opacity: 1;
}

.checkbox-custom label input[type="checkbox"]:checked + .cr {
    background-color: #43c5e6;
    border-color: #43c5e6;
    color: #ffffff;
}

.checkbox-primary label input[type="checkbox"]:checked + .cr {
    background-color: #0a2737;
    border-color: #0a2737;
    color: #ffffff;
}

.checkbox-success label input[type="checkbox"]:checked + .cr {
    background-color: #4acf95;
    border-color: #4acf95;
    color: #ffffff;
}

.checkbox-info label input[type="checkbox"]:checked + .cr {
    background-color: #45bbe0;
    border-color: #45bbe0;
    color: #ffffff;
}

.checkbox-warning label input[type="checkbox"]:checked + .cr {
    background-color: #ff9800;
    border-color: #ff9800;
    color: #ffffff;
}

.checkbox-danger label input[type="checkbox"]:checked + .cr {
    background-color: #f7531f;
    border-color: #f7531f;
    color: #ffffff;
}

.checkbox-inverse label input[type="checkbox"]:checked + .cr {
    background-color: #3b3e47;
    border-color: #3b3e47;
    color: #ffffff;
}

.checkbox-pink label input[type="checkbox"]:checked + .cr {
    background-color: #ec1d45;
    border-color: #ec1d45;
    color: #ffffff;
}

.checkbox-purple label input[type="checkbox"]:checked + .cr {
    background-color: #8892d6;
    border-color: #8892d6;
    color: #ffffff;
}

.radio-primary label input[type="radio"]:checked + .cr {
    border-color: #0a2737;
    color: #0a2737;
}

.radio-success label input[type="radio"]:checked + .cr {
    border-color: #4acf95;
    color: #4acf95;
}

.radio-info label input[type="radio"]:checked + .cr {
    border-color: #45bbe0;
    color: #45bbe0;
}

.radio-warning label input[type="radio"]:checked + .cr {
    border-color: #ff9800;
    color: #ff9800;
}

.radio-danger label input[type="radio"]:checked + .cr {
    border-color: #f7531f;
    color: #f7531f;
}

.radio-custom label input[type="radio"]:checked + .cr {
    border-color: #43c5e6;
    color: #43c5e6;
}

.radio-inverse label input[type="radio"]:checked + .cr {
    border-color: #3b3e47;
    color: #3b3e47;
}

.radio-pink label input[type="radio"]:checked + .cr {
    border-color: #ec1d45;
    color: #ec1d45;
}

.radio-purple label input[type="radio"]:checked + .cr {
    border-color: #8892d6;
    color: #8892d6;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

/* =============
   Modals
============= */
.modal .modal-dialog {
    top: 100px;
}

.modal .modal-dialog .modal-content {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #DDDDDD;
    border-radius: 2px;
    box-shadow: none;
    padding: 0;
}

.modal .modal-dialog .modal-content .modal-header, .modal .modal-dialog .modal-content .modal-header-dark {
    border-bottom-width: 2px;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
}

.modal .modal-dialog .modal-content .modal-body, .modal .modal-dialog .modal-content .modal-body-dark {
    padding: 20px;
}

.modal .modal-dialog .modal-content .modal-footer {
    padding: 0;
    padding-top: 15px;
    padding-bottom: 50px !important;
}

.modal-full {
    width: 98%;
}

.modal-content .nav.nav-tabs + .tab-content, .modal-content .nav.nav-tabs-dark + .tab-content {
    margin-bottom: 0;
}

.modal-content .panel-group {
    margin-bottom: 0;
}

.modal-content .panel {
    border-top: none;
}

/* Custom-modal */
.modal-demo {
    background-color: #ffffff;
    width: 600px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    background-clip: padding-box;
    display: none;
}

.modal-demo .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: #edf1f1;
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: #969696;
    color: #ffffff;
    text-align: left;
    margin: 0;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
    top: 20px;
    z-index: 9999;
}

/* =============
   Tabs
============= */
.tab-content {
    padding: 20px 0 0 0;
}

ul.nav-tabs, ul.nav-tabs-dark {
    display: inline-block;
    position: relative;
    margin: 0px auto;
    border: none;
}

.nav-tabs > li, .nav-tabs-dark > li {
    display: inline-block;
}

.nav-tabs > li > a {
    color: #0a2737;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
}

.dark .nav-tabs > li > a {
    color: #698290;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
}

.nav-tabs-dark > li > a {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
}

.nav-tabs > li > a:hover, .nav-tabs-dark > li > a:hover {
    background-color: rgba(152, 166, 173, 0.1);
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    color: #43c5e6;
}
.nav-tabs-dark > li.active > a,
.nav-tabs-dark > li.active > a:focus,
.nav-tabs-dark > li.active > a:hover {
    color: #43c5e6;
}

/* Vertial tab */
.tabs-vertical-env .tab-content {
    background: #ffffff;
    display: table-cell;
    padding: 0 0 0 20px;
    vertical-align: top;
}

.tabs-vertical-env .nav.tabs-vertical {
    display: table-cell;
    min-width: 120px;
    vertical-align: top;
    width: 150px;
}

.tabs-vertical-env .nav.tabs-vertical li > a {
    color: #969696;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    text-transform: uppercase;
}

.tabs-vertical-env .nav.tabs-vertical li.active > a {
    background-color: rgba(152, 166, 173, 0.1);
    border: 0;
    color: #43c5e6;
}

.tabs-vertical-env-right .tab-content {
    padding: 0 20px 0 0;
}

.tabs-bordered {
    border-bottom: 2px solid rgba(152, 166, 173, 0.2) !important;
}

.tabs-bordered li a,
.tabs-bordered li a:hover,
.tabs-bordered li a:focus {
    border: 0 !important;
    background-color: #ffffff !important;
    padding: 10px 20px !important;
}

.tabs-bordered li.active a,
.tabs-bordered li.active a:hover,
.tabs-bordered li.active a:focus {
    border-bottom: 2px solid #43c5e6 !important;
    margin-bottom: -1px;
    color: #43c5e6;
}

/* Navpills */
.nav-pills > li > a {
    color: #969696;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    background-color: #43c5e6;
}

/* =============
   Progressbars
============= */
.progress {
    -webkit-box-shadow: none !important;
    background-color: #f1f6f6;
    box-shadow: none !important;
    height: 10px;
    margin-bottom: 18px;
    overflow: hidden;
}

.progress-bar {
    box-shadow: none;
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
}

.progress.progress-sm {
    height: 5px !important;
}

.progress.progress-sm .progress-bar {
    font-size: 8px;
    line-height: 5px;
}

.progress.progress-md {
    height: 15px !important;
}

.progress.progress-md .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
}

.progress.progress-lg {
    height: 20px !important;
}

.progress.progress-lg .progress-bar {
    font-size: 12px;
    line-height: 20px;
}

.progress-bar-primary {
    background-color: #0a2737;
}

.progress-bar-success {
    background-color: #4acf95;
}

.progress-bar-info {
    background-color: #45bbe0;
}

.progress-bar-warning {
    background-color: #ff9800;
}

.progress-bar-danger {
    background-color: #f7531f;
}

.progress-bar-inverse {
    background-color: #3b3e47;
}

.progress-bar-purple {
    background-color: #8892d6;
}

.progress-bar-pink {
    background-color: #ec1d45;
}

.progress-bar-custom {
    background-color: #43c5e6;
}

/* Progressbar Vertical */
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
}

.progress-vertical .progress-bar {
    width: 100%;
}

.progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
}

.progress-vertical-bottom .progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
    width: 5px !important;
}

.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
    font-size: 8px;
    line-height: 5px;
}

.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
    width: 15px !important;
}

.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
}

.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
    width: 20px !important;
}

.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
    font-size: 12px;
    line-height: 20px;
}

/* =============
   Alerts
============= */
.alert {
    position: relative;
}

.alert .alert-link {
    font-weight: 600;
}

.alert-dismissable .close,
.alert-dismissible .close {
    opacity: 0.9;
}

.alert-icon {
    padding-left: 50px;
}

.alert-icon i {
    position: absolute;
    left: 0;
    height: 50px;
    width: 50px;
    text-align: center;
    top: 0;
    line-height: 50px;
    font-size: 22px;
}

.alert-success {
    color: #4acf95;
    background-color: #ebfaf3;
    border-color: #9be4c4;
}

.alert-success .alert-link {
    color: #30b67c;
}

.alert-success hr {
    border-top-color: #30b67c;
}

.alert-info {
    color: #45bbe0;
    background-color: #def3fa;
    border-color: #9cdbef;
}

.alert-info .alert-link {
    color: #23a6cf;
}

.alert-info hr {
    border-top-color: #23a6cf;
}

.alert-warning {
    color: #ff9800;
    background-color: #fff5e5;
    border-color: #ffd699;
}

.alert-warning .alert-link {
    color: #cc7a00;
}

.alert-warning hr {
    border-top-color: #cc7a00;
}

.alert-danger {
    color: #f7531f;
    background-color: #fef2ee;
    border-color: #fb9f81;
}

.alert-danger .alert-link {
    color: #db3b08;
}

.alert-danger hr {
    border-top-color: #db3b08;
}

.alert-white {
    background-color: #ffffff !important;
}

.jq-toast-single {
    border-radius: 0;
    font-family: 'vugolight', sans-serif;
    background-color: #969696;
    font-size: 14px;
}

.jq-toast-single h2 {
    font-family: 'vugo', sans-serif;
}

.jq-toast-single a {
    font-size: 14px;
}

.jq-toast-single a:hover {
    color: #43c5e6;
}

.jq-icon-info {
    background-color: #45bbe0;
    color: #ffffff;
    border-color: #45bbe0;
}

.jq-icon-success {
    background-color: #4acf95;
    color: #ffffff;
    border-color: #4acf95;
}

.jq-icon-warning {
    background-color: #ff9800;
    color: #ffffff;
    border-color: #ff9800;
}

.jq-icon-error {
    background-color: #f7531f;
    color: #ffffff;
    border-color: #f7531f;
}

.close-jq-toast-single {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 20px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-color: #969696;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
}

.jq-toast-loader {
    height: 3px;
    border-radius: 0;
}

/* =============
   Carousel
============= */
.carousel-control {
    width: 10%;
}

.carousel-control span {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    font-size: 30px;
}

/* =========== */
/* Sweet Alert */
/* =========== */
.swal2-modal {
    font-family: 'vugolight', sans-serif;
}

.swal2-modal .swal2-title {
    font-size: 28px;
}

.swal2-modal .swal2-content {
    font-size: 16px;
}

.swal2-modal .swal2-spacer {
    margin: 10px 0;
}

.swal2-modal .swal2-file,
.swal2-modal .swal2-input,
.swal2-modal .swal2-textarea {
    border: 2px solid #98a6ad;
    font-size: 16px;
    box-shadow: none !important;
}

.swal2-icon.swal2-question {
    color: #43c5e6;
    border-color: #43c5e6;
}

.swal2-icon.swal2-success {
    border-color: #4acf95;
}

.swal2-icon.swal2-success .line {
    background-color: #4acf95;
}

.swal2-icon.swal2-success .placeholder {
    border-color: #4acf95;
}

.swal2-icon.swal2-warning {
    color: #ff9800;
    border-color: #ff9800;
}

.swal2-icon.swal2-error {
    border-color: #f7531f;
}

.swal2-icon.swal2-error .line {
    background-color: #f7531f;
}

.swal2-modal .swal2-file:focus,
.swal2-modal .swal2-input:focus,
.swal2-modal .swal2-textarea:focus {
    outline: 0;
    border: 2px solid #43c5e6;
}

/* =============
   Widgets
============= */
.widget-box-one .widget-one-icon {
    position: absolute;
    right: 30px;
    font-size: 72px !important;
    top: 0;
    color: #f1f6f6;
    overflow: hidden;
    vertical-align: middle;
    line-height: 2 !important;
}

.widget-box-one .wigdet-one-content {
    position: relative;
}

.widget-box-two p {
    font-size: 14px;
}

.widget-box-two .widget-two-icon {
    position: absolute;
    right: 20px;
    font-size: 24px !important;
    top: 15px;
    overflow: hidden;
    vertical-align: middle;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    color: #43c5e6;
    border: 2px solid #43c5e6;
}

.widget-box-two .wigdet-two-content {
    position: relative;
}

.widget-two-custom {
    background-color: #43c5e6;
    border-color: #43c5e6;
}

.widget-two-primary {
    background-color: #ffffff;
    border-color: #43c5e6;
}

.widget-two-success {
    background-color: #4acf95;
    border-color: #4acf95;
}

.widget-two-warning {
    background-color: #ff9800;
    border-color: #ff9800;
}

.widget-two-info {
    background-color: #43c5e6;
    border-color: #43c5e6;
}

.widget-two-trips {
    background-color: #ffffff;
    border-color: #43c5e6;
}

.widget-two-trips .widget-two-icon {
    border-color: #43c5e6;
    color: #43c5e6;
}

.widget-two-requests {
    background-color: #ffffff;
    border-color: #f26624;
}

.widget-two-requests .widget-two-icon {
    border-color: #f26624;
    color: #f26624;
}

.widget-two-referals {
    background-color: #ffffff;
    border-color: #af77cf;
}

.widget-two-referals .widget-two-icon {
    border-color: #af77cf;
    color: #af77cf;
}

.widget-two-danger {
    background-color: #f7531f;
    border-color: #f7531f;
}

.widget-two-inverse {
    background-color: #3b3e47;
    border-color: #3b3e47;
}

.widget-two-purple {
    background-color: #8892d6;
    border-color: #8892d6;
}

.widget-two-pink {
    background-color: #ec1d45;
    border-color: #ec1d45;
}

.widget-two-default {
    background-color: #ffffff;
}

.widget-two-default .widget-two-icon {
    color: #43c5e6;
    border: 2px solid #43c5e6;
}

.widget-box-three img {
    height: 48px;
    width: 48px;
    line-height: 80px;
}

.widget-box-three .bg-icon {
    height: 80px;
    width: 80px;
    line-height: 76px;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-border-radius: 50%;
    background-clip: padding-box;
    border: 1px dashed #98a6ad;
    background-color: #f1f6f6;
    margin-right: 20px;
}

.widget-box-three .bg-icon i {
    vertical-align: middle;
    font-size: 36px;
    color: #98a6ad;
}

.widget-user {
    position: relative;
}

.widget-user img {
    width: 60px;
    height: 60px;
}

.user-position {
    position: absolute;
    top: 0;
    border-left: 1px solid #edf1f1;
    bottom: 0;
    width: 44px;
    font-size: 16px;
    text-align: center;
    right: 0;
    left: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.user-position span {
    transform: rotate(90deg);
}

/* Avatar box */
.avatar-sm-box {
    height: 32px;
    width: 32px;
    color: #ffffff;
    display: block;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    font-family: 'vugo', sans-serif;
    font-size: 16px;
}

/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
    background: #f1f6f6;
    border: 1px solid #d1d7da;
    padding: 10px 16px;
    height: auto;
    font-size: 16px;
    font-weight: normal;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
}

.custom-dd .dd-list .dd-item .dd-handle:hover {
    color: #43c5e6;
}

.custom-dd .dd-list .dd-item button {
    height: auto;
    font-size: 25px;
    margin: 8px auto;
    color: #f1f6f6;
    width: 35px;
}

.custom-dd-empty .dd-list .dd3-handle {
    border: none;
    background: #f1f6f6;
    height: 36px !important;
    width: 36px !important;
}

.custom-dd-empty .dd-list .dd3-handle:before {
    color: inherit;
    top: 7px;
}

.custom-dd-empty .dd-list .dd3-handle:hover {
    color: #43c5e6;
}

.custom-dd-empty .dd-list .dd3-content {
    height: auto;
    border: none;
    padding: 8px 16px 8px 46px;
    background: #f1f6f6;
    font-family: 'vugo', sans-serif;
}

.custom-dd-empty .dd-list .dd3-content:hover {
    color: #43c5e6;
}

.custom-dd-empty .dd-list button {
    width: 26px;
    height: 26px;
    font-size: 16px;
    font-weight: 600;
}

.dd-dragel > .dd-item .dd-handle {
    padding: 8px 16px !important;
    background: #f1f6f6;
    height: auto;
}

.dd-placeholder,
.dd-empty {
    background: #f1f6f6;
    border: 1px dashed #98a6ad;
}

.dd-dragel > .dd3-item > .dd3-handle {
    border: none;
    background: #f1f6f6;
    height: 36px !important;
    width: 36px !important;
}

.dd-dragel > .dd3-item > .dd3-handle:before {
    color: inherit;
    top: 7px;
}

.dd-dragel > .dd3-item > .dd3-content {
    padding: 8px 16px 8px 46px;
    background: #f1f6f6;
    height: auto;
}

/* =============
   Rating
============= */
.rating-md i {
    font-size: 18px;
}

.rating-lg i {
    font-size: 24px;
}

/* =============
   Calendar
============= */
.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-view {
    margin-top: 30px;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar {
    margin-bottom: 5px;
    margin-top: 15px;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
    font-family: 'vugo', sans-serif;
}

.fc-day {
    background: #ffffff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc th.fc-widget-header {
    background: #edf1f1;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #edf1f1;
}

.fc-button {
    background: #edf1f1;
    border: none;
    color: #f1f6f6;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}

.fc-state-hover {
    background: #f1f6f6;
}

.fc-state-highlight {
    background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #43c5e6 !important;
    color: #ffffff !important;
    text-shadow: none !important;
}

.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #ffffff;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 13px;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.external-event {
    cursor: move;
    margin: 10px 0;
    padding: 6px 10px;
}

.external-event.bg-primary {
    background-color: rgba(10, 39, 55, 0.3) !important;
    color: #0a2737;
}

.external-event.bg-success {
    background-color: rgba(74, 207, 149, 0.3) !important;
    color: #4acf95;
}

.external-event.bg-info {
    background-color: rgba(69, 187, 224, 0.3) !important;
    color: #45bbe0;
}

.external-event.bg-warning {
    background-color: rgba(255, 152, 0, 0.3) !important;
    color: #ff9800;
}

.external-event.bg-danger {
    background-color: rgba(247, 83, 31, 0.3) !important;
    color: #f7531f;
}

.external-event.bg-pink {
    background-color: rgba(236, 29, 69, 0.3) !important;
    color: #ec1d45;
}

.external-event.bg-purple {
    background-color: rgba(136, 146, 214, 0.3) !important;
    color: #8892d6;
}

.external-event.bg-inverse {
    background-color: rgba(59, 62, 71, 0.3) !important;
    color: #3b3e47;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: 'vugo', sans-serif;
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: 'vugo', sans-serif;
}

/* =============
   Form
============= */
/* Form components */
label {
    font-weight: 600;
    font-size: 14px;
    font-family: 'vugo', sans-serif;
}

.form-control {
    border: 1px solid #969696;
    border-radius: 4px;
    padding: 7px 12px;
    height: 38px;
    max-width: 100%;
    font-size: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.form-control-dark {
    border: 1px solid #969696;
    border-radius: 4px;
    padding: 7px 12px;
    height: 38px;
    background: #1d1f20;
    max-width: 100%;
    font-size: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    width: 100%;
}

.form-control:focus, .form-control-dark:focus {
    border: 1px solid #b4c4c4;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
}

textarea.form-control, textarea.form-control-dark {
    min-height: 90px;
    border: solid 1px #969696;
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.3333333;
    border-radius: 4px;
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.form-horizontal .form-group {
    margin-left: -10px;
    margin-right: -10px;
}

.form-control-feedback {
    line-height: 38px !important;
}

.input-group-btn .btn {
    padding: 7px 12px;
    height: 38px;
}

.input-group-btn .btn-sm {
    padding: 5px 10px;
}

.input-group-btn .btn-lg {
    padding: 10px 17px;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label,
.has-success .form-control-feedback {
    color: #4acf95;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label,
.has-warning .form-control-feedback {
    color: #ff9800;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label,
.has-error .form-control-feedback {
    color: #f7531f;
}

.has-success .form-control, .has-success .form-control-dark {
    border-color: #4acf95;
    box-shadow: none !important;
}

.has-warning .form-control,.has-warning .form-control-dark {
    border-color: #ff9800;
    box-shadow: none !important;
}

.has-error .form-control, .has-error .form-control-dark {
    border-color: #f7531f;
    box-shadow: none !important;
}

.input-group-addon {
    border-radius: 2px;
    border: 1px solid #e4eaea;
    background-color: #e4eaea;
}

/* == Form Advanced */
/* Bootstrap tagsinput */
.bootstrap-tagsinput {
    box-shadow: none;
    padding: 0 7px 0;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #e3e3e3;
}

.bootstrap-tagsinput-dark {
    box-shadow: none;
    padding: 0 7px 0;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #e3e3e3;
}

.bootstrap-tagsinput .label-info {
    background-color: #43c5e6 !important;
    display: inline-block;
    padding: 5px;
    margin: 3px 1px;
}

.bootstrap-tagsinput-dark .label-info {
    background-color: #43c5e6 !important;
    display: inline-block;
    padding: 5px;
    margin: 3px 1px;
}

/* Select 2 */
.select2-container {
    width: 100% !important;
}

.select2-container .select2-selection--single {
    border: 1px solid #E3E3E3 !important;
    height: 38px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 36px !important;
    padding-left: 12px !important;
}

.select2-container .select2-selection--single .select2-selection__arrow {
    height: 34px;
    width: 34px;
    right: 3px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
    border-color: #999 transparent transparent transparent;
    border-width: 6px 6px 0 6px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #999 transparent !important;
    border-width: 0 6px 6px 6px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #43c5e6;
}

.select2-results__option {
    padding: 6px 12px;
}

.select2-dropdown {
    border: 1px solid #e3e3e3 !important;
    padding-top: 5px;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.select2-search input {
    border: 1px solid #e3e3e3 !important;
}

.select2-container .select2-selection--multiple {
    min-height: 38px !important;
    border: 1px solid #e3e3e3 !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 2px 10px;
}

.select2-container .select2-selection--multiple .select2-search__field {
    margin-top: 7px;
    border: 0 !important;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 1px;
    padding: 0 7px;
}

/* AUTOCOMPLETE */
.autocomplete-suggestions {
    border: 1px solid #f9f9f9;
    background: #ffffff;
    cursor: default;
    overflow: auto;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.autocomplete-suggestion {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-no-suggestion {
    padding: 2px 5px;
}

.autocomplete-selected {
    background: rgba(152, 166, 173, 0.3);
    cursor: pointer;
}

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #969696;
}

.autocomplete-group {
    padding: 2px 5px;
}

.autocomplete-group strong {
    font-weight: bold;
    font-size: 16px;
    color: #969696;
    display: block;
}

/* Bootstrap-select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100% !important;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
}

.bootstrap-select .glyphicon {
    padding-right: 6px;
}

/* Bootstrap filestyle */
.icon-span-filestyle {
    padding-right: 5px;
}

/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
    padding: 9px 12px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
    top: 4px;
    left: 8px;
}

/* Form validation */
.parsley-error {
    border-color: #f7531f !important;
}

.parsley-errors-list {
    display: none;
    margin: 0;
    padding: 0;
}

.parsley-errors-list > li {
    font-size: 12px;
    list-style: none;
    color: #f7531f;
    margin-top: 5px;
}

.bootstrap-timepicker-widget table td input {
    border: 1px solid rgba(150, 150, 150, 0.3);
    width: 35px;
}

.datepicker-dropdown {
    padding: 10px !important;
}

.datepicker td,
.datepicker th {
    width: 30px;
    height: 30px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #43c5e6 !important;
    color: #ffffff !important;
    background-image: none !important;
    text-shadow: none !important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #4acf95 !important;
    color: #ffffff !important;
    background-image: none !important;
}

.datepicker-inline {
    border: 2px solid rgba(150, 150, 150, 0.1);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #43c5e6;
}

.daterangepicker .input-mini.active {
    border: 1px solid rgba(150, 150, 150, 0.3);
}

.daterangepicker .ranges li {
    border-radius: 2px;
    color: #969696;
    font-weight: 600;
    font-size: 12px;
    font-family: 'vugo', sans-serif;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
    border: 1px solid rgba(150, 150, 150, 0.3);
    padding: 2px;
    width: 60px;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    background-color: #43c5e6;
    border: 1px solid #43c5e6;
    color: #ffffff;
}

/* Wizard */
.wizard > .content {
    background: #ffffff;
    min-height: 240px;
    padding: 20px !important;
    border: 1px solid #f1f6f6;
    margin-top: 10px !important;
}

.wizard > .content > .body {
    padding: 0px;
    position: relative;
    width: 100%;
}

.wizard > .content > .body input {
    border: 1px solid #e5eeee;
}

.wizard > .content > .body ul > li {
    display: block;
    line-height: 30px;
}

.wizard > .content > .body label.error {
    color: #f7531f;
    margin-left: 0;
    margin-top: 5px;
    font-size: 12px;
}

.wizard > .content > .body label {
    display: inline-block;
    margin-top: 10px;
}

.wizard > .steps a {
    font-size: 16px;
    text-align: center;
    font-family: 'vugo', sans-serif;
}

.wizard > .steps .number {
    font-size: 74px;
    line-height: 86px;
    font-family: 'vugo', sans-serif;
    position: absolute;
    left: 10px;
    top: -14px;
    opacity: 0.1;
}

.wizard > .steps .disabled a {
    background: #f1f6f6;
    color: #a3a3a3 !important;
    cursor: default;
    border: 1px solid #e1ecec;
}

.wizard > .steps .disabled a:hover {
    background: #e1ecec;
    border: 1px solid #d2e2e2;
}

.wizard > .steps .disabled a:active {
    background: #e1ecec;
    border: 1px solid #d2e2e2;
}

.wizard > .steps .current a {
    background: #43c5e6;
}

.wizard > .steps .current a:hover {
    background: #43c5e6;
}

.wizard > .steps .current a:hover .number {
    color: #ffffff;
    opacity: 0.4;
}

.wizard > .steps .current a:active {
    background: #43c5e6;
}

.wizard > .steps .current a:active .number {
    color: #ffffff;
    opacity: 0.4;
}

.wizard > .steps .current a .number {
    color: #ffffff;
    opacity: 0.4;
}

.wizard > .steps .done a {
    background: #f1f6f6 !important;
    color: #a3a3a3 !important;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active,
.wizard > .content {
    border-radius: 2px;
    position: relative;
}

.wizard > .actions {
    margin-top: 15px !important;
}

.wizard > .actions a {
    background: #43c5e6;
    border-radius: 2px !important;
    color: #ffffff;
}

.wizard > .actions a:hover {
    background: #1aa0c3;
    color: #ffffff;
}

.wizard > .actions a:active {
    background: #1aa0c3;
    color: #ffffff;
}

.wizard > .actions .disabled a {
    background: #ffffff;
    color: #969696;
    cursor: default;
    border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:hover {
    background: #f1f6f6;
    color: #969696;
    cursor: default;
    border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:active {
    background: #f1f6f6;
    color: #969696;
    cursor: default;
    border: 1px solid #eaeaea;
}

.wizard.vertical > .content {
    margin-top: 0px !important;
}

@media (max-width: 560px) {
    .wizard.vertical > .steps,
    .wizard.vertical > .content {
        width: 100%;
    }
}

/* Summernote */
.note-editor {
    position: relative;
}

.note-editor .btn-default {
    background-color: transparent;
    border-color: transparent !important;
}

.note-editor .btn-group-sm > .btn,
.note-editor .btn-sm {
    padding: 8px 12px !important;
}

.note-editor .note-toolbar {
    background-color: #f1f6f6;
    border-bottom: 1px solid #edf1f1;
    margin: 0;
}

.note-editor .note-statusbar {
    background-color: #ffffff;
}

.note-editor .note-statusbar .note-resizebar {
    border-top: none;
    height: 15px;
    padding-top: 3px;
}

.note-editor.note-frame {
    border: 1px solid #edf1f1 !important;
}

.note-popover .popover .popover-content {
    padding: 5px 0 10px 5px;
}

.note-popover .btn-default {
    background-color: transparent;
    border-color: transparent !important;
}

.note-popover .btn-group-sm > .btn,
.note-popover .btn-sm {
    padding: 8px 12px !important;
}

.note-toolbar {
    padding: 5px 0 10px 5px;
}

/* Wysiwig css */
.mce-panel {
    border: 1px solid #f1f6f6 !important;
    background-color: #f1f6f6 !important;
}

.mce-menu {
    background-color: #ffffff !important;
    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}

.mce-menubar .mce-menubtn:hover,
.mce-menubar .mce-menubtn.mce-active,
.mce-menubar .mce-menubtn:focus {
    border-color: #f1f6f6 !important;
}

.mce-menu-item:hover,
.mce-menu-item.mce-selected,
.mce-menu-item:focus,
.mce-menu-item-normal.mce-active,
.mce-primary {
    background-color: #43c5e6 !important;
}

.mce-window-head .mce-title {
    font-family: 'vugo', sans-serif;
}

.mce-window {
    padding: 20px !important;
}

.mce-window-head {
    padding-bottom: 20px !important;
    border-bottom: 0 !important;
}

/* == Form Uploads == */
.jFiler-input-dragDrop {
    width: 100%;
    background-color: #fafafa;
}

.jFiler-theme-default .jFiler-input {
    width: 100%;
}

.jFiler-theme-default .jFiler-input-button {
    background-color: #43c5e6;
    background-image: none !important;
    color: #ffffff;
    border: 1px solid #43c5e6 !important;
}

/* =============
   Tables
============= */
.buttontd {
    width: 19%;
}

th {
    font-family: 'vugo', sans-serif;
    font-weight: normal;
    font-size: 13px;
    padding: 12px !important;
}

.table-centered td {
    vertical-align: middle !important;
}

/* Data table */
div.dt-button-info {
    background-color: #43c5e6;
    border: none;
    color: #ffffff;
    box-shadow: none;
    border-radius: 3px;
    text-align: center;
    z-index: 21;
}

div.dt-button-info h2 {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

table.dataTable {
    margin-top: 10px !important;
    margin-bottom: 18px !important;
}

.table-bordered.dataTable > thead > tr > td,
.table-bordered.dataTable > thead > tr > th {
    border-bottom-width: 1px !important;
}

/* Fixed Header table */
.fixedHeader-floating {
    top: 70px !important;
}

/* Key Table border */
table.dataTable th.focus,
table.dataTable td.focus {
    outline: 2px solid #43c5e6 !important;
    outline-offset: -1px;
    background-color: #43c5e6;
    color: #ffffff;
}

/* Responsive data table */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    box-shadow: 0 0 3px rgba(67, 89, 102, 0.2);
    background-color: #4acf95;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    background-color: #f7531f;
}

table.dataTable > tbody > tr.child span.dtr-title {
    font-family: 'vugo', sans-serif;
}

/* ColVid Tables */
div.ColVis {
    float: none;
    margin-right: 30px;
}

button.ColVis_Button,
.ColVis_Button:hover {
    float: none;
    border-radius: 3px;
    outline: none !important;
    background: none;
    box-shadow: none;
    color: #ffffff !important;
    background-color: #43c5e6 !important;
    border: 1px solid #43c5e6 !important;
}

.dataTables_length {
    float: left;
}

div.ColVis_collectionBackground {
    background-color: transparent;
}

ul.ColVis_collection {
    padding: 10px 0 0 0;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border: none;
}

ul.ColVis_collection li {
    background: transparent !important;
    padding: 3px 10px !important;
    border: none !important;
    box-shadow: none !important;
}

#datatable-colvid_info {
    float: left;
}

/* Responsive Table */
.table-rep-plugin .dropdown-menu li.checkbox-row {
    padding: 2px 15px !important;
}

.table-rep-plugin .table-responsive {
    border: none !important;
}

.table-rep-plugin tbody th {
    font-size: 14px;
    font-weight: normal;
}

.table-rep-plugin .checkbox-row {
    padding-left: 40px;
}

.table-rep-plugin .checkbox-row label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
}

.table-rep-plugin .checkbox-row label::before {
    -o-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #98a6ad;
    content: "";
    display: inline-block;
    height: 17px;
    left: 0;
    margin-left: -20px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 17px;
    outline: none !important;
}

.table-rep-plugin .checkbox-row label::after {
    color: #f1f6f6;
    display: inline-block;
    font-size: 11px;
    height: 16px;
    left: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    position: absolute;
    top: -1px;
    width: 16px;
}

.table-rep-plugin .checkbox-row input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
    opacity: 0.65;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label::before {
    outline-offset: -2px;
    outline: none;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
    content: "\f00c";
    font-family: 'FontAwesome';
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label::before {
    background-color: #edf1f1;
    cursor: not-allowed;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::before {
    background-color: #ffffff;
    border-color: #43c5e6;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
    color: #43c5e6;
}

.table-rep-plugin table.focus-on tbody tr.focused th,
.table-rep-plugin table.focus-on tbody tr.focused td,
.table-rep-plugin .sticky-table-header {
    background-color: #43c5e6;
    color: #ffffff;
    border-color: #43c5e6;
}

.table-rep-plugin .sticky-table-header.fixed-solution {
    top: 70px !important;
}

/* Data table editable */
.add-edit-table td,
.add-edit-table th {
    vertical-align: middle !important;
}

.add-edit-table td {
    border: 0 !important;
}

#datatable-editable .actions a {
    padding: 5px;
}

#datatable-editable .form-control, #datatable-editable .form-control-dark {
    background-color: #ffffff;
    width: 100%;
}

#datatable-editable .fa-trash-o {
    color: #f7531f;
}

#datatable-editable .fa-times {
    color: #f7531f;
}

#datatable-editable .fa-pencil {
    color: #43c5e6;
}

#datatable-editable .fa-save {
    color: #4acf95;
}

#datatable td {
    font-weight: normal;
}

.modal-block {
    background: transparent;
    margin: 40px auto;
    max-width: 600px;
    padding: 0;
    position: relative;
    text-align: left;
}

.dt-buttons {
    float: left;
}

div#datatable-buttons_info {
    float: left;
}

table.dataTable thead th {
    position: relative;
    background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    position: absolute;
    top: 7px;
    right: 8px;
    display: block;
    font-family: FontAwesome;
}

table.dataTable thead th.sorting:after {
    content: "\f0dc";
    padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
    content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
    content: "\f0dd";
}

.DTFC_LeftBodyWrapper table thead th.sorting:after,
.dataTables_scrollBody table thead th.sorting:after,
.DTFC_RightBodyLiner table thead th.sorting:after,
.DTFC_LeftBodyWrapper table thead th.sorting_asc:after,
.dataTables_scrollBody table thead th.sorting_asc:after,
.DTFC_RightBodyLiner table thead th.sorting_asc:after,
.DTFC_LeftBodyWrapper table thead th.sorting_desc:after,
.dataTables_scrollBody table thead th.sorting_desc:after,
.DTFC_RightBodyLiner table thead th.sorting_desc:after {
    display: none !important;
}

/* Tablesaw Tables */
.tablesaw thead {
    background: rgba(241, 246, 246, 0.7);
    background-image: none;
    border: none;
}

.tablesaw thead th {
    text-shadow: none;
    letter-spacing: 0.06em;
}

.tablesaw thead tr:first-child th {
    font-weight: 600;
    border: none;
    font-family: 'vugo', sans-serif;
}

.tablesaw td {
    border-top: 1px solid rgba(241, 246, 246, 0.7) !important;
}

.tablesaw td,
.tablesaw tbody th {
    font-size: inherit;
    line-height: inherit;
    padding: 10px !important;
}

.tablesaw-stack tbody tr,
.tablesaw tbody tr {
    border-bottom: none;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after,
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
    font-family: FontAwesome;
    font-size: 10px;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
    content: "\f176";
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
    content: "\f175";
}

.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
    font-size: 8px;
    padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
    box-shadow: none;
}

.tablesaw-enhanced .tablesaw-bar .btn {
    text-shadow: none;
    background-image: none;
}

.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
    background: #ffffff;
}

.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
    color: #43c5e6 !important;
    background-color: #f1f6f6;
    outline: none !important;
    box-shadow: none !important;
    background-image: none;
}

/* Table colored */
.table-colored thead th {
    color: #ffffff;
}

.table-colored.table-custom thead th {
    background-color: #43c5e6;
}

.table-colored.table-primary thead th {
    background-color: #0a2737;
}

.table-colored.table-success thead th {
    background-color: #4acf95;
}

.table-colored.table-info thead th {
    background-color: #43c5e6;
}

.table-colored.table-warning thead th {
    background-color: #ff9800;
}

.table-colored.table-danger thead th {
    background-color: #f7531f;
}

.table-colored.table-inverse thead th {
    background-color: #3b3e47;
}

.table-colored.table-pink thead th {
    background-color: #ec1d45;
}

.table-colored.table-purple thead th {
    background-color: #8892d6;
}

/* Table colored-bordered */
.table-colored-bordered thead th {
    border-bottom: 0 !important;
    color: #ffffff;
}

.table-colored-bordered.table-bordered-primary {
    border: 2px solid #0a2737;
}

.table-colored-bordered.table-bordered-primary thead th {
    background-color: #0a2737;
}

.table-colored-bordered.table-bordered-success {
    border: 2px solid #4acf95;
}

.table-colored-bordered.table-bordered-success thead th {
    background-color: #4acf95;
}

.table-colored-bordered.table-bordered-info {
    border: 2px solid #45bbe0;
}

.table-colored-bordered.table-bordered-info thead th {
    background-color: #45bbe0;
}

.table-colored-bordered.table-bordered-custom {
    border: 2px solid #43c5e6;
}

.table-colored-bordered.table-bordered-custom thead th {
    background-color: #43c5e6;
}

.table-colored-bordered.table-bordered-warning {
    border: 2px solid #ff9800;
}

.table-colored-bordered.table-bordered-warning thead th {
    background-color: #ff9800;
}

.table-colored-bordered.table-bordered-danger {
    border: 2px solid #f7531f;
}

.table-colored-bordered.table-bordered-danger thead th {
    background-color: #f7531f;
}

.table-colored-bordered.table-bordered-inverse {
    border: 2px solid #3b3e47;
}

.table-colored-bordered.table-bordered-inverse thead th {
    background-color: #3b3e47;
}

.table-colored-bordered.table-bordered-pink {
    border: 2px solid #ec1d45;
}

.table-colored-bordered.table-bordered-pink thead th {
    background-color: #ec1d45;
}

.table-colored-bordered.table-bordered-purple {
    border: 2px solid #8892d6;
}

.table-colored-bordered.table-bordered-purple thead th {
    background-color: #8892d6;
}

/* Table full colored */
.table-colored-full {
    color: #ffffff;
}

.table-colored-full thead th {
    border-bottom: 0 !important;
    padding: 10px !important;
}

.table-colored-full tbody td,
.table-colored-full tbody th {
    border: 0 !important;
}

.table-colored-full.table-full-primary {
    background-color: rgba(10, 39, 55, 0.8);
}

.table-colored-full.table-full-primary thead th {
    background-color: #0a2737;
}

.table-colored-full.table-full-primary tbody tr:hover {
    background-color: #0a2737;
}

.table-colored-full.table-full-success {
    background-color: rgba(74, 207, 149, 0.8);
}

.table-colored-full.table-full-success thead th {
    background-color: #4acf95;
}

.table-colored-full.table-full-success tbody tr:hover {
    background-color: #4acf95;
}

.table-colored-full.table-full-info {
    background-color: rgba(69, 187, 224, 0.8);
}

.table-colored-full.table-full-info thead th {
    background-color: #45bbe0;
}

.table-colored-full.table-full-info tbody tr:hover {
    background-color: #45bbe0;
}

.table-colored-full.table-full-custom {
    background-color: rgba(67, 197, 230, 0.8);
}

.table-colored-full.table-full-custom thead th {
    background-color: #43c5e6;
}

.table-colored-full.table-full-custom tbody tr:hover {
    background-color: #43c5e6;
}

.table-colored-full.table-full-warning {
    background-color: rgba(255, 152, 0, 0.8);
}

.table-colored-full.table-full-warning thead th {
    background-color: #ff9800;
}

.table-colored-full.table-full-warning tbody tr:hover {
    background-color: #ff9800;
}

.table-colored-full.table-full-danger {
    background-color: rgba(247, 83, 31, 0.8);
}

.table-colored-full.table-full-danger thead th {
    background-color: #f7531f;
}

.table-colored-full.table-full-danger tbody tr:hover {
    background-color: #f7531f;
}

.table-colored-full.table-full-inverse {
    background-color: rgba(59, 62, 71, 0.8);
}

.table-colored-full.table-full-inverse thead th {
    background-color: #3b3e47;
}

.table-colored-full.table-full-inverse tbody tr:hover {
    background-color: #3b3e47;
}

.table-colored-full.table-full-pink {
    background-color: rgba(236, 29, 69, 0.8);
}

.table-colored-full.table-full-pink thead th {
    background-color: #ec1d45;
}

.table-colored-full.table-full-pink tbody tr:hover {
    background-color: #ec1d45;
}

.table-colored-full.table-full-purple {
    background-color: rgba(136, 146, 214, 0.8);
}

.table-colored-full.table-full-purple thead th {
    background-color: #8892d6;
}

.table-colored-full.table-full-purple tbody tr:hover {
    background-color: #8892d6;
}

/* =====================================
   CHARTS
   ===================================== */
.chart-detail-list li {
    margin: 0 10px;
}

.chart-detail-list li h5 {
    color: rgba(150, 150, 150, 0.6);
    text-transform: uppercase;
    font-size: 13px;
}

/* Flot chart */
#flotTip {
    padding: 8px 12px;
    background-color: #969696 !important;
    z-index: 100;
    color: #ffffff;
    opacity: 0.9;
    font-size: 13px;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}

.legend tr {
    height: 20px;
    font-family: 'vugo', sans-serif;
}

.legendLabel {
    padding-left: 5px !important;
    line-height: 10px;
    padding-right: 10px;
}

/* Morris chart */
.morris-hover.morris-default-style {
    border-radius: 5px;
    padding: 10px 12px;
}

/* Chartist chart */
.ct-golden-section:before {
    float: none;
}

.ct-chart {
    max-height: 300px;
}

.ct-chart .ct-label {
    fill: #98a6ad;
    color: #98a6ad;
    font-size: 14px;
    line-height: 1;
}

.ct-grid {
    stroke: rgba(150, 150, 150, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
    color: #ffffff;
    fill: #ffffff;
    font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: #0a2737;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: #45bbe0;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: #43c5e6;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
    stroke: #8892d6;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
    stroke: #969696;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
    stroke: #ec1d45;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
    stroke: #f7531f;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
    fill: #0a2737;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
    fill: #45bbe0;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
    fill: #43c5e6;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
    fill: #43c5e6;
}

.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    background: #969696;
    color: #ffffff;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
    opacity: 1;
}

/* C3 chart */
.c3 svg {
    max-width: 100%;
}

.c3-tooltip td > span {
    background: #969696;
}

.c3-tooltip td {
    border-left: none;
}

.c3-tooltip {
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.12);
    opacity: 1;
}

.c3-chart-arcs-title {
    font-size: 18px;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
}

.c3-tooltip tr {
    border: none !important;
}

.c3-tooltip th {
    background-color: #969696;
}

.c3-tooltip .value {
    font-weight: 600;
    font-family: 'vugo', sans-serif;
}

/* Sparkline chart */
.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: auto !important;
    height: auto !important;
    background-color: #969696 !important;
    padding: 5px 10px !important;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    border-color: #969696 !important;
}

.jqsfield {
    font-size: 12px !important;
    line-height: 18px !important;
}

/* Google Chart*/
.google-chart .chart {
    display: inline-block;
}

.btn {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
}

.table-responsive {
    border: solid 0px #000000 !important;
    border-radius: 0px !important;
}

.table > thead > tr > th {
    border: solid 0px #000000 !important;
}

td {
    vertical-align: middle !important;
}

table.m-0 {
    font-size: 16px !important;
    color: #000000 !important;
    letter-spacing: 0px !important;
}

/* File Uploader */
.fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
.fileuploader-theme-thumbnails .fileuploader-items-list li.fileuploader-item {
    display: block;
    height: 90px;
    line-height: 95px;
    padding: 5px;
    vertical-align: top;
    width: 185px;
    border-radius: 4px;
    border: 1px dashed #0a2737;
}

.fileuploader-thumbnails-input-inner {
    width: 172px;
    height: 80px;
}

.fileuploader-theme-thumbnails .fileuploader-items-list {
    width: 185px;
    position: relative;
    margin: 0px auto;
    padding: 0px;
}

.fileuploader {
    background: transparent;
    padding: 0px;
    margin: 0px;
}

.fileuploader-theme-thumbnails .fileuploader-items-list li.fileuploader-item {
    position: absolute;
    z-index: 99999;
}

.fileuploader-theme-thumbnails .fileuploader-thumbnails-input-inner {
    -moz-user-select: none;
    border: solid 0px #000000 !important;
    background: #a8efff;
    border-radius: 0px;
    color: #0a2737;
    line-height: 80px;
    cursor: pointer;
    font-size: 40px;
    text-align: center;
    transition: all 0.1s ease 0s;
    vertical-align: top;
}

.fileuploader-theme-thumbnails .fileuploader-items-list .actions-holder {
    background-color: #ba8ad6;
}

.fileuploader-input-button {
    background: #43c5e6 !important;
}

/*
Template Name: Codefox Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Components
*/
/* =============
  == Components List==

   - Popover / Tooltips
   - Buttons
   - Panels
   - Checkbox and radio
   - Modals
   - Tabs
   - Progressbars
   - Notifications
   - Alerts
   - Carousel
   - Sweet Alert
   - Widgets
   - Nestable
   - Rating
   - Calendar
   - Form
   - Tables
   - Charts

============= */
/* =================
   Popover / Tooltips
==================== */
/* Popover */
.popover {
    font-family: inherit;
    border: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
}

.popover .popover-title {
    background-color: transparent;
    color: #43c5e6;
    padding: 12px 15px;
    font-size: 15px;
}

.popover .arrow {
    border-color: transparent !important;
}

/* Tooltips */
.tooltip {
    font-family: 'vugo', sans-serif;
}

.tooltip .tooltip-inner {
    padding: 4px 10px;
    border-radius: 2px;
    background-color: #969696;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #969696;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #969696;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #969696;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #969696;
}

/* Tooltpster */
.tooltipster-sidetip .tooltipster-box {
    background-color: #43c5e6;
    border: 2px solid #43c5e6;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
    border-top-color: #43c5e6;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
    border-bottom-color: #43c5e6;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
    border-left-color: #43c5e6;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
    border-right-color: #43c5e6;
}

/*POLITIQ ICONS*/
.pol {
    color: #000000;
    font-size: 28px;
}

/*END OF POLITIQ ICONS*/
/* =============
   Buttons
============= */
.btn {
    border-radius: 6px;
    font-size: 16px;
    padding: 12px 24px;
    outline: none !important;
    font-family: 'vugo', sans-serif;
}

.btn-mini {
    /*background:@white !important;
      border:solid 1px @dark !important;*/
    border: solid 1px #969696 !important;
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 8px;
    outline: none !important;
    font-family: 'vugo', sans-serif;
}
.btn-mini-dark {
    /*background:@white !important;
      border:solid 1px @dark !important;*/
    /*border: solid 1px #969696 !important;*/
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 8px;
    outline: none !important;
    font-family: 'vugo', sans-serif;
}

.btn-md {
    padding: 9px 18px;
}

.btn-group-lg > .btn,
.btn-lg {
    padding: 12px 20px !important;
    font-size: 18px;
}

.btn-group-sm > .btn,
.btn-sm {
    padding: 5px 10px !important;
    font-size: 14px;
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px !important;
    font-size: 12px;
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.btn-custom,
.btn-primary,
.btn-primary-dark,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink {
    color: #ffffff !important;
}

.btn-custom {
    background-color: #43c5e6;
    border-color: #43c5e6;
}

.btn-mini:hover i,
.btn-mini:focus i,
.btn-mini:active i {
    color: #43c5e6;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
    background-color: #2cbee3 !important;
    border: 1px solid #2cbee3 !important;
}

.btn-default {
    background-color: #ffffff;
    border-color: rgba(150, 150, 150, 0.2);
}

.btn-toolbar:hover,
.btn-toolbar:focus,
.btn-toolbar:active,
.btn-toolbar.active,
.btn-toolbar.focus,
.btn-toolbar:active,
.btn-toolbar:focus,
.btn-toolbar:hover,
.open > .dropdown-toggle.btn-toolbar {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
    background-color: rgba(150, 150, 150, 0.07) !important;
    border-color: rgba(150, 150, 150, 0.2) !important;
}

.btn-toolbar {
    padding: 0px 15px !important;
    background: transparent !important;
    min-width: 30px;
}

.btn-mini:hover,
.btn-mini:focus,
.btn-mini:active,
.btn-mini.active,
.btn-mini.focus,
.btn-mini:active,
.btn-mini:focus,
.btn-mini:hover,
.open > .dropdown-toggle.btn-mini {
    color: #43c5e6;
    border: solid 1px #43c5e6 !important;
    background: #ffffff !important;
    outline: none;
    box-shadow: none;
}

.btn-primary {
    background-color: #000000 !important;
    border: 0px solid #0a2737 !important;
}
.btn-primary-dark {
    background-color: #46bcf2 !important;
    border: 0px solid #0a2737 !important;
}

.btn-upload {
    background-color: #43c5e6 !important;
    border: 0px solid #0a2737 !important;
    color: #ffffff;
}

.btn-upload:focus,
.btn-upload:active,
.btn-upload.active,
.btn-upload.hover {
    background-color: #1aa0c3 !important;
    outline: none !important;
    box-shadow: none !important;
    border: 0px solid #0a2737 !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
    background-color: #43c5e6;
    outline: none !important;
    box-shadow: none !important;
    border-color: #061821 !important;
}

.btn-primary-dark:focus,
.btn-primary-dark:active,
.btn-primary-dark.active,
.btn-primary-dark.focus,
.btn-primary-dark:active,
.btn-primary-dark:focus,
.btn-primary-dark:hover,
.open > .dropdown-toggle.btn-primary-dark {
    background-color: #43c5e6;
    outline: none !important;
    box-shadow: none !important;
    border-color: #061821 !important;
}

.btn-success {
    background-color: #4acf95 !important;
    border: 1px solid #4acf95 !important;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
    background-color: #36ca89 !important;
    border-color: #36ca89 !important;
}

.btn-info {
    background-color: #ea624c !important;
    border: 0px solid #45bbe0 !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
    background-color: #2fb3dc !important;
    border-color: #2fb3dc !important;
}

.btn-warning {
    background-color: #ff9800 !important;
    border: 1px solid #ff9800 !important;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
    background-color: #e68900 !important;
    border-color: #e68900 !important;
}

.btn-danger {
    background-color: #f7531f !important;
    border: 1px solid #f7531f !important;
}

.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
    background-color: #f44109 !important;
    border-color: #f44109 !important;
}

.btn-inverse {
    background-color: #3b3e47 !important;
    border: 1px solid #3b3e47 !important;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
    background-color: #2f3239 !important;
    border-color: #2f3239 !important;
}

.btn-purple {
    background-color: #8892d6 !important;
    border: 1px solid #8892d6 !important;
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
    background-color: #7581cf !important;
    border-color: #7581cf !important;
}

.btn-pink {
    background-color: #ec1d45 !important;
    border: 1px solid #ec1d45 !important;
}

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
    background-color: #dd133a !important;
    border-color: #dd133a !important;
}

.btn-bordered {
    background-color: transparent !important;
}

.btn-bordered.btn-default {
    background-color: #ffffff;
    border: 2px solid rgba(150, 150, 150, 0.2) !important;
}

.btn-bordered.btn-custom {
    color: #43c5e6 !important;
    border: 2px solid #43c5e6 !important;
}

.btn-bordered.btn-custom:hover,
.btn-bordered.btn-custom:focus,
.btn-bordered.btn-custom:active {
    background-color: #43c5e6;
    color: #ffffff !important;
    border: 2px solid #23bbe2 !important;
}

.btn-bordered.btn-primary, .btn-bordered.btn-primary-dark {
    color: #0a2737 !important;
    border: 2px solid #0a2737 !important;
}

.btn-bordered.btn-primary:hover,
.btn-bordered.btn-primary:focus,
.btn-bordered.btn-primary:active {
    background-color: #0a2737;
    color: #ffffff !important;
    border: 2px solid #051219 !important;
}
.btn-bordered.btn-primary-dark:hover,
.btn-bordered.btn-primary-dark:focus,
.btn-bordered.btn-primary-dark:active {
    background-color: #0a2737;
    color: #ffffff !important;
    border: 2px solid #051219 !important;
}

.btn-bordered.btn-success {
    color: #4acf95 !important;
    border: 2px solid #4acf95 !important;
}

.btn-bordered.btn-success:hover,
.btn-bordered.btn-success:focus,
.btn-bordered.btn-success:active {
    background-color: #4acf95;
    color: #ffffff !important;
    border: 2px solid #33c284 !important;
}

.btn-bordered.btn-info {
    color: #45bbe0 !important;
    border: 2px solid #45bbe0 !important;
}

.btn-bordered.btn-info:hover,
.btn-bordered.btn-info:focus,
.btn-bordered.btn-info:active {
    background-color: #45bbe0;
    color: #ffffff !important;
    border: 2px solid #26b0db !important;
}

.btn-bordered.btn-warning {
    color: #ff9800 !important;
    border: 2px solid #ff9800 !important;
}

.btn-bordered.btn-warning:hover,
.btn-bordered.btn-warning:focus,
.btn-bordered.btn-warning:active {
    background-color: #ff9800;
    color: #ffffff !important;
    border: 2px solid #db8300 !important;
}

.btn-bordered.btn-danger {
    color: #f7531f !important;
    border: 2px solid #f7531f !important;
}

.btn-bordered.btn-danger:hover,
.btn-bordered.btn-danger:focus,
.btn-bordered.btn-danger:active {
    background-color: #f7531f;
    color: #ffffff !important;
    border: 2px solid #ea3f08 !important;
}

.btn-bordered.btn-inverse {
    color: #3b3e47 !important;
    border: 2px solid #3b3e47 !important;
}

.btn-bordered.btn-inverse:hover,
.btn-bordered.btn-inverse:focus,
.btn-bordered.btn-inverse:active {
    background-color: #3b3e47;
    color: #ffffff !important;
    border: 2px solid #2b2d34 !important;
}

.btn-bordered.btn-purple {
    color: #8892d6 !important;
    border: 2px solid #8892d6 !important;
}

.btn-bordered.btn-purple:hover,
.btn-bordered.btn-purple:focus,
.btn-bordered.btn-purple:active {
    background-color: #8892d6;
    color: #ffffff !important;
    border: 2px solid #6d7acd !important;
}

.btn-bordered.btn-pink {
    color: #ec1d45 !important;
    border: 2px solid #ec1d45 !important;
}

.btn-bordered.btn-pink:hover,
.btn-bordered.btn-pink:focus,
.btn-bordered.btn-pink:active {
    background-color: #ec1d45;
    color: #ffffff !important;
    border: 2px solid #d41237 !important;
}

.btn-rounded {
    border-radius: 2em;
}

/* ===========
   Panels
 =============*/
.panel {
    border: 1px solid #edf1f1;
    box-shadow: none;
    margin-bottom: 20px;
}

.panel .panel-body {
    padding: 5px;
}

.panel .panel-body p {
    margin-bottom: 0;
    line-height: 24px;
}

.panel .panel-body p + p {
    padding-top: 10px;
}

.panel-heading {
    border: none !important;
    padding: 15px 20px;
    margin: -1px;
    border-radius: 4px 4px 0 0;
}

.panel-default > .panel-heading {
    background-color: #ffffff;
    margin: 0;
    color: #f1f6f6;
}

.panel-title {
    font-weight: 700;
    margin: 0;
    font-family: 'vugo', sans-serif;
}

.panel-sub-title {
    color: rgba(255, 255, 255, 0.7) !important;
    margin-top: 3px;
    font-size: 14px;
    margin-bottom: -4px;
}

.panel-footer {
    background: #ffffff;
}

.panel-default .panel-sub-title {
    color: inherit !important;
}

.panel-color .panel-title {
    color: #ffffff;
}

.panel-custom > .panel-heading {
    background-color: #43c5e6;
}

.panel-primary > .panel-heading {
    background-color: #0a2737;
}

.panel-success > .panel-heading {
    background-color: #4acf95;
}

.panel-info > .panel-heading {
    background-color: #45bbe0;
}

.panel-warning > .panel-heading {
    background-color: #ff9800;
}

.panel-danger > .panel-heading {
    background-color: #f7531f;
}

.panel-purple > .panel-heading {
    background-color: #8892d6;
}

.panel-pink > .panel-heading {
    background-color: #ec1d45;
}

.panel-inverse > .panel-heading {
    background-color: #3b3e47;
}

.panel-border {
    border-radius: 3px;
}

.panel-border .panel-heading {
    background-color: #ffffff;
    border-top: 3px solid #ccc !important;
    border-radius: 3px;
    padding: 15px 20px 0;
    margin: -2px 0 0 0 !important;
}

.panel-border .panel-body {
    padding: 15px 20px 20px 20px;
}

.panel-border.panel-primary .panel-heading {
    border-color: #0a2737 !important;
    color: #0a2737 !important;
}

.panel-border.panel-success .panel-heading {
    border-color: #4acf95 !important;
    color: #4acf95 !important;
}

.panel-border.panel-info .panel-heading {
    border-color: #45bbe0 !important;
    color: #45bbe0 !important;
}

.panel-border.panel-warning .panel-heading {
    border-color: #ff9800 !important;
    color: #ff9800 !important;
}

.panel-border.panel-danger .panel-heading {
    border-color: #f7531f !important;
    color: #f7531f !important;
}

.panel-border.panel-purple .panel-heading {
    border-color: #8892d6 !important;
    color: #8892d6 !important;
}

.panel-border.panel-pink .panel-heading {
    border-color: #ec1d45 !important;
    color: #ec1d45 !important;
}

.panel-border.panel-inverse .panel-heading {
    border-color: #3b3e47 !important;
    color: #3b3e47 !important;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: 0 !important;
}

/* =============
   Checkbox and Radios
============= */
.checkbox label,
.radio label {
    padding-left: 25px !important;
    font-size: 15px;
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: -25px;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 11px;
    line-height: 0;
    top: 9px;
    left: 3px;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none !important;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    opacity: 1;
}

.checkbox-custom label input[type="checkbox"]:checked + .cr {
    background-color: #43c5e6;
    border-color: #43c5e6;
    color: #ffffff;
}

.checkbox-primary label input[type="checkbox"]:checked + .cr {
    background-color: #0a2737;
    border-color: #0a2737;
    color: #ffffff;
}

.checkbox-success label input[type="checkbox"]:checked + .cr {
    background-color: #4acf95;
    border-color: #4acf95;
    color: #ffffff;
}

.checkbox-info label input[type="checkbox"]:checked + .cr {
    background-color: #45bbe0;
    border-color: #45bbe0;
    color: #ffffff;
}

.checkbox-warning label input[type="checkbox"]:checked + .cr {
    background-color: #ff9800;
    border-color: #ff9800;
    color: #ffffff;
}

.checkbox-danger label input[type="checkbox"]:checked + .cr {
    background-color: #f7531f;
    border-color: #f7531f;
    color: #ffffff;
}

.checkbox-inverse label input[type="checkbox"]:checked + .cr {
    background-color: #3b3e47;
    border-color: #3b3e47;
    color: #ffffff;
}

.checkbox-pink label input[type="checkbox"]:checked + .cr {
    background-color: #ec1d45;
    border-color: #ec1d45;
    color: #ffffff;
}

.checkbox-purple label input[type="checkbox"]:checked + .cr {
    background-color: #8892d6;
    border-color: #8892d6;
    color: #ffffff;
}

.radio-primary label input[type="radio"]:checked + .cr {
    border-color: #0a2737;
    color: #0a2737;
}

.radio-success label input[type="radio"]:checked + .cr {
    border-color: #4acf95;
    color: #4acf95;
}

.radio-info label input[type="radio"]:checked + .cr {
    border-color: #45bbe0;
    color: #45bbe0;
}

.radio-warning label input[type="radio"]:checked + .cr {
    border-color: #ff9800;
    color: #ff9800;
}

.radio-danger label input[type="radio"]:checked + .cr {
    border-color: #f7531f;
    color: #f7531f;
}

.radio-custom label input[type="radio"]:checked + .cr {
    border-color: #43c5e6;
    color: #43c5e6;
}

.radio-inverse label input[type="radio"]:checked + .cr {
    border-color: #3b3e47;
    color: #3b3e47;
}

.radio-pink label input[type="radio"]:checked + .cr {
    border-color: #ec1d45;
    color: #ec1d45;
}

.radio-purple label input[type="radio"]:checked + .cr {
    border-color: #8892d6;
    color: #8892d6;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

/* =============
   Modals
============= */

.modal .modal-dialog .modal-content .modal-header, .modal .modal-dialog .modal-content .modal-header-dark {
    border-bottom-width: 2px;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
}

.modal .modal-dialog .modal-content .modal-footer {
    padding: 0;
    padding-top: 15px;
}

.modal-full {
    width: 98%;
}

.modal-content .nav.nav-tabs + .tab-content, .modal-content .nav.nav-tabs-dark + .tab-content {
    margin-bottom: 0;
}

.modal-content .panel-group {
    margin-bottom: 0;
}

.modal-content .panel {
    border-top: none;
}

/* Custom-modal */
.modal-demo {
    background-color: #ffffff;
    width: 600px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    background-clip: padding-box;
    display: none;
}

.modal-demo .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: #edf1f1;
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: #969696;
    color: #ffffff;
    text-align: left;
    margin: 0;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
    top: 20px;
    z-index: 9999;
}

/* =============
   Tabs
============= */
.tab-content {
    padding: 20px 0 0 0;
}

ul.nav-tabs, ul.nav-tabs-dark {
    display: inline-block;
    position: relative;
    margin: 0px auto;
    border: none;
}

.nav-tabs > li, .nav-tabs-dark > li {
    display: inline-block;
}

.nav-tabs > li > a {
    color: #0a2737;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
}
.nav-tabs-dark > li > a {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    font-size: 13px;
}

.nav-tabs > li > a:hover, .nav-tabs-dark > li > a:hover {
    background-color: rgba(152, 166, 173, 0.1);
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    color: #43c5e6;
}
.nav-tabs-dark > li.active > a,
.nav-tabs-dark > li.active > a:focus,
.nav-tabs-dark > li.active > a:hover {
    color: #43c5e6;
}

/* Vertial tab */
.tabs-vertical-env .tab-content {
    background: #ffffff;
    display: table-cell;
    padding: 0 0 0 20px;
    vertical-align: top;
}

.tabs-vertical-env .nav.tabs-vertical {
    display: table-cell;
    min-width: 120px;
    vertical-align: top;
    width: 150px;
}

.tabs-vertical-env .nav.tabs-vertical li > a {
    color: #969696;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    text-transform: uppercase;
}

.tabs-vertical-env .nav.tabs-vertical li.active > a {
    background-color: rgba(152, 166, 173, 0.1);
    border: 0;
    color: #43c5e6;
}

.tabs-vertical-env-right .tab-content {
    padding: 0 20px 0 0;
}

.tabs-bordered {
    border-bottom: 2px solid rgba(152, 166, 173, 0.2) !important;
}

.tabs-bordered li a,
.tabs-bordered li a:hover,
.tabs-bordered li a:focus {
    border: 0 !important;
    background-color: #ffffff !important;
    padding: 10px 20px !important;
}

.tabs-bordered li.active a,
.tabs-bordered li.active a:hover,
.tabs-bordered li.active a:focus {
    border-bottom: 2px solid #43c5e6 !important;
    margin-bottom: -1px;
    color: #43c5e6;
}

/* Navpills */
.nav-pills > li > a {
    color: #969696;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    background-color: #43c5e6;
}

/* =============
   Progressbars
============= */
.progress {
    -webkit-box-shadow: none !important;
    background-color: #f1f6f6;
    box-shadow: none !important;
    height: 10px;
    margin-bottom: 18px;
    overflow: hidden;
}

.progress-bar {
    box-shadow: none;
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
}

.progress.progress-sm {
    height: 5px !important;
}

.progress.progress-sm .progress-bar {
    font-size: 8px;
    line-height: 5px;
}

.progress.progress-md {
    height: 15px !important;
}

.progress.progress-md .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
}

.progress.progress-lg {
    height: 20px !important;
}

.progress.progress-lg .progress-bar {
    font-size: 12px;
    line-height: 20px;
}

.progress-bar-primary {
    background-color: #0a2737;
}

.progress-bar-success {
    background-color: #4acf95;
}

.progress-bar-info {
    background-color: #45bbe0;
}

.progress-bar-warning {
    background-color: #ff9800;
}

.progress-bar-danger {
    background-color: #f7531f;
}

.progress-bar-inverse {
    background-color: #3b3e47;
}

.progress-bar-purple {
    background-color: #8892d6;
}

.progress-bar-pink {
    background-color: #ec1d45;
}

.progress-bar-custom {
    background-color: #43c5e6;
}

/* Progressbar Vertical */
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
}

.progress-vertical .progress-bar {
    width: 100%;
}

.progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
}

.progress-vertical-bottom .progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
    width: 5px !important;
}

.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
    font-size: 8px;
    line-height: 5px;
}

.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
    width: 15px !important;
}

.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
}

.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
    width: 20px !important;
}

.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
    font-size: 12px;
    line-height: 20px;
}

/* =============
   Alerts
============= */
.alert {
    position: relative;
}

.alert .alert-link {
    font-weight: 600;
}

.alert-dismissable .close,
.alert-dismissible .close {
    opacity: 0.9;
}

.alert-icon {
    padding-left: 50px;
}

.alert-icon i {
    position: absolute;
    left: 0;
    height: 50px;
    width: 50px;
    text-align: center;
    top: 0;
    line-height: 50px;
    font-size: 22px;
}

.alert-success {
    color: #4acf95;
    background-color: #ebfaf3;
    border-color: #9be4c4;
}

.alert-success .alert-link {
    color: #30b67c;
}

.alert-success hr {
    border-top-color: #30b67c;
}

.alert-info {
    color: #45bbe0;
    background-color: #def3fa;
    border-color: #9cdbef;
}

.alert-info .alert-link {
    color: #23a6cf;
}

.alert-info hr {
    border-top-color: #23a6cf;
}

.alert-warning {
    color: #ff9800;
    background-color: #fff5e5;
    border-color: #ffd699;
}

.alert-warning .alert-link {
    color: #cc7a00;
}

.alert-warning hr {
    border-top-color: #cc7a00;
}

.alert-danger {
    color: #f7531f;
    background-color: #fef2ee;
    border-color: #fb9f81;
}

.alert-danger .alert-link {
    color: #db3b08;
}

.alert-danger hr {
    border-top-color: #db3b08;
}

.alert-white {
    background-color: #ffffff !important;
}

.jq-toast-single {
    border-radius: 0;
    font-family: 'vugolight', sans-serif;
    background-color: #969696;
    font-size: 14px;
}

.jq-toast-single h2 {
    font-family: 'vugo', sans-serif;
}

.jq-toast-single a {
    font-size: 14px;
}

.jq-toast-single a:hover {
    color: #43c5e6;
}

.jq-icon-info {
    background-color: #45bbe0;
    color: #ffffff;
    border-color: #45bbe0;
}

.jq-icon-success {
    background-color: #4acf95;
    color: #ffffff;
    border-color: #4acf95;
}

.jq-icon-warning {
    background-color: #ff9800;
    color: #ffffff;
    border-color: #ff9800;
}

.jq-icon-error {
    background-color: #f7531f;
    color: #ffffff;
    border-color: #f7531f;
}

.close-jq-toast-single {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 20px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-color: #969696;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
}

.jq-toast-loader {
    height: 3px;
    border-radius: 0;
}

/* =============
   Carousel
============= */
.carousel-control {
    width: 10%;
}

.carousel-control span {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    font-size: 30px;
}

/* =========== */
/* Sweet Alert */
/* =========== */
.swal2-modal {
    font-family: 'vugolight', sans-serif;
}

.swal2-modal .swal2-title {
    font-size: 28px;
}

.swal2-modal .swal2-content {
    font-size: 16px;
}

.swal2-modal .swal2-spacer {
    margin: 10px 0;
}

.swal2-modal .swal2-file,
.swal2-modal .swal2-input,
.swal2-modal .swal2-textarea {
    border: 2px solid #98a6ad;
    font-size: 16px;
    box-shadow: none !important;
}

.swal2-icon.swal2-question {
    color: #43c5e6;
    border-color: #43c5e6;
}

.swal2-icon.swal2-success {
    border-color: #4acf95;
}

.swal2-icon.swal2-success .line {
    background-color: #4acf95;
}

.swal2-icon.swal2-success .placeholder {
    border-color: #4acf95;
}

.swal2-icon.swal2-warning {
    color: #ff9800;
    border-color: #ff9800;
}

.swal2-icon.swal2-error {
    border-color: #f7531f;
}

.swal2-icon.swal2-error .line {
    background-color: #f7531f;
}

.swal2-modal .swal2-file:focus,
.swal2-modal .swal2-input:focus,
.swal2-modal .swal2-textarea:focus {
    outline: 0;
    border: 2px solid #43c5e6;
}

/* =============
   Widgets
============= */
.widget-box-one .widget-one-icon {
    position: absolute;
    right: 30px;
    font-size: 72px !important;
    top: 0;
    color: #f1f6f6;
    overflow: hidden;
    vertical-align: middle;
    line-height: 2 !important;
}

.widget-box-one .wigdet-one-content {
    position: relative;
}

.widget-box-two p {
    font-size: 14px;
}

.widget-box-two .widget-two-icon {
    position: absolute;
    right: 20px;
    font-size: 24px !important;
    top: 15px;
    overflow: hidden;
    vertical-align: middle;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    color: #43c5e6;
    border: 2px solid #43c5e6;
}

.widget-box-two .wigdet-two-content {
    position: relative;
}

.widget-two-custom {
    background-color: #43c5e6;
    border-color: #43c5e6;
}

.widget-two-primary {
    background-color: #ffffff;
    border-color: #43c5e6;
}

.widget-two-success {
    background-color: #4acf95;
    border-color: #4acf95;
}

.widget-two-warning {
    background-color: #ff9800;
    border-color: #ff9800;
}

.widget-two-info {
    background-color: #43c5e6;
    border-color: #43c5e6;
}

.widget-two-trips {
    background-color: #ffffff;
    border-color: #43c5e6;
}

.widget-two-trips .widget-two-icon {
    border-color: #43c5e6;
    color: #43c5e6;
}

.widget-two-requests {
    background-color: #ffffff;
    border-color: #f26624;
}

.widget-two-requests .widget-two-icon {
    border-color: #f26624;
    color: #f26624;
}

.widget-two-referals {
    background-color: #ffffff;
    border-color: #af77cf;
}

.widget-two-referals .widget-two-icon {
    border-color: #af77cf;
    color: #af77cf;
}

.widget-two-danger {
    background-color: #f7531f;
    border-color: #f7531f;
}

.widget-two-inverse {
    background-color: #3b3e47;
    border-color: #3b3e47;
}

.widget-two-purple {
    background-color: #8892d6;
    border-color: #8892d6;
}

.widget-two-pink {
    background-color: #ec1d45;
    border-color: #ec1d45;
}

.widget-two-default {
    background-color: #ffffff;
}

.widget-two-default .widget-two-icon {
    color: #43c5e6;
    border: 2px solid #43c5e6;
}

.widget-box-three img {
    height: 48px;
    width: 48px;
    line-height: 80px;
}

.widget-box-three .bg-icon {
    height: 80px;
    width: 80px;
    line-height: 76px;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-border-radius: 50%;
    background-clip: padding-box;
    border: 1px dashed #98a6ad;
    background-color: #f1f6f6;
    margin-right: 20px;
}

.widget-box-three .bg-icon i {
    vertical-align: middle;
    font-size: 36px;
    color: #98a6ad;
}

.widget-user {
    position: relative;
}

.widget-user img {
    width: 60px;
    height: 60px;
}

.user-position {
    position: absolute;
    top: 0;
    border-left: 1px solid #edf1f1;
    bottom: 0;
    width: 44px;
    font-size: 16px;
    text-align: center;
    right: 0;
    left: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.user-position span {
    transform: rotate(90deg);
}

/* Avatar box */
.avatar-sm-box {
    height: 32px;
    width: 32px;
    color: #ffffff;
    display: block;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    font-family: 'vugo', sans-serif;
    font-size: 16px;
}

/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
    background: #f1f6f6;
    border: 1px solid #d1d7da;
    padding: 10px 16px;
    height: auto;
    font-size: 16px;
    font-weight: normal;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
}

.custom-dd .dd-list .dd-item .dd-handle:hover {
    color: #43c5e6;
}

.custom-dd .dd-list .dd-item button {
    height: auto;
    font-size: 25px;
    margin: 8px auto;
    color: #f1f6f6;
    width: 35px;
}

.custom-dd-empty .dd-list .dd3-handle {
    border: none;
    background: #f1f6f6;
    height: 36px !important;
    width: 36px !important;
}

.custom-dd-empty .dd-list .dd3-handle:before {
    color: inherit;
    top: 7px;
}

.custom-dd-empty .dd-list .dd3-handle:hover {
    color: #43c5e6;
}

.custom-dd-empty .dd-list .dd3-content {
    height: auto;
    border: none;
    padding: 8px 16px 8px 46px;
    background: #f1f6f6;
    font-family: 'vugo', sans-serif;
}

.custom-dd-empty .dd-list .dd3-content:hover {
    color: #43c5e6;
}

.custom-dd-empty .dd-list button {
    width: 26px;
    height: 26px;
    font-size: 16px;
    font-weight: 600;
}

.dd-dragel > .dd-item .dd-handle {
    padding: 8px 16px !important;
    background: #f1f6f6;
    height: auto;
}

.dd-placeholder,
.dd-empty {
    background: #f1f6f6;
    border: 1px dashed #98a6ad;
}

.dd-dragel > .dd3-item > .dd3-handle {
    border: none;
    background: #f1f6f6;
    height: 36px !important;
    width: 36px !important;
}

.dd-dragel > .dd3-item > .dd3-handle:before {
    color: inherit;
    top: 7px;
}

.dd-dragel > .dd3-item > .dd3-content {
    padding: 8px 16px 8px 46px;
    background: #f1f6f6;
    height: auto;
}

/* =============
   Rating
============= */
.rating-md i {
    font-size: 18px;
}

.rating-lg i {
    font-size: 24px;
}

/* =============
   Calendar
============= */
.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-view {
    margin-top: 30px;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar {
    margin-bottom: 5px;
    margin-top: 15px;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
    font-family: 'vugo', sans-serif;
}

.fc-day {
    background: #ffffff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc th.fc-widget-header {
    background: #edf1f1;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #edf1f1;
}

.fc-button {
    background: #edf1f1;
    border: none;
    color: #f1f6f6;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}

.fc-state-hover {
    background: #f1f6f6;
}

.fc-state-highlight {
    background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #43c5e6 !important;
    color: #ffffff !important;
    text-shadow: none !important;
}

.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #ffffff;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 13px;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.external-event {
    cursor: move;
    margin: 10px 0;
    padding: 6px 10px;
}

.external-event.bg-primary {
    background-color: rgba(10, 39, 55, 0.3) !important;
    color: #0a2737;
}

.external-event.bg-success {
    background-color: rgba(74, 207, 149, 0.3) !important;
    color: #4acf95;
}

.external-event.bg-info {
    background-color: rgba(69, 187, 224, 0.3) !important;
    color: #45bbe0;
}

.external-event.bg-warning {
    background-color: rgba(255, 152, 0, 0.3) !important;
    color: #ff9800;
}

.external-event.bg-danger {
    background-color: rgba(247, 83, 31, 0.3) !important;
    color: #f7531f;
}

.external-event.bg-pink {
    background-color: rgba(236, 29, 69, 0.3) !important;
    color: #ec1d45;
}

.external-event.bg-purple {
    background-color: rgba(136, 146, 214, 0.3) !important;
    color: #8892d6;
}

.external-event.bg-inverse {
    background-color: rgba(59, 62, 71, 0.3) !important;
    color: #3b3e47;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: 'vugo', sans-serif;
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: 'vugo', sans-serif;
}

/* =============
   Form
============= */
/* Form components */
label {
    font-weight: 600;
    font-size: 14px;
    font-family: 'vugo', sans-serif;
}

.form-control, .form-control-dark {
    border: 1px solid #969696;
    border-radius: 4px;
    padding: 7px 12px;
    height: 38px;
    max-width: 100%;
    font-size: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}

.form-control:focus, .form-control-dark:focus {
    border: 1px solid #b4c4c4;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
}

textarea.form-control, textarea.form-control-dark {
    min-height: 90px;
    border: solid 1px #969696;
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.3333333;
    border-radius: 4px;
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.form-horizontal .form-group {
    margin-left: -10px;
    margin-right: -10px;
}

.form-control-feedback {
    line-height: 38px !important;
}

.input-group-btn .btn {
    padding: 7px 12px;
    height: 38px;
}

.input-group-btn .btn-sm {
    padding: 5px 10px;
}

.input-group-btn .btn-lg {
    padding: 10px 17px;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label,
.has-success .form-control-feedback {
    color: #4acf95;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label,
.has-warning .form-control-feedback {
    color: #ff9800;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label,
.has-error .form-control-feedback {
    color: #f7531f;
}

.has-success .form-control, .has-success .form-control-dark {
    border-color: #4acf95;
    box-shadow: none !important;
}

.has-warning .form-control, .has-warning .form-control-dark {
    border-color: #ff9800;
    box-shadow: none !important;
}

.has-error .form-control, .has-error .form-control-dark {
    border-color: #f7531f;
    box-shadow: none !important;
}

.input-group-addon {
    border-radius: 2px;
    border: 1px solid #e4eaea;
    background-color: #e4eaea;
}

/* == Form Advanced */
/* Bootstrap tagsinput */
.bootstrap-tagsinput {
    box-shadow: none;
    padding: 0 7px 0;
    width: 100%;
    margin: 10px 0;
    border: 1px solid #e3e3e3;
}

.bootstrap-tagsinput-dark {
    box-shadow: none;
    padding: 0 7px 0;
    width: 100%;
    margin: 10px 0;
    border: 1px solid #e3e3e3;
}

.bootstrap-tagsinput .label-info {
    background-color: #43c5e6 !important;
    display: inline-block;
    padding: 5px;
    margin: 3px 1px;
}

.bootstrap-tagsinput-dark .label-info {
    background-color: #43c5e6 !important;
    display: inline-block;
    padding: 5px;
    margin: 3px 1px;
}

/* Select 2 */
.select2-container {
    width: 100% !important;
}

.select2-container .select2-selection--single {
    border: 1px solid #E3E3E3 !important;
    height: 38px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 36px !important;
    padding-left: 12px !important;
}

.select2-container .select2-selection--single .select2-selection__arrow {
    height: 34px;
    width: 34px;
    right: 3px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
    border-color: #999 transparent transparent transparent;
    border-width: 6px 6px 0 6px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #999 transparent !important;
    border-width: 0 6px 6px 6px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #43c5e6;
}

.select2-results__option {
    padding: 6px 12px;
}

.select2-dropdown {
    border: 1px solid #e3e3e3 !important;
    padding-top: 5px;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.select2-search input {
    border: 1px solid #e3e3e3 !important;
}

.select2-container .select2-selection--multiple {
    min-height: 38px !important;
    border: 1px solid #e3e3e3 !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 2px 10px;
}

.select2-container .select2-selection--multiple .select2-search__field {
    margin-top: 7px;
    border: 0 !important;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 1px;
    padding: 0 7px;
}

/* AUTOCOMPLETE */
.autocomplete-suggestions {
    border: 1px solid #f9f9f9;
    background: #ffffff;
    cursor: default;
    overflow: auto;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.autocomplete-suggestion {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-no-suggestion {
    padding: 2px 5px;
}

.autocomplete-selected {
    background: rgba(152, 166, 173, 0.3);
    cursor: pointer;
}

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #969696;
}

.autocomplete-group {
    padding: 2px 5px;
}

.autocomplete-group strong {
    font-weight: bold;
    font-size: 16px;
    color: #969696;
    display: block;
}

/* Bootstrap-select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100% !important;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
}

.bootstrap-select .glyphicon {
    padding-right: 6px;
}

/* Bootstrap filestyle */
.icon-span-filestyle {
    padding-right: 5px;
}

/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
    padding: 9px 12px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
    top: 4px;
    left: 8px;
}

/* Form validation */
.parsley-error {
    border-color: #f7531f !important;
}

.parsley-errors-list {
    display: none;
    margin: 0;
    padding: 0;
}

.parsley-errors-list > li {
    font-size: 12px;
    list-style: none;
    color: #f7531f;
    margin-top: 5px;
}

.bootstrap-timepicker-widget table td input {
    border: 1px solid rgba(150, 150, 150, 0.3);
    width: 35px;
}

.datepicker-dropdown {
    padding: 10px !important;
}

.datepicker td,
.datepicker th {
    width: 30px;
    height: 30px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #43c5e6 !important;
    color: #ffffff !important;
    background-image: none !important;
    text-shadow: none !important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #4acf95 !important;
    color: #ffffff !important;
    background-image: none !important;
}

.datepicker-inline {
    border: 2px solid rgba(150, 150, 150, 0.1);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #43c5e6;
}

.daterangepicker .input-mini.active {
    border: 1px solid rgba(150, 150, 150, 0.3);
}

.daterangepicker .ranges li {
    border-radius: 2px;
    color: #969696;
    font-weight: 600;
    font-size: 12px;
    font-family: 'vugo', sans-serif;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
    border: 1px solid rgba(150, 150, 150, 0.3);
    padding: 2px;
    width: 60px;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    background-color: #43c5e6;
    border: 1px solid #43c5e6;
    color: #ffffff;
}

/* Wizard */
.wizard > .content {
    background: #ffffff;
    min-height: 240px;
    padding: 20px !important;
    border: 1px solid #f1f6f6;
    margin-top: 10px !important;
}

.wizard > .content > .body {
    padding: 0px;
    position: relative;
    width: 100%;
}

.wizard > .content > .body input {
    border: 1px solid #e5eeee;
}

.wizard > .content > .body ul > li {
    display: block;
    line-height: 30px;
}

.wizard > .content > .body label.error {
    color: #f7531f;
    margin-left: 0;
    margin-top: 5px;
    font-size: 12px;
}

.wizard > .content > .body label {
    display: inline-block;
    margin-top: 10px;
}

.wizard > .steps a {
    font-size: 16px;
    text-align: center;
    font-family: 'vugo', sans-serif;
}

.wizard > .steps .number {
    font-size: 74px;
    line-height: 86px;
    font-family: 'vugo', sans-serif;
    position: absolute;
    left: 10px;
    top: -14px;
    opacity: 0.1;
}

.wizard > .steps .disabled a {
    background: #f1f6f6;
    color: #a3a3a3 !important;
    cursor: default;
    border: 1px solid #e1ecec;
}

.wizard > .steps .disabled a:hover {
    background: #e1ecec;
    border: 1px solid #d2e2e2;
}

.wizard > .steps .disabled a:active {
    background: #e1ecec;
    border: 1px solid #d2e2e2;
}

.wizard > .steps .current a {
    background: #43c5e6;
}

.wizard > .steps .current a:hover {
    background: #43c5e6;
}

.wizard > .steps .current a:hover .number {
    color: #ffffff;
    opacity: 0.4;
}

.wizard > .steps .current a:active {
    background: #43c5e6;
}

.wizard > .steps .current a:active .number {
    color: #ffffff;
    opacity: 0.4;
}

.wizard > .steps .current a .number {
    color: #ffffff;
    opacity: 0.4;
}

.wizard > .steps .done a {
    background: #f1f6f6 !important;
    color: #a3a3a3 !important;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active,
.wizard > .content {
    border-radius: 2px;
    position: relative;
}

.wizard > .actions {
    margin-top: 15px !important;
}

.wizard > .actions a {
    background: #43c5e6;
    border-radius: 2px !important;
    color: #ffffff;
}

.wizard > .actions a:hover {
    background: #1aa0c3;
    color: #ffffff;
}

.wizard > .actions a:active {
    background: #1aa0c3;
    color: #ffffff;
}

.wizard > .actions .disabled a {
    background: #ffffff;
    color: #969696;
    cursor: default;
    border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:hover {
    background: #f1f6f6;
    color: #969696;
    cursor: default;
    border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:active {
    background: #f1f6f6;
    color: #969696;
    cursor: default;
    border: 1px solid #eaeaea;
}

.wizard.vertical > .content {
    margin-top: 0px !important;
}

@media (max-width: 560px) {
    .wizard.vertical > .steps,
    .wizard.vertical > .content {
        width: 100%;
    }
}

/* Summernote */
.note-editor {
    position: relative;
}

.note-editor .btn-default {
    background-color: transparent;
    border-color: transparent !important;
}

.note-editor .btn-group-sm > .btn,
.note-editor .btn-sm {
    padding: 8px 12px !important;
}

.note-editor .note-toolbar {
    background-color: #f1f6f6;
    border-bottom: 1px solid #edf1f1;
    margin: 0;
}

.note-editor .note-statusbar {
    background-color: #ffffff;
}

.note-editor .note-statusbar .note-resizebar {
    border-top: none;
    height: 15px;
    padding-top: 3px;
}

.note-editor.note-frame {
    border: 1px solid #edf1f1 !important;
}

.note-popover .popover .popover-content {
    padding: 5px 0 10px 5px;
}

.note-popover .btn-default {
    background-color: transparent;
    border-color: transparent !important;
}

.note-popover .btn-group-sm > .btn,
.note-popover .btn-sm {
    padding: 8px 12px !important;
}

.note-toolbar {
    padding: 5px 0 10px 5px;
}

/* Wysiwig css */
.mce-panel {
    border: 1px solid #f1f6f6 !important;
    background-color: #f1f6f6 !important;
}

.mce-menu {
    background-color: #ffffff !important;
    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}

.mce-menubar .mce-menubtn:hover,
.mce-menubar .mce-menubtn.mce-active,
.mce-menubar .mce-menubtn:focus {
    border-color: #f1f6f6 !important;
}

.mce-menu-item:hover,
.mce-menu-item.mce-selected,
.mce-menu-item:focus,
.mce-menu-item-normal.mce-active,
.mce-primary {
    background-color: #43c5e6 !important;
}

.mce-window-head .mce-title {
    font-family: 'vugo', sans-serif;
}

.mce-window {
    padding: 20px !important;
}

.mce-window-head {
    padding-bottom: 20px !important;
    border-bottom: 0 !important;
}

/* == Form Uploads == */
.jFiler-input-dragDrop {
    width: 100%;
    background-color: #fafafa;
}

.jFiler-theme-default .jFiler-input {
    width: 100%;
}

.jFiler-theme-default .jFiler-input-button {
    background-color: #43c5e6;
    background-image: none !important;
    color: #ffffff;
    border: 1px solid #43c5e6 !important;
}

/* =============
   Tables
============= */
.buttontd {
    width: 19%;
}

th {
    font-family: 'vugo', sans-serif;
    font-weight: normal;
    font-size: 13px;
    padding: 12px !important;
}

.table-centered td {
    vertical-align: middle !important;
}

/* Data table */
div.dt-button-info {
    background-color: #43c5e6;
    border: none;
    color: #ffffff;
    box-shadow: none;
    border-radius: 3px;
    text-align: center;
    z-index: 21;
}

div.dt-button-info h2 {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

table.dataTable {
    margin-top: 10px !important;
    margin-bottom: 18px !important;
}

.table-bordered.dataTable > thead > tr > td,
.table-bordered.dataTable > thead > tr > th {
    border-bottom-width: 1px !important;
}

/* Fixed Header table */
.fixedHeader-floating {
    top: 70px !important;
}

/* Key Table border */
table.dataTable th.focus,
table.dataTable td.focus {
    outline: 2px solid #43c5e6 !important;
    outline-offset: -1px;
    background-color: #43c5e6;
    color: #ffffff;
}

/* Responsive data table */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    box-shadow: 0 0 3px rgba(67, 89, 102, 0.2);
    background-color: #4acf95;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    background-color: #f7531f;
}

table.dataTable > tbody > tr.child span.dtr-title {
    font-family: 'vugo', sans-serif;
}

/* ColVid Tables */
div.ColVis {
    float: none;
    margin-right: 30px;
}

button.ColVis_Button,
.ColVis_Button:hover {
    float: none;
    border-radius: 3px;
    outline: none !important;
    background: none;
    box-shadow: none;
    color: #ffffff !important;
    background-color: #43c5e6 !important;
    border: 1px solid #43c5e6 !important;
}

.dataTables_length {
    float: left;
}

div.ColVis_collectionBackground {
    background-color: transparent;
}

ul.ColVis_collection {
    padding: 10px 0 0 0;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border: none;
}

ul.ColVis_collection li {
    background: transparent !important;
    padding: 3px 10px !important;
    border: none !important;
    box-shadow: none !important;
}

#datatable-colvid_info {
    float: left;
}

/* Responsive Table */
.table-rep-plugin .dropdown-menu li.checkbox-row {
    padding: 2px 15px !important;
}

.table-rep-plugin .table-responsive {
    border: none !important;
}

.table-rep-plugin tbody th {
    font-size: 14px;
    font-weight: normal;
}

.table-rep-plugin .checkbox-row {
    padding-left: 40px;
}

.table-rep-plugin .checkbox-row label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
}

.table-rep-plugin .checkbox-row label::before {
    -o-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #98a6ad;
    content: "";
    display: inline-block;
    height: 17px;
    left: 0;
    margin-left: -20px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 17px;
    outline: none !important;
}

.table-rep-plugin .checkbox-row label::after {
    color: #f1f6f6;
    display: inline-block;
    font-size: 11px;
    height: 16px;
    left: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    position: absolute;
    top: -1px;
    width: 16px;
}

.table-rep-plugin .checkbox-row input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
    opacity: 0.65;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label::before {
    outline-offset: -2px;
    outline: none;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
    content: "\f00c";
    font-family: 'FontAwesome';
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label::before {
    background-color: #edf1f1;
    cursor: not-allowed;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::before {
    background-color: #ffffff;
    border-color: #43c5e6;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
    color: #43c5e6;
}

.table-rep-plugin table.focus-on tbody tr.focused th,
.table-rep-plugin table.focus-on tbody tr.focused td,
.table-rep-plugin .sticky-table-header {
    background-color: #43c5e6;
    color: #ffffff;
    border-color: #43c5e6;
}

.table-rep-plugin .sticky-table-header.fixed-solution {
    top: 70px !important;
}

/* Data table editable */
.add-edit-table td,
.add-edit-table th {
    vertical-align: middle !important;
}

.add-edit-table td {
    border: 0 !important;
}

#datatable-editable .actions a {
    padding: 5px;
}

#datatable-editable .form-control, #datatable-editable .form-contro-dark {
    background-color: #ffffff;
    width: 100%;
}

#datatable-editable .fa-trash-o {
    color: #f7531f;
}

#datatable-editable .fa-times {
    color: #f7531f;
}

#datatable-editable .fa-pencil {
    color: #43c5e6;
}

#datatable-editable .fa-save {
    color: #4acf95;
}

#datatable td {
    font-weight: normal;
}

.modal-block {
    background: transparent;
    margin: 40px auto;
    max-width: 600px;
    padding: 0;
    position: relative;
    text-align: left;
}

.dt-buttons {
    float: left;
}

div#datatable-buttons_info {
    float: left;
}

table.dataTable thead th {
    position: relative;
    background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    position: absolute;
    top: 7px;
    right: 8px;
    display: block;
    font-family: FontAwesome;
}

table.dataTable thead th.sorting:after {
    content: "\f0dc";
    padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
    content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
    content: "\f0dd";
}

.DTFC_LeftBodyWrapper table thead th.sorting:after,
.dataTables_scrollBody table thead th.sorting:after,
.DTFC_RightBodyLiner table thead th.sorting:after,
.DTFC_LeftBodyWrapper table thead th.sorting_asc:after,
.dataTables_scrollBody table thead th.sorting_asc:after,
.DTFC_RightBodyLiner table thead th.sorting_asc:after,
.DTFC_LeftBodyWrapper table thead th.sorting_desc:after,
.dataTables_scrollBody table thead th.sorting_desc:after,
.DTFC_RightBodyLiner table thead th.sorting_desc:after {
    display: none !important;
}

/* Tablesaw Tables */
.tablesaw thead {
    background: rgba(241, 246, 246, 0.7);
    background-image: none;
    border: none;
}

.tablesaw thead th {
    text-shadow: none;
    letter-spacing: 0.06em;
}

.tablesaw thead tr:first-child th {
    font-weight: 600;
    border: none;
    font-family: 'vugo', sans-serif;
}

.tablesaw td {
    border-top: 1px solid rgba(241, 246, 246, 0.7) !important;
}

.tablesaw td,
.tablesaw tbody th {
    font-size: inherit;
    line-height: inherit;
    padding: 10px !important;
}

.tablesaw-stack tbody tr,
.tablesaw tbody tr {
    border-bottom: none;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after,
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
    font-family: FontAwesome;
    font-size: 10px;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
    content: "\f176";
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
    content: "\f175";
}

.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
    font-size: 8px;
    padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
    box-shadow: none;
}

.tablesaw-enhanced .tablesaw-bar .btn {
    text-shadow: none;
    background-image: none;
}

.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
    background: #ffffff;
}

.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
    color: #43c5e6 !important;
    background-color: #f1f6f6;
    outline: none !important;
    box-shadow: none !important;
    background-image: none;
}

/* Table colored */
.table-colored thead th {
    color: #ffffff;
}

.table-colored.table-custom thead th {
    background-color: #43c5e6;
}

.table-colored.table-primary thead th {
    background-color: #0a2737;
}

.table-colored.table-success thead th {
    background-color: #4acf95;
}

.table-colored.table-info thead th {
    background-color: #43c5e6;
}

.table-colored.table-warning thead th {
    background-color: #ff9800;
}

.table-colored.table-danger thead th {
    background-color: #f7531f;
}

.table-colored.table-inverse thead th {
    background-color: #3b3e47;
}

.table-colored.table-pink thead th {
    background-color: #ec1d45;
}

.table-colored.table-purple thead th {
    background-color: #8892d6;
}

/* Table colored-bordered */
.table-colored-bordered thead th {
    border-bottom: 0 !important;
    color: #ffffff;
}

.table-colored-bordered.table-bordered-primary {
    border: 2px solid #0a2737;
}

.table-colored-bordered.table-bordered-primary thead th {
    background-color: #0a2737;
}

.table-colored-bordered.table-bordered-success {
    border: 2px solid #4acf95;
}

.table-colored-bordered.table-bordered-success thead th {
    background-color: #4acf95;
}

.table-colored-bordered.table-bordered-info {
    border: 2px solid #45bbe0;
}

.table-colored-bordered.table-bordered-info thead th {
    background-color: #45bbe0;
}

.table-colored-bordered.table-bordered-custom {
    border: 2px solid #43c5e6;
}

.table-colored-bordered.table-bordered-custom thead th {
    background-color: #43c5e6;
}

.table-colored-bordered.table-bordered-warning {
    border: 2px solid #ff9800;
}

.table-colored-bordered.table-bordered-warning thead th {
    background-color: #ff9800;
}

.table-colored-bordered.table-bordered-danger {
    border: 2px solid #f7531f;
}

.table-colored-bordered.table-bordered-danger thead th {
    background-color: #f7531f;
}

.table-colored-bordered.table-bordered-inverse {
    border: 2px solid #3b3e47;
}

.table-colored-bordered.table-bordered-inverse thead th {
    background-color: #3b3e47;
}

.table-colored-bordered.table-bordered-pink {
    border: 2px solid #ec1d45;
}

.table-colored-bordered.table-bordered-pink thead th {
    background-color: #ec1d45;
}

.table-colored-bordered.table-bordered-purple {
    border: 2px solid #8892d6;
}

.table-colored-bordered.table-bordered-purple thead th {
    background-color: #8892d6;
}

/* Table full colored */
.table-colored-full {
    color: #ffffff;
}

.table-colored-full thead th {
    border-bottom: 0 !important;
    padding: 10px !important;
}

.table-colored-full tbody td,
.table-colored-full tbody th {
    border: 0 !important;
}

.table-colored-full.table-full-primary {
    background-color: rgba(10, 39, 55, 0.8);
}

.table-colored-full.table-full-primary thead th {
    background-color: #0a2737;
}

.table-colored-full.table-full-primary tbody tr:hover {
    background-color: #0a2737;
}

.table-colored-full.table-full-success {
    background-color: rgba(74, 207, 149, 0.8);
}

.table-colored-full.table-full-success thead th {
    background-color: #4acf95;
}

.table-colored-full.table-full-success tbody tr:hover {
    background-color: #4acf95;
}

.table-colored-full.table-full-info {
    background-color: rgba(69, 187, 224, 0.8);
}

.table-colored-full.table-full-info thead th {
    background-color: #45bbe0;
}

.table-colored-full.table-full-info tbody tr:hover {
    background-color: #45bbe0;
}

.table-colored-full.table-full-custom {
    background-color: rgba(67, 197, 230, 0.8);
}

.table-colored-full.table-full-custom thead th {
    background-color: #43c5e6;
}

.table-colored-full.table-full-custom tbody tr:hover {
    background-color: #43c5e6;
}

.table-colored-full.table-full-warning {
    background-color: rgba(255, 152, 0, 0.8);
}

.table-colored-full.table-full-warning thead th {
    background-color: #ff9800;
}

.table-colored-full.table-full-warning tbody tr:hover {
    background-color: #ff9800;
}

.table-colored-full.table-full-danger {
    background-color: rgba(247, 83, 31, 0.8);
}

.table-colored-full.table-full-danger thead th {
    background-color: #f7531f;
}

.table-colored-full.table-full-danger tbody tr:hover {
    background-color: #f7531f;
}

.table-colored-full.table-full-inverse {
    background-color: rgba(59, 62, 71, 0.8);
}

.table-colored-full.table-full-inverse thead th {
    background-color: #3b3e47;
}

.table-colored-full.table-full-inverse tbody tr:hover {
    background-color: #3b3e47;
}

.table-colored-full.table-full-pink {
    background-color: rgba(236, 29, 69, 0.8);
}

.table-colored-full.table-full-pink thead th {
    background-color: #ec1d45;
}

.table-colored-full.table-full-pink tbody tr:hover {
    background-color: #ec1d45;
}

.table-colored-full.table-full-purple {
    background-color: rgba(136, 146, 214, 0.8);
}

.table-colored-full.table-full-purple thead th {
    background-color: #8892d6;
}

.table-colored-full.table-full-purple tbody tr:hover {
    background-color: #8892d6;
}

/* =====================================
   CHARTS
   ===================================== */
.chart-detail-list li {
    margin: 0 10px;
}

.chart-detail-list li h5 {
    color: rgba(150, 150, 150, 0.6);
    text-transform: uppercase;
    font-size: 13px;
}

/* Flot chart */
#flotTip {
    padding: 8px 12px;
    background-color: #969696 !important;
    z-index: 100;
    color: #ffffff;
    opacity: 0.9;
    font-size: 13px;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}

.legend tr {
    height: 20px;
    font-family: 'vugo', sans-serif;
}

.legendLabel {
    padding-left: 5px !important;
    line-height: 10px;
    padding-right: 10px;
}

/* Morris chart */
.morris-hover.morris-default-style {
    border-radius: 5px;
    padding: 10px 12px;
}

/* Chartist chart */
.ct-golden-section:before {
    float: none;
}

.ct-chart {
    max-height: 300px;
}

.ct-chart .ct-label {
    fill: #98a6ad;
    color: #98a6ad;
    font-size: 14px;
    line-height: 1;
}

.ct-grid {
    stroke: rgba(150, 150, 150, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
    color: #ffffff;
    fill: #ffffff;
    font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: #0a2737;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: #45bbe0;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: #43c5e6;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
    stroke: #8892d6;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
    stroke: #969696;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
    stroke: #ec1d45;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
    stroke: #f7531f;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
    fill: #0a2737;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
    fill: #45bbe0;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
    fill: #43c5e6;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
    fill: #43c5e6;
}

.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    background: #969696;
    color: #ffffff;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
    opacity: 1;
}

/* C3 chart */
.c3 svg {
    max-width: 100%;
}

.c3-tooltip td > span {
    background: #969696;
}

.c3-tooltip td {
    border-left: none;
}

.c3-tooltip {
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.12);
    opacity: 1;
}

.c3-chart-arcs-title {
    font-size: 18px;
    font-weight: 600;
    font-family: 'vugo', sans-serif;
}

.c3-tooltip tr {
    border: none !important;
}

.c3-tooltip th {
    background-color: #969696;
}

.c3-tooltip .value {
    font-weight: 600;
    font-family: 'vugo', sans-serif;
}

/* Sparkline chart */
.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: auto !important;
    height: auto !important;
    background-color: #969696 !important;
    padding: 5px 10px !important;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    border-color: #969696 !important;
}

.jqsfield {
    font-size: 12px !important;
    line-height: 18px !important;
}

/* Google Chart*/
.google-chart .chart {
    display: inline-block;
}

.btn {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
}

.table-responsive {
    border: solid 0px #000000 !important;
    border-radius: 0px !important;
}

.table > thead > tr > th {
    border: solid 0px #000000 !important;
}

td {
    vertical-align: middle !important;
}

table.m-0 {
    font-size: 16px !important;
    color: #000000 !important;
    letter-spacing: 0px !important;
}

/* File Uploader */
.fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
.fileuploader-theme-thumbnails .fileuploader-items-list li.fileuploader-item {
    display: block;
    height: 90px;
    line-height: 95px;
    padding: 5px;
    vertical-align: top;
    width: 185px;
    border-radius: 4px;
    border: 1px dashed #0a2737;
}

.fileuploader-thumbnails-input-inner {
    width: 172px;
    height: 80px;
}

.fileuploader-theme-thumbnails .fileuploader-items-list {
    width: 185px;
    position: relative;
    margin: 0px auto;
    padding: 0px;
}

.fileuploader {
    background: transparent;
    padding: 0px;
    margin: 0px;
}

.fileuploader-theme-thumbnails .fileuploader-items-list li.fileuploader-item {
    position: absolute;
    z-index: 99999;
}

.fileuploader-theme-thumbnails .fileuploader-thumbnails-input-inner {
    -moz-user-select: none;
    border: solid 0px #000000 !important;
    background: #a8efff;
    border-radius: 0px;
    color: #0a2737;
    line-height: 80px;
    cursor: pointer;
    font-size: 40px;
    text-align: center;
    transition: all 0.1s ease 0s;
    vertical-align: top;
}

.fileuploader-theme-thumbnails .fileuploader-items-list .actions-holder {
    background-color: #ba8ad6;
}

.fileuploader-input-button {
    background: #43c5e6 !important;
}

.btn-user-ban {
    background-color: #ec1c45 !important;
    color: white;
}

.btn-user-ban:hover {
    background-color: #46bcf2 !important;
    color: white;
}

.btn-user-ban:focus {
    background-color: #ec1c45 !important;
    color: white;
}

.ban-user {
    align-items: center;
}

.btn-user-cancel {
    background-color: #ec1c45 !important;
    color: white;
}

.btn-user-cancel:hover {
    background-color: #ec1c45 !important;
    color: white;
}

.btn-user-submit:hover {
    background-color: #000000 !important;
    color: white;
}

.btn-user-submit {
    background-color: #000000 !important;
    color: white;
}
